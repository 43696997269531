import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import BookmarkItem from '../BookmarkItem/BookmarkItem';
import * as Styled from './MoreButton.styles';
import { MoreButtonProps } from './MoreButton.types';

const MoreButton: FC<MoreButtonProps> = ({
  bookmarks,
  disabled,
  onDeleteBookmark,
  onDragBookmark,
  onDragOver,
  onDrop,
  offsetIndex,
  dropIndex,
  dropIndicatorPosition,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEnteredMoreMenu, setIsEnteredMoreMenu] = useState(false);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsEnteredMoreMenu(false);
  };

  const handleMenuDragEnter = () => {
    setIsEnteredMoreMenu(true);
  };

  const handleBackdropDragEnter = () => {
    if (isEnteredMoreMenu) {
      handleClose();
    }
  };

  return (
    <>
      <Styled.Button
        id="more-bookmarks-button"
        aria-controls={open ? 'more-bookmarks-options' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size="small"
        onClick={handleOpen}
        startIcon={<MoreVertIcon />}
        onDragOver={(e) => {
          e.preventDefault();
          handleOpen(e);
        }}
      >
        <Typography variant="labelSmall" color="text.secondary">
          More
        </Typography>
      </Styled.Button>
      <Styled.Menu
        id="more-bookmarks-options"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-bookmarks-button',
          onDragEnter: handleMenuDragEnter,
          onDragOver,
          onDrop,
        }}
        BackdropProps={{
          onDragEnter: handleBackdropDragEnter,
        }}
      >
        {bookmarks.map((bookmark, i) => (
          <div key={bookmark.id}>
            {offsetIndex + i === dropIndex && dropIndicatorPosition === 'before' && (
              <Styled.DropIndicator />
            )}
            <BookmarkItem
              bookmark={bookmark}
              disabled={disabled}
              onDeleteBookmark={onDeleteBookmark}
              onDragBookmark={onDragBookmark}
              isMenuItem
            />
            {offsetIndex + i === dropIndex && dropIndicatorPosition === 'after' && (
              <Styled.DropIndicator />
            )}
          </div>
        ))}
      </Styled.Menu>
    </>
  );
};

export default MoreButton;
