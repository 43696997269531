import { Skeleton, Typography, Box } from '@mui/material';
import { FC } from 'react';
import { ORGANIZATION_DEFAULT_LOGO } from 'utils/mediaPaths';
import * as Styled from './OrganizationCard.styles';
import { OrganizationCardProps } from './OrganizationCard.types';

const OrganizationCard: FC<OrganizationCardProps> = ({ isLoading, organization }) => {
  if (isLoading) {
    return (
      <Styled.Organization>
        <Box width={32} height={32}>
          <Skeleton width={32} height={32} sx={{ transform: 'none', borderRadius: 2 }} />
        </Box>

        <Box display="flex" flexDirection="column" width="100%">
          <Skeleton width="75%" />
          <Skeleton width="50%" />
        </Box>
      </Styled.Organization>
    );
  }

  return (
    <Styled.Organization>
      <Styled.OrganizationImage
        src={organization?.logoUrl ? organization.logoUrl : ORGANIZATION_DEFAULT_LOGO}
        alt={organization?.name}
        title={organization?.name}
      />

      <Box display="flex" flexDirection="column">
        <Typography variant="titleMedium" color="text.primary">
          {organization?.name}
        </Typography>
        <Typography variant="bodySmall" color="text.secondary">
          Organization
        </Typography>
      </Box>
    </Styled.Organization>
  );
};

export default OrganizationCard;
