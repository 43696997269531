import AppsIcon from '@mui/icons-material/Apps';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/HomeRounded';
import useUserApps from 'api/platformApi/services/userAppsService/useUserApps';
import AppHeader from 'components/AppHeader/AppHeader';
import SideNav from 'components/SideNav/SideNav';
import { NavItem } from 'components/SideNav/SideNav.types';
import ToolsSubmenu from 'components/SideNav/components/ToolsSubmenu/ToolsSubmenu';
import useAuthContext from 'contexts/auth/useAuthContext';
import useResetScrollPosition from 'hooks/useResetScrollPosition';
import { Outlet, useParams } from 'react-router-dom';
import { routes } from 'utils/routes';
import { getUserMenuActions } from 'utils/userMenuActions';
import * as Styled from './AppLayout.styles';

const AppLayout = () => {
  const { user, logout } = useAuthContext();
  const { workspaceSlug } = useParams();
  const activeWorkspaceSlug = workspaceSlug ?? '';
  const userActions = getUserMenuActions(user);
  const scrollableContainerRef = useResetScrollPosition();

  const appsQuery = useUserApps();
  const apps = appsQuery.data?.data ?? [];
  const workspaceLevelApps = apps.filter((app) => app.manifest?.intents?.openWorkspace);

  const navItems: NavItem[] = [
    {
      label: 'Home',
      icon: <HomeIcon />,
      route: routes.dashboard(activeWorkspaceSlug),
    },
    {
      label: 'Projects',
      icon: <FolderIcon />,
      route: routes.projects(activeWorkspaceSlug),
    },
  ];

  if (workspaceLevelApps.length > 0) {
    navItems.push({
      label: 'Tools',
      icon: <AppsIcon />,
      submenu: {
        label: 'Workspace Tools',
        renderItems: (handleClose) => (
          <ToolsSubmenu
            tools={workspaceLevelApps}
            activeWorkspaceSlug={activeWorkspaceSlug}
            onItemClick={handleClose}
          />
        ),
      },
    });
  }

  return (
    <main>
      <AppHeader flow="monks.flow" user={user} onSignOutClick={logout} userActions={userActions} />

      <Styled.Container>
        <SideNav navItems={navItems} />

        <Styled.ContentLayout ref={scrollableContainerRef}>
          <Styled.Content>
            <Outlet />
          </Styled.Content>
        </Styled.ContentLayout>
      </Styled.Container>
    </main>
  );
};

export default AppLayout;
