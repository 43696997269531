/**
 * @see public/workspace-icons/
 */
const DEFAULT_LOGO_COUNT = 6;
const getDefaultLogoFile = (index: number) =>
  window.location.origin + `/workspace-icons/workspace-default-logo-${index}.svg`;

export function fromTitleToDefaultLogo(title: string) {
  const codePoint = title.toLocaleLowerCase().codePointAt(0);
  const logoIndex = (codePoint ? codePoint % DEFAULT_LOGO_COUNT : 0) + 1;
  return getDefaultLogoFile(logoIndex);
}
