import platformApi from 'api/platformApi/platformApi';
import {
  IWorkspaceProjectFilesParamsPath,
  IWorkspaceProjectFilesParamsQuery,
  IWorkspaceProjectFilesResponse,
} from 'api/platformApi/platformApi.types';

export const getWorkspaceProjectFiles = async ({
  params: {
    path: { projectId, workspaceId },
    query: { app, limit, nextCursor },
  },
}: ServiceFnConfig<IWorkspaceProjectFilesParamsPath, IWorkspaceProjectFilesParamsQuery>) => {
  const response = await platformApi.get<IWorkspaceProjectFilesResponse>(
    `/workspaces/${workspaceId}/projects/${projectId}/files`,
    { params: { app, limit, nextCursor } },
  );

  return response.data;
};
