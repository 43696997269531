import platformApi from 'api/platformApi/platformApi';
import { IWorkspaceProjectUsersServiceResponse } from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig } from 'axios';

export const getWorkspaceProjectUsers = (
  workspaceId: string,
  projectId: string,
  axiosConfig?: AxiosRequestConfig,
) => {
  return platformApi.get<IWorkspaceProjectUsersServiceResponse>(
    `/workspaces/${workspaceId}/projects/${projectId}/users`,
    axiosConfig,
  );
};
