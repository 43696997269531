import platformApi from 'api/platformApi/platformApi';
import {
  ICreateOrganizationAuthMethodRequestBody,
  ICreateOrganizationAuthMethodResponse,
} from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const createOrganizationAuthMethod = async (
  organizationId: string,
  methodData: ICreateOrganizationAuthMethodRequestBody,
  axiosConfig?: AxiosRequestConfig,
) => {
  const response = await platformApi.post<
    ICreateOrganizationAuthMethodRequestBody,
    AxiosResponse<ICreateOrganizationAuthMethodResponse>,
    ICreateOrganizationAuthMethodRequestBody
  >(`/organizations/${organizationId}/sso-methods`, methodData, axiosConfig);

  return response.data;
};
