import { UseMutationOptions, skipToken, useMutation, useQuery } from '@tanstack/react-query';
import {
  ICreateWorkspaceServiceRequestBody,
  ICreateWorkspaceServiceResponse,
  IUpdateWorkspaceServiceParamsPath,
  IUpdateWorkspaceServiceRequestBody,
  IUpdateWorkspaceServiceResponse,
  IWorkspaceServiceParamsPath,
} from '../../platformApi.types';
import { CreateWorkspaceOperationError, UpdateWorkspaceOperationError } from './errors';
import { createWorkspace, getWorkspace, getWorkspaces, updateWorkspace } from './services';

export const WorkspacesServiceKeys = {
  root: ['workspaces'] as const,
  lists() {
    return [...this.root, 'list'] as const;
  },
  details() {
    return [...this.root, 'detail'] as const;
  },
  detail(params: Partial<IWorkspaceServiceParamsPath>) {
    return [...this.details(), params] as const;
  },
  create() {
    return [...this.root, 'create'] as const;
  },
  update() {
    return [...this.root, 'update'] as const;
  },
};

export const useWorkspaces = () => {
  return useQuery({
    queryKey: WorkspacesServiceKeys.lists(),
    queryFn: getWorkspaces,
  });
};

export type UseWorkspaceParams = Partial<IWorkspaceServiceParamsPath>;

export const useWorkspace = (params: UseWorkspaceParams) => {
  const { workspaceId } = params;

  return useQuery({
    queryKey: WorkspacesServiceKeys.detail({ workspaceId }),
    queryFn: workspaceId ? () => getWorkspace({ params: { path: { workspaceId } } }) : skipToken,
  });
};

type UseCreateWorkspaceOptions = UseMutationOptions<
  ICreateWorkspaceServiceResponse,
  CreateWorkspaceOperationError,
  ICreateWorkspaceServiceRequestBody
>;

export const useCreateWorkspace = (options?: UseCreateWorkspaceOptions) => {
  return useMutation({
    ...options,
    mutationKey: WorkspacesServiceKeys.create(),
    mutationFn(variables) {
      return createWorkspace({ body: variables });
    },
  });
};

type UseUpdateWorkspaceOptions = UseMutationOptions<
  IUpdateWorkspaceServiceResponse,
  UpdateWorkspaceOperationError,
  IUpdateWorkspaceServiceParamsPath & IUpdateWorkspaceServiceRequestBody
>;

export const useUpdateWorkspace = (options?: UseUpdateWorkspaceOptions) => {
  return useMutation({
    ...options,
    mutationKey: WorkspacesServiceKeys.update(),
    mutationFn({ workspaceId, ...body }) {
      return updateWorkspace({ params: { path: { workspaceId } }, body });
    },
  });
};
