import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function useResetScrollPosition<T extends readonly unknown[]>(
  additionalDeps: T = [] as unknown as T,
): React.RefObject<HTMLDivElement> {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    scrollableContainerRef.current?.scrollTo(0, 0);
  }, [pathname, ...additionalDeps]);

  return scrollableContainerRef;
}

export default useResetScrollPosition;
