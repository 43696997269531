import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  IUpdateOrganizationRequestBody,
  IUpdateOrganizationResponse,
} from 'api/platformApi/platformApi.types';
import { updateOrganization, UpdateOrganizationOperationError } from './updateOrganizationService';

function useUpdateOrganization(
  organizationId: string,
  mutationOptions?: UseMutationOptions<
    IUpdateOrganizationResponse,
    UpdateOrganizationOperationError,
    IUpdateOrganizationRequestBody
  >,
) {
  return useMutation({
    ...mutationOptions,
    mutationKey: ['updateOrganization', { organizationId }],
    mutationFn: (data: IUpdateOrganizationRequestBody) => {
      return updateOrganization(organizationId, data);
    },
  });
}

export default useUpdateOrganization;
