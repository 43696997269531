import { Box, Typography } from '@mui/material';
import logoUrl from 'assets/img/monks.flow-icon.svg';
import { FC } from 'react';
import * as Styled from './ErrorPage.styles';
import { ErrorPageProps } from './ErrorPage.types';

const ErrorPage: FC<ErrorPageProps> = (props) => {
  const Container = props.embed ? Styled.EmbeddedContainer : Styled.MainContainer;

  return (
    <Container>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={2}
        maxWidth={480}
        textAlign="center"
      >
        <img src={logoUrl} alt="MonksFlow" />

        <Typography color="text.primary" variant="titleLargeMd">
          {props.title}
        </Typography>

        <Typography color="text.secondary" variant="bodyMedium">
          {props.description}
        </Typography>

        {props.content}
      </Box>
    </Container>
  );
};

export default ErrorPage;
