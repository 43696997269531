import waveformBlurredBg from 'assets/img/waveform-blurred-bg.jpg';
import PageFeedbackDefault from 'components/ui/PageFeedback/PageFeedback';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.surface.level0} url(${waveformBlurredBg}) no-repeat
    center center / cover fixed;
  width: 100%;
  height: 100vh;
`;

export const PageFeedback = styled(PageFeedbackDefault)`
  margin-top: auto;
`;
