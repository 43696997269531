import { monksFlowLightTheme } from '@monksflow/monks-ui';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ErrorIcon from '@mui/icons-material/Error';
import { createTheme, ThemeOptions } from '@mui/material';
import { merge } from 'lodash-es';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontWeightExtraBold: number;
  }

  interface TypographyVariantsOptions {
    fontWeightExtraBold?: number;
  }

  interface ZIndex {
    pageBar: number;
  }
}

/**
 * In the platform app we have a bunch of components being customized on a one-time
 * basis that keeps extending everywhere.
 *
 * The idea of this file is to house all the customizations that are being used
 * and later discuss if it's worth to move it into the `monks-ui` package.
 *
 * Per component customizations are dangerous cause if not handled properly
 * they can start to go awry and create a lot of confusion.
 */
const platformTheme = createTheme(
  /**
   * Using `merge` is necessary cause MUI does not process `createTheme` arguments
   * other than the first.
   *
   * @see https://mui.com/material-ui/customization/theming/#api
   */
  merge(monksFlowLightTheme, {
    /**
     * TODO: Remove sending `monksShadows` and `typography` duplicates after
     *       updating `monks-ui` making all `<X>Options` types optional.
     */
    typography: {
      ...monksFlowLightTheme.typography,
      fontWeightExtraBold: 800,
    },
    components: {
      /**
       * MUI Accordion
       */
      MuiAccordion: {
        defaultProps: {
          defaultExpanded: true,
          disableGutters: true,
        },
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiAccordionSummary: {
        defaultProps: {
          expandIcon: <ArrowDropDownIcon />,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.surface.dividerMain,
            padding: 0,
          }),
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(1.5, 1),
          }),
        },
      },
      /**
       * MUI Alert
       */
      MuiAlert: {
        defaultProps: {
          icon: <ErrorIcon />,
        },
        styleOverrides: {
          standardError: {
            backgroundColor: '#fdeded',
            color: '#5f2120',
          },
          standardInfo: {
            backgroundColor: '#e5f6fd',
            color: '#014361',
          },
        },
      },
      /**
       * MUI Dialog
       */
      MuiDialog: {
        styleOverrides: {
          paper: ({ theme }) => ({
            borderRadius: theme.shape.borderRadius * 4,
          }),
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(2, 3),
          }),
        },
      },
    },
    zIndex: {
      pageBar: 1000,
    },
  } satisfies ThemeOptions),
);

export { platformTheme };
