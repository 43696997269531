import { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import {
  useGlobalPreferences,
  usePreferredWorkspace,
} from 'utils/preferences/useGlobalPreferences';
import { routes } from 'utils/routes';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';

export const NavigateToWorkspace = ({ savePreference }: { savePreference?: boolean }) => {
  const { save } = useGlobalPreferences();
  const preferredWorkspaceSlug = usePreferredWorkspace();

  const activeWorkspace = useActiveWorkspace();
  const activeWorkspaceSlug = activeWorkspace?.slug ?? '';

  const workspaceSlug = preferredWorkspaceSlug ?? activeWorkspaceSlug;

  useEffect(() => {
    if (!savePreference) return;
    save({ workspaceSlug }, false);
  }, [save, savePreference, workspaceSlug]);

  return <Navigate to={routes.dashboard(workspaceSlug)} replace />;
};

export const NavigateToIndex = () => {
  return <Navigate to="/" replace />;
};

export const NavigateTo404 = () => {
  return <Navigate to="/404" replace />;
};

export const NavigateToLogin = () => {
  const { read } = useGlobalPreferences();
  const windowLocation = window.location;
  const organizationSlug = read('organizationSlug');
  const [searchParams] = useSearchParams();
  const redirectUri = searchParams.get('redirectUri') ?? windowLocation.href;

  let loginRoute = '/login';

  if (organizationSlug) {
    loginRoute = `/${organizationSlug}/login`;
  }

  return (
    <Navigate
      to={loginRoute}
      replace
      state={{
        redirectUri,
      }}
    />
  );
};
