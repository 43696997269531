import ErrorIcon from '@mui/icons-material/Error';
import { useQueryClient } from '@tanstack/react-query';
import useAddWorkspaceUsers from 'api/platformApi/services/addWorkspaceUsersService/useAddWorkspaceUsers';
import { WorkspaceMetricsServiceKeys } from 'api/platformApi/services/workspaceMetricsService/hooks';
import useWorkspaceUsers, {
  getWorkspaceUsersQueryKey,
} from 'api/platformApi/services/workspaceUsersService/useWorkspaceUsers';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import SelectUsers from 'components/SelectUsers/SelectUsers';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './AddWorkspaceUsersDrawer.styles';
import {
  AddWorkspaceUsersDrawerProps,
  AddWorkspaceUsersForm,
} from './AddWorkspaceUsersDrawer.types';

const AddWorkspaceUsersDrawer: FC<AddWorkspaceUsersDrawerProps> = ({
  workspaceId = '',
  ...props
}) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();

  const { data: workspaceUsers = [] } = useWorkspaceUsers({ workspaceId });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<AddWorkspaceUsersForm>({
    mode: 'all',
    defaultValues: {
      users: [],
    },
  });

  const handleClose = () => {
    reset();
    props.onClose();
  };

  const addWorkspaceUsersMutation = useAddWorkspaceUsers(workspaceId, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getWorkspaceUsersQueryKey({ workspaceId }),
      });

      await queryClient.invalidateQueries({
        queryKey: WorkspaceMetricsServiceKeys.detail({ workspaceId }),
      });
      handleClose();
      snackbar.success('Users added to workspace successfully');
    },
  });

  const onAddUserSubmit: SubmitHandler<AddWorkspaceUsersForm> = async (data) => {
    await addWorkspaceUsersMutation.mutateAsync({
      userIds: data.users.map((user) => user.id),
    });
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <AppDrawer open={props.open} onClose={handleClose}>
      <Styled.Form onSubmit={handleSubmit(onAddUserSubmit)}>
        <AppDrawerHeader title="Add users to workspace" onClose={handleClose} />

        <AppDrawerContent>
          {addWorkspaceUsersMutation.isError && (
            <Styled.Alert severity="error" icon={<ErrorIcon />} sx={{ mb: 3 }}>
              An error occurred, please try again
            </Styled.Alert>
          )}

          <Controller
            name="users"
            control={control}
            render={({ field }) => (
              <SelectUsers
                value={field.value}
                onChange={field.onChange}
                inputLabel="Search users"
                inputPlaceholder="Search for users to add to this workspace..."
                disabled={isSubmitting}
                filter={(users) =>
                  users.filter(
                    (user) => !workspaceUsers.find((workspaceUser) => workspaceUser.id === user.id),
                  )
                }
              />
            )}
          />
        </AppDrawerContent>

        <Styled.AddUserAppDrawerFooter>
          <Styled.AddUserButton
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isSubmitDisabled}
          >
            {isSubmitting ? <MFButtonLoader /> : `Add to workspace`}
          </Styled.AddUserButton>
        </Styled.AddUserAppDrawerFooter>
      </Styled.Form>
    </AppDrawer>
  );
};

export default AddWorkspaceUsersDrawer;
