import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  IPlatformApiGenericError,
  IResendInvitationResponse,
} from 'api/platformApi/platformApi.types';
import { AxiosError } from 'axios';
import { resendInvitation } from './resendInvitationService';

function useResendInvitation(
  invitationId: string,
  mutationOptions?: UseMutationOptions<
    IResendInvitationResponse,
    AxiosError<IPlatformApiGenericError>
  >,
) {
  return useMutation({
    ...mutationOptions,
    mutationKey: ['resendInvitation'],
    mutationFn: () => {
      return resendInvitation(invitationId).then((r) => r.data);
    },
  });
}

export default useResendInvitation;
