import { IWorkspaceUser } from 'api/platformApi/platformApi.types';
import { ITableOrderDirection } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import { MembersTableSortableCells } from './WorkspaceMembers.types';

export function descendingComparator(
  a: IWorkspaceUser,
  b: IWorkspaceUser,
  orderBy: MembersTableSortableCells['id'],
) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(
  order: ITableOrderDirection,
  orderBy: MembersTableSortableCells['id'],
): (a: IWorkspaceUser, b: IWorkspaceUser) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
