import AppHeader from 'components/AppHeader/AppHeader';
import SideNav from 'components/SideNav/SideNav';
import useAuthContext from 'contexts/auth/useAuthContext';
import { getUserMenuActions } from 'utils/userMenuActions';
import ErrorPage from '../ErrorPage/ErrorPage';
import * as Styled from './ErrorNoWorkspacePage.styles';

const ErrorNoWorkspacePage = () => {
  const { user, logout } = useAuthContext();
  const userActions = getUserMenuActions(user);

  return (
    <main>
      <AppHeader flow="monks.flow" user={user} onSignOutClick={logout} userActions={userActions} />

      <Styled.Container>
        <SideNav navItems={[]} />

        <Styled.ContentLayout>
          <ErrorPage
            title="No workspaces assigned"
            description="You have not been assigned to any workspaces. Contact your Monks.Flow administrator to access workspaces and tools."
          />
        </Styled.ContentLayout>
      </Styled.Container>
    </main>
  );
};

export default ErrorNoWorkspacePage;
