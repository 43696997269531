import { ContentCopy } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import useOrganization from 'api/platformApi/services/organizationService/useOrganization';
import useUpdateOrganization from 'api/platformApi/services/updateOrganizationService/useUpdateOrganization';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import { requiredValidation } from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './EditOrganizationSection.styles';
import {
  EditOrganizationSectionProps,
  UpdateOrganizationForm,
} from './EditOrganizationSection.types';

const EditOrganizationSection: FC<EditOrganizationSectionProps> = ({ organizationId }) => {
  const snackbar = useSnackbarContext();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm<UpdateOrganizationForm>({
    mode: 'all',
    defaultValues: {
      name: '',
      logoUrl: '',
    },
  });

  const organization = useOrganization(organizationId);
  const updateOrganizationMutation = useUpdateOrganization(organizationId, {
    onSuccess: async () => {
      await organization.refetch();
      snackbar.success('Organization updated successfully');
    },
    onError: (error) => {
      if (error.scope === 'field' && error.field) {
        setError(error.field, { message: error.message });
      }

      if (error.scope === 'snackbar') {
        reset();
        snackbar.error(error.message);
      }
    },
  });

  const onUpdateOrganizationSubmit: SubmitHandler<UpdateOrganizationForm> = async (data) => {
    await updateOrganizationMutation.mutateAsync(data);
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  useEffect(() => {
    reset({
      name: organization.data?.name,
      logoUrl: organization.data?.logoUrl,
    });
  }, [reset, organization.data]);

  const orgUrl = `${window.location.origin}/${organization.data?.slug}/login`;

  const handleCopyOrgUrl = async () => {
    await navigator.clipboard.writeText(orgUrl);
    snackbar.success('Copied to clipboard');
  };

  return (
    <Styled.Container>
      <Styled.Form onSubmit={handleSubmit(onUpdateOrganizationSubmit)}>
        {organization.data?.logoUrl && (
          <Styled.OrganizationLogo src={organization.data.logoUrl} alt="Logo" />
        )}
        <input type="hidden" {...control.register('logoUrl')} />
        <RHFMonkTextField
          name="name"
          control={control}
          rules={{ ...requiredValidation }}
          value={organization.data?.name}
          label="Organization name"
          placeholder="Add a name..."
          disabled={isSubmitting}
          required
          fullWidth
        />

        <TextField
          value={orgUrl}
          label="Org URL"
          disabled
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Styled.IconButton onClick={handleCopyOrgUrl}>
                  <ContentCopy fontSize="small" />
                </Styled.IconButton>
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />

        <Styled.Button type="submit" variant="outlined" size="large" disabled={isSubmitDisabled}>
          {isSubmitting ? <MFButtonLoader /> : `Update`}
        </Styled.Button>
      </Styled.Form>
    </Styled.Container>
  );
};

export default EditOrganizationSection;
