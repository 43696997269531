import { Drawer as MuiDrawer } from '@mui/material';
import styled from 'styled-components';

interface IStyledMuiBackdrop {
  $leftSidebar?: boolean;
}

const getLeftPosition = ({ $leftSidebar }: IStyledMuiBackdrop) => ($leftSidebar ? '64px' : '0px');

export const Drawer = styled(MuiDrawer)<IStyledMuiBackdrop>`
  .MuiModal-backdrop {
    top: 56px;
    left: ${({ $leftSidebar }) => getLeftPosition({ $leftSidebar })};
  }

  &.MuiDrawer-modal {
    top: 56px;
    left: ${({ $leftSidebar }) => getLeftPosition({ $leftSidebar })};
  }

  .MuiDrawer-paper {
    top: 56px;
    height: calc(100% - 56px);

    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: 560px;
    }
  }
`;
