import platformApi from 'api/platformApi/platformApi';
import {
  IUpdateOrganizationAuthMethodRequestBody,
  IUpdateOrganizationAuthMethodResponse,
  IUpdateOrganizationAuthMethodServiceParamsPath,
} from 'api/platformApi/platformApi.types';

export const updateOrganizationAuthMethodService = async ({
  params: {
    path: { organizationId, methodId },
  },
  body,
}: ServiceFnConfig<
  IUpdateOrganizationAuthMethodServiceParamsPath,
  undefined,
  IUpdateOrganizationAuthMethodRequestBody
>) => {
  const response = await platformApi.put<IUpdateOrganizationAuthMethodResponse>(
    `/organizations/${organizationId}/sso-methods/${methodId}`,
    body,
  );

  return response.data;
};
