import { TableRowProps } from '@mui/material';
import { forwardRef } from 'react';
import * as Styled from './MFTableBodyRow.styles';

const MFTableBodyRow = forwardRef<HTMLTableRowElement, TableRowProps>((props, ref) => {
  return <Styled.TableRow ref={ref} {...props} />;
});

MFTableBodyRow.displayName = 'MFTableBodyRow';

export default MFTableBodyRow;
