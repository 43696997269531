import { Menu, MenuItem, Skeleton } from '@mui/material';
import { FC, useState } from 'react';
import BookmarkImage from '../BookmarkImage/BookmarkImage';
import * as Styled from './BookmarkItem.styles';
import { BookmarkItemProps } from './BookmarkItem.types';

const BookmarkItem: FC<BookmarkItemProps> = ({
  bookmark,
  disabled,
  onDeleteBookmark,
  onDragBookmark,
  isLoading,
  isMenuItem,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleContextMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleDragStart = () => {
    onDragBookmark(bookmark.id);
  };

  if (isLoading) {
    return (
      <Styled.Container>
        <Styled.Button size="small" style={{ pointerEvents: 'none' }}>
          <Skeleton variant="rounded" width={16} height={16} />
          <Skeleton variant="rounded" width={60} height={12} />
        </Styled.Button>
      </Styled.Container>
    );
  }

  const Component: React.ElementType = isMenuItem ? Styled.MenuItem : Styled.Button;

  return (
    <Styled.Container id={bookmark.id} draggable={!disabled} onDragStart={handleDragStart}>
      <Component
        size="small"
        onClick={() => {
          window.open(bookmark.url, '_blank');
        }}
        onContextMenu={handleContextMenu}
      >
        {bookmark.logoUrl ? <BookmarkImage src={bookmark.logoUrl} /> : <Styled.CustomLinkIcon />}
        <Styled.BookmarkName variant="labelSmall" color="text.secondary">
          {bookmark.name}
        </Styled.BookmarkName>
      </Component>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            onDeleteBookmark(bookmark.id);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Styled.Container>
  );
};

export default BookmarkItem;
