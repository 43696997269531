import { Dialog as MuiDialog } from '@mui/material';
import styled from 'styled-components';

export const Dialog = styled(MuiDialog)`
  & .MuiDialog-paper {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;
    max-width: 500px;
  }
  & .MuiDialogTitle-root {
    padding: ${({ theme }) => theme.spacing(2, 3)};
  }
  & .MuiDialogContent-root {
    padding: ${({ theme }) => theme.spacing(2, 3)};
  }
  & .MuiDialogActions-root {
    padding: ${({ theme }) => theme.spacing(1.75, 2.75)};
  }
`;
