import {
  Autocomplete as MuiAutocomplete,
  Popper as MuiPopper,
  Typography,
  TypographyProps,
} from '@mui/material';
import styled from 'styled-components';

export const Label = styled(Typography)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const SelectContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.surface.dividerMain};
  overflow: hidden;
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

export const Autocomplete = styled(MuiAutocomplete)`
  & .MuiAutocomplete-inputRoot::before: {
    border-bottom-color: transparent;
  },
}` as typeof MuiAutocomplete;

export const AutocompletePopper = styled(MuiPopper)`
  & .MuiPaper-root {
    box-shadow: ${({ theme }) => theme.shadows[8]};
  }
`;

export const NoOptionsText = styled(Typography)<TypographyProps>`
  text-align: center;
  padding: ${({ theme }) => theme.spacing(4, 0)};
`;

export const SelectedOptionsContainer = styled.div<{ $disabled?: boolean }>`
  & > * {
    border-top: 1px solid ${({ theme }) => theme.palette.surface.dividerMain};
  }

  ${(props) => {
    if (props.$disabled) {
      return `
      opacity: 0.5;
      pointer-events: none;
    `;
    }
  }}
`;

export const SelectedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;

export const SelectedDetails = styled.div`
  display: flex;
  align-items: center;
`;
