import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  ICreateOrganizationServiceRequestBody,
  ICreateOrganizationServiceResponse,
} from 'api/platformApi/platformApi.types';
import {
  CreateOrganizationOperationError,
  createOrganizationService,
} from './createOrganizationService';

function useCreateOrganization(
  mutationOptions?: UseMutationOptions<
    ICreateOrganizationServiceResponse,
    CreateOrganizationOperationError,
    ICreateOrganizationServiceRequestBody
  >,
) {
  return useMutation({
    ...mutationOptions,
    mutationKey: ['createOrganization'],
    mutationFn(body: ICreateOrganizationServiceRequestBody) {
      return createOrganizationService({ body });
    },
  });
}

export default useCreateOrganization;
