import { Typography } from '@mui/material';
import { IUser } from 'api/platformApi/platformApi.types';
import useUpdateWorkspaceProjectUsers from 'api/platformApi/services/updateWorkspaceProjectUsersService/useUpdateWorkspaceProjectUsers';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { useProjectAccessLevel } from 'hooks/useAccessLevel';
import { FC, useEffect, useState } from 'react';
import { isProjectInviteOnly } from 'utils/project';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import * as Styled from './ShareProjectDrawer.styles';
import { ShareProjectDrawerProps } from './ShareProjectDrawer.types';
import MemberList from './components/MemberList/MemberList';
import MemberSelect from './components/MemberSelect/MemberSelect';
import ProjectVisibility from './components/ProjectVisibility/ProjectVisibility';

const ShareProjectDrawer: FC<ShareProjectDrawerProps> = (props) => {
  const { open, onClose, project, currentMembers } = props;
  const workspaceId = useActiveWorkspace()?.id ?? '';
  const snackbar = useSnackbarContext();
  const [selectedMembers, setSelectedMembers] = useState<IUser[]>(currentMembers);
  const updateProjectUsers = useUpdateWorkspaceProjectUsers(workspaceId, project.id);
  const projectAccessLevel = useProjectAccessLevel({ workspaceId, projectId: project.id });

  useEffect(() => {
    // Reset selectedMembers when the drawer is closed without saving
    setSelectedMembers(currentMembers);
  }, [open, currentMembers]);

  const handleSave = () => {
    const selectedMemberIds = selectedMembers.map((user) => user.id);
    updateProjectUsers.mutate(
      { userIds: selectedMemberIds },
      {
        onSuccess: () => {
          onClose();
          snackbar.success('Project sharing updated');
        },
        onError: () => {
          onClose();
          snackbar.error('An error occurred and project sharing was not able to be updated');
        },
      },
    );
  };

  const handleRemoveMember = (member: IUser) => {
    setSelectedMembers((prev) => prev.filter((selectedMember) => selectedMember.id !== member.id));
  };

  const isInviteOnly = isProjectInviteOnly(project);
  const hasWriteAccess = projectAccessLevel === 'write';

  return (
    <AppDrawer open={open} onClose={onClose} leftSidebar={false}>
      <AppDrawerHeader title="Share project" onClose={onClose}></AppDrawerHeader>
      <AppDrawerContent>
        <ProjectVisibility visibility={project.visibility} />
        <Styled.Members>
          <Typography variant="titleMedium" color="text.primary">
            Members
          </Typography>
          <Styled.MemberSelectListContainer>
            {hasWriteAccess && isInviteOnly && (
              <MemberSelect
                selectedMembers={selectedMembers}
                onMemberSelect={(members) => {
                  setSelectedMembers(members);
                }}
                disabled={updateProjectUsers.isPending}
              />
            )}

            {selectedMembers.length > 0 && (
              <MemberList
                project={project}
                members={selectedMembers}
                onRemoveMember={handleRemoveMember}
                disabled={updateProjectUsers.isPending}
                renderActions={hasWriteAccess ? undefined : () => null}
              />
            )}
          </Styled.MemberSelectListContainer>
        </Styled.Members>
      </AppDrawerContent>

      {hasWriteAccess && isInviteOnly && (
        <Styled.CustomAppDrawerFooter>
          <Styled.SaveChangesButton
            type="button"
            variant="contained"
            size="large"
            color="primary"
            onClick={handleSave}
            disabled={updateProjectUsers.isPending}
          >
            {updateProjectUsers.isPending ? <MFButtonLoader /> : `Save changes`}
          </Styled.SaveChangesButton>
        </Styled.CustomAppDrawerFooter>
      )}
    </AppDrawer>
  );
};

export default ShareProjectDrawer;
