import { Alert as MuiAlert } from '@mui/material';
import styled, { css } from 'styled-components';

export const Alert = styled(MuiAlert)`
  min-width: 320px;
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme, severity }) =>
    !severity &&
    css`
      background-color: ${theme.palette.common.black};
    `}
`;
