import * as Styled from './ProjectsPage.styles';
import FlowDescriptionPopover from './components/FlowDescriptionPopover/FlowDescriptionPopover';
import ProjectsPanel from './components/ProjectsPanel/ProjectsPanel';

const ProjectsPage = () => {
  return (
    <>
      <Styled.Header>
        <span>projects</span>
        <FlowDescriptionPopover />
      </Styled.Header>
      <ProjectsPanel />
    </>
  );
};

export default ProjectsPage;
