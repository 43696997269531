import { Avatar as MuiAvatar, Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';

export const Avatar = styled(MuiAvatar)`
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 800;
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.dark};
  line-height: 24px;
  margin-right: 12px;
`;

export const NoOptionsText = styled(Typography)<TypographyProps>`
  text-align: center;
  padding: 32px 0px;
`;
