import platformApi from 'api/platformApi/platformApi';
import {
  IWorkspaceSolutionsServiceParamsPath,
  IWorkspaceSolutionsServiceResponse,
} from 'api/platformApi/platformApi.types';

export const getWorkspaceSolutions = async ({
  params: {
    path: { workspaceId },
  },
}: ServiceFnConfig<IWorkspaceSolutionsServiceParamsPath>) => {
  const axiosResponse = await platformApi.get<IWorkspaceSolutionsServiceResponse>(
    `/workspaces/${workspaceId}/solutions`,
  );
  const apiResponse = axiosResponse.data;
  const apiData = apiResponse;

  return apiData.data;
};
