import { TableRow as MuiTableRow } from '@mui/material';
import styled from 'styled-components';

export const TableRow = styled(MuiTableRow)`
  & th:first-child {
    border-radius: 8px 0px 0px 8px;
  }

  & th:last-child {
    border-radius: 0px 8px 8px 0px;
  }

  & th:only-child {
    border-radius: 8px;
  }
`;
