import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Typography } from '@mui/material';
import useOrganization from 'api/platformApi/services/organizationService/useOrganization';
import AdminWorkspacesIcon from 'components/Icons/AdminWorkspacesIcon';
import useAuthContext from 'contexts/auth/useAuthContext';
import { PERMISSIONS, validatePermissions } from 'utils/permissions';
import * as Styled from './AdminSideNav.styles';
import { MenuOption } from './AdminSideNav.types';
import OrganizationCard from './components/OrganizationCard/OrganizationCard';

const AdminSideNav = () => {
  const { user } = useAuthContext();

  const organizationId = user?.organizationId ?? '';
  /**
   * TODO: Remove the organization query after the `/auth/me` has been redeployed
   *       to include the organization in the user object.
   */
  const organizationQuery = useOrganization(organizationId);
  const organization = user?.organization ?? organizationQuery.data;

  const canManageUsers = validatePermissions([PERMISSIONS.PlatformManageUsers], user?.permissions);

  const canManageWorkspaces = validatePermissions(
    [PERMISSIONS.PlatformManageWorkspaces],
    user?.permissions,
  );

  const menuOptions: MenuOption[] = [];

  if (canManageUsers) {
    menuOptions.push({
      title: 'Users',
      link: '/admin/users',
      icon: <AccountCircleOutlinedIcon />,
    });
  }

  if (canManageWorkspaces) {
    menuOptions.push({
      title: 'Workspaces',
      link: '/admin/workspaces',
      icon: <AdminWorkspacesIcon />,
    });
  }

  return (
    <Styled.Container>
      <OrganizationCard isLoading={organizationQuery.isLoading} organization={organization} />

      <Styled.Menu>
        {menuOptions.map((option, optionIndex) => (
          <Styled.Link to={option.link} key={optionIndex}>
            {({ isActive }) => (
              <Styled.ListItem $selected={isActive}>
                <Styled.ListItemIcon $selected={isActive}>{option.icon}</Styled.ListItemIcon>
                <Styled.ListItemText>
                  <Typography variant="titleMedium">{option.title}</Typography>
                </Styled.ListItemText>
              </Styled.ListItem>
            )}
          </Styled.Link>
        ))}
      </Styled.Menu>
    </Styled.Container>
  );
};

export default AdminSideNav;
