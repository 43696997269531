import ListSkeleton from 'components/ui/ListSkeleton/ListSkeleton';
import { FC } from 'react';
import WorkspacesPageHeader from '../WorkspacesPageHeader/WorkspacesPageHeader';
import { WorkspacesLoaderProps } from './WorkspacesLoader.types';

const WorkspacesLoader: FC<WorkspacesLoaderProps> = (props) => {
  return (
    <>
      <WorkspacesPageHeader />
      <ListSkeleton headCells={props.headCells} rows={props.rows} />
    </>
  );
};

export default WorkspacesLoader;
