import { Button, Typography } from '@mui/material';
import useOrganization from 'api/platformApi/services/organizationService/useOrganization';
import { useReducer } from 'react';
import EditOrganizationAppsDrawer from '../EditOrganizationAppsDrawer/EditOrganizationAppsDrawer';
import * as Styled from './OrganizationApps.styles';
import { OrganizationAppsProps } from './OrganizationApps.types';

const OrganizationApps: React.FC<OrganizationAppsProps> = ({ organizationId }) => {
  const organizationQuery = useOrganization(organizationId);
  const apps = organizationQuery.data?.apps ?? [];

  const [isEditOrgAppsDrawerOpen, toggleEditOrgAppsDrawer] = useReducer((state) => !state, false);

  return (
    <>
      <Styled.Container>
        <Styled.TitleHeader>
          <Typography component="h2" variant="titleMedium">
            Applications
          </Typography>

          <Button onClick={toggleEditOrgAppsDrawer} variant="outlined" size="small">
            Edit
          </Button>
        </Styled.TitleHeader>

        <Styled.AppsContainer>
          {apps.length === 0 && (
            <Typography variant="bodySmall" color="text.secondary">
              No applications added to this organization
            </Typography>
          )}
          {apps.map((app) => (
            <Styled.AppCard key={app.id}>
              <Styled.AppLogo src={app.logo} alt={app.name} />
              <Typography variant="bodySmall" color="text.primary">
                {app.name}
              </Typography>
            </Styled.AppCard>
          ))}
        </Styled.AppsContainer>
      </Styled.Container>

      <EditOrganizationAppsDrawer
        open={isEditOrgAppsDrawerOpen}
        onClose={toggleEditOrgAppsDrawer}
        enabledApps={apps}
      />
    </>
  );
};

export default OrganizationApps;
