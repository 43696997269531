import { DoNotDisturbOnOutlined } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { IUser } from 'api/platformApi/platformApi.types';
import useAuthContext from 'contexts/auth/useAuthContext';
import React from 'react';
import * as Styled from './MemberList.styles';
import { MemberListProps } from './MemberList.types';

const MemberList: React.FC<MemberListProps> = ({
  disabled,
  members,
  onRemoveMember,
  project,
  renderActions = (member) => (
    <IconButton
      onClick={() => {
        onRemoveMember(member);
      }}
      color="error"
      disabled={disabled ?? member.id === project.owner.id}
    >
      <DoNotDisturbOnOutlined />
    </IconButton>
  ),
}) => {
  const { user: currentUser } = useAuthContext();

  const isCurrentUser = (user: IUser) => user.id === currentUser?.id;
  const isProjectOwner = (user: IUser) => user.id === project.owner.id;

  return (
    <Styled.Container $disabled={disabled}>
      {members.map((member) => (
        <Styled.MemberRow key={member.id}>
          <Styled.MemberDetails>
            <Styled.MemberAvatar>{member.name[0]}</Styled.MemberAvatar>
            <div>
              <Typography component="span" variant="bodyMedium">
                {member.name}
              </Typography>
              {` `}
              {isCurrentUser(member) && (
                <Typography component="span" variant="bodyMedium" color="text.secondary">
                  (me)
                </Typography>
              )}
            </div>
          </Styled.MemberDetails>
          {isProjectOwner(member) && (
            <Typography variant="titleSmall" color="text.primary">
              Owner
            </Typography>
          )}

          {renderActions(member)}
        </Styled.MemberRow>
      ))}
    </Styled.Container>
  );
};

export default MemberList;
