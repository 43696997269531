import platformApi from 'api/platformApi/platformApi';
import {
  IPlatformApiGenericError,
  IUpdateUserServiceParamsPath,
  IUpdateUserServiceRequestBody,
  IUpdateUserServiceResponse,
} from 'api/platformApi/platformApi.types';
import { ErrorSchema, InterfaceOperationError, isLastAdminError } from 'utils/httpErrors';
import { FORBIDDEN } from 'utils/httpStatuses';

export const updateUserService = async ({
  params: {
    path: { userId },
  },
  body,
}: ServiceFnConfig<IUpdateUserServiceParamsPath, undefined, IUpdateUserServiceRequestBody>) => {
  try {
    const response = await platformApi.put<IUpdateUserServiceResponse>(`/users/${userId}`, body);

    return response.data;
  } catch (error) {
    const apiError = UpdateUserOperationError.unwrapAPIError(error);

    if (apiError) {
      throw new UpdateUserOperationError(apiError);
    }

    throw error;
  }
};

type UpdateUserOperationErrorSchema = ErrorSchema<typeof FORBIDDEN>;

export class UpdateUserOperationError extends InterfaceOperationError<
  IUpdateUserServiceRequestBody,
  UpdateUserOperationErrorSchema
> {
  errorSchema: UpdateUserOperationErrorSchema = {
    [FORBIDDEN]: (apiError) => {
      if (isLastAdminError(apiError)) {
        this.scope = 'snackbar';
        this.message =
          'Unable to revoke admin permissions from the last admin in the organization.';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError, 'An error occured and the user could not be updated.', 'snackbar');
    this.scopeAPIError();
  }
}
