import { useQuery } from '@tanstack/react-query';
import { IOrganizationAuthMethodServiceParamsPath } from 'api/platformApi/platformApi.types';
import { organizationAuthMethodService } from './organizationAuthMethodService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseOrganizationAuthMethodParams extends IOrganizationAuthMethodServiceParamsPath {}

export const ORGANIZATION_AUTH_METHOD_QUERY_KEY_PREFIX = 'organizationAuthMethod';

export const organizationAuthMethodServiceQueryKey = (params: UseOrganizationAuthMethodParams) => [
  ORGANIZATION_AUTH_METHOD_QUERY_KEY_PREFIX,
  { ...params },
];

const useOrganizationAuthMethod = (params: UseOrganizationAuthMethodParams) => {
  return useQuery({
    queryKey: organizationAuthMethodServiceQueryKey(params),
    queryFn: () =>
      organizationAuthMethodService({
        params: {
          path: {
            organizationId: params.organizationId,
            methodId: params.methodId,
          },
        },
      }),
  });
};

export default useOrganizationAuthMethod;
