import platformApi from 'api/platformApi/platformApi';
import {
  ICreateOrganizationAdminServiceParamsPath,
  ICreateOrganizationAdminServiceRequestBody,
  ICreateOrganizationAdminServiceResponse,
  IPlatformApiGenericError,
} from 'api/platformApi/platformApi.types';
import {
  ErrorSchema,
  InterfaceOperationError,
  isOrganizationAdminExistsError,
} from 'utils/httpErrors';
import { CONFLICT } from 'utils/httpStatuses';

export const createOrganizationAdminService = async ({
  params: {
    path: { organizationId },
  },
  body,
}: ServiceFnConfig<
  ICreateOrganizationAdminServiceParamsPath,
  undefined,
  ICreateOrganizationAdminServiceRequestBody
>) => {
  try {
    const response = await platformApi.post<ICreateOrganizationAdminServiceResponse>(
      `/organizations/${organizationId}/invitations`,
      body,
    );

    return response.data;
  } catch (error) {
    const apiError = CreateOrganizationAdminOperationError.unwrapAPIError(error);
    if (apiError) throw new CreateOrganizationAdminOperationError(apiError);
    throw error;
  }
};

interface CreateOrganizationAdminOperationErrorFields {
  email: string;
}

type CreateOrganizationAdminOperationErrorSchema = ErrorSchema<typeof CONFLICT>;

export class CreateOrganizationAdminOperationError extends InterfaceOperationError<
  CreateOrganizationAdminOperationErrorFields,
  CreateOrganizationAdminOperationErrorSchema
> {
  errorSchema: CreateOrganizationAdminOperationErrorSchema = {
    [CONFLICT]: (apiError) => {
      if (isOrganizationAdminExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'email';
        this.message = 'This email is already registered';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(
      apiError,
      'An error occured and the organization admin could not be created.',
      'snackbar',
    );
    this.scopeAPIError();
  }
}
