import styled from 'styled-components';

export const AppLogo = styled.img`
  border-radius: ${({ theme }) => theme.shape.borderRadius * 1.5}px;
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const SelectedApplicationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;
