import ErrorIcon from '@mui/icons-material/Error';
import {
  useUpdateWorkspaceProject,
  useWorkspaceProject,
} from 'api/platformApi/services/workspaceProjectsService/hooks';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import ProjectVisibilitySelect from 'components/ProjectVisibilitySelect/ProjectVisiblitySelect';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import {
  getMaxLengthValidation,
  requiredValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import {
  PROJECT_DESCRIPTION_MAX_LENGTH,
  PROJECT_TITLE_MAX_LENGTH,
} from '../CreateProjectDrawer/CreateProjectDrawer';
import * as Styled from './EditProjectDrawer.styles';
import { EditProjectDrawerProps, IUpdateProjectForm } from './EditProjectDrawer.types';

const EditProjectDrawer: FC<EditProjectDrawerProps> = (props) => {
  const { open, onClose } = props;
  const snackbar = useSnackbarContext();
  const params = useParams();
  const workspaceId = useActiveWorkspace()?.id ?? '';
  const projectId = params.projectId ?? '';
  const project = useWorkspaceProject({ workspaceId, projectId });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm<IUpdateProjectForm>({
    mode: 'all',
    defaultValues: {
      name: '',
      description: '',
      visibility: 'private',
    },
  });

  const updateWorkspaceProject = useUpdateWorkspaceProject({
    onError(error) {
      if (error.scope === 'field' && error.field) {
        setError(error.field, { message: error.message });
      }
    },
    onSuccess: async () => {
      await project.refetch();
      reset();
      onClose();
      snackbar.success('Project updated');
    },
  });

  const onUpdateProjectSubmit: SubmitHandler<IUpdateProjectForm> = async (data) => {
    await updateWorkspaceProject.mutateAsync({ projectId, workspaceId, ...data });
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  useEffect(() => {
    reset({
      name: project.data?.name,
      description: project.data?.description ?? '',
      visibility: project.data?.visibility,
    });
  }, [reset, project.data]);

  return (
    <AppDrawer open={open} onClose={onClose}>
      <Styled.Form onSubmit={handleSubmit(onUpdateProjectSubmit)}>
        <AppDrawerHeader title="Edit Project" onClose={onClose}></AppDrawerHeader>

        <AppDrawerContent>
          {updateWorkspaceProject.error?.scope === 'alert' && (
            <Styled.Alert severity="error" icon={<ErrorIcon />}>
              {updateWorkspaceProject.error.message}
            </Styled.Alert>
          )}

          <RHFMonkTextField
            name="name"
            control={control}
            rules={{
              ...requiredValidation,
              ...getMaxLengthValidation(PROJECT_TITLE_MAX_LENGTH),
            }}
            value={project.data?.name}
            label="Project title"
            placeholder="Add a title..."
            disabled={isSubmitting}
            required
            fullWidth
            sx={{ marginTop: '24px' }}
          />

          <RHFMonkTextField
            name="description"
            control={control}
            rules={{
              ...getMaxLengthValidation(PROJECT_DESCRIPTION_MAX_LENGTH),
            }}
            value={project.data?.description}
            label="Description"
            placeholder="Describe the purpose of this project..."
            disabled={isSubmitting}
            fullWidth
            multiline
            rows={5}
            sx={{ marginTop: '24px' }}
          />

          <ProjectVisibilitySelect<IUpdateProjectForm>
            name="visibility"
            control={control}
            disabled
          />
        </AppDrawerContent>

        <Styled.CustomAppDrawerFooter>
          <Styled.EditProjectButton
            type="submit"
            disabled={isSubmitDisabled}
            variant="contained"
            size="large"
            color="primary"
          >
            {isSubmitting ? <MFButtonLoader /> : `Save Changes`}
          </Styled.EditProjectButton>
        </Styled.CustomAppDrawerFooter>
      </Styled.Form>
    </AppDrawer>
  );
};

export default EditProjectDrawer;
