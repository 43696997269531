import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 64px;
  flex-shrink: 0;
  background-color: #212121;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.appBar};
`;

export const NegativeRoundedDetail = styled.div`
  width: 16px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: -16px;
  z-index: ${({ theme }) => theme.zIndex.appBar};

  &:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0 0 0 8px #212121;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
