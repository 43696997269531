import React from 'react';
import { IAuthContext } from './AuthContext.types';

const DEFAULT_AUTH_CONTEXT = {
  state: 'unauthenticated',
  isUserAuthenticated: false,
  user: undefined,
  checkLoginStatus: () => {
    return;
  },
  login: () => {
    return;
  },
  logout: () => {
    return;
  },
} satisfies IAuthContext;

const AuthContext = React.createContext<IAuthContext>(DEFAULT_AUTH_CONTEXT);

export default AuthContext;
