const pluralRules = new Intl.PluralRules('en-US');

/**
 * Simple utility to handle singular and plural formats.
 * Taken from the 2ality.com blog article.
 *
 * @see https://2ality.com/2019/12/intl-pluralrules.html
 */
export function pluralize(count: number, singular: string, plural: string) {
  const grammaticalNumber = pluralRules.select(count);

  switch (grammaticalNumber) {
    case 'one':
      return singular;
    case 'other':
      return plural;
    default:
      throw new Error('Unknown: ' + grammaticalNumber);
  }
}
