import { Avatar as MuiAvatar, Typography } from '@mui/material';
import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import styled from 'styled-components';

export const CustomMFTableHeadCell = styled(MFTableHeadCell)`
  width: 33.33%;
`;

export const NameContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled(MuiAvatar)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.palette.primary.dark};
  background-color: ${({ theme }) => theme.palette.primary.light};
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 166%;
  letter-spacing: 0.4px;
`;

export const Name = styled(Typography)`
  margin-left: 12px;
`;

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CustomMFTableContainer = styled(MFTableContainer)`
  padding: 24px;
`;
