import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  IPlatformApiGenericError,
  IUpdateWorkspaceSolutionsServiceParamsPath,
  IUpdateWorkspaceSolutionsServiceRequestBody,
} from 'api/platformApi/platformApi.types';
import { AxiosError } from 'axios';
import { updateWorkspaceSolutionsService } from './updateWorkspaceSolutionsService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseUpdateWorkspaceSolutionsParams extends IUpdateWorkspaceSolutionsServiceParamsPath {}

const useUpdateWorkspaceSolutions = (
  params: UseUpdateWorkspaceSolutionsParams,
  mutationOptions?: UseMutationOptions<
    unknown,
    AxiosError<IPlatformApiGenericError>,
    IUpdateWorkspaceSolutionsServiceRequestBody
  >,
) => {
  return useMutation({
    ...mutationOptions,
    mutationFn: (solutionsData: IUpdateWorkspaceSolutionsServiceRequestBody) => {
      return updateWorkspaceSolutionsService({
        params: {
          path: {
            workspaceId: params.workspaceId,
          },
        },
        body: solutionsData,
      });
    },
  });
};

export default useUpdateWorkspaceSolutions;
