import PlatformApiSdk from '@monksflow/platform-api-sdk';

// AUTH_CALLBACK_URL is the full URL that will be used as the callback/redirect URI
// in the OAuth 2.0 / OpenID Connect authentication flow. This URL should be
// provided to the client so they can configure it in their Identity Provider (IDP).
export const AUTH_CALLBACK_URL = import.meta.env.PLATFORM_API_BASE_URL + '/auth/callback';

export function organizationLogin(methodId: string, redirectUri?: string) {
  const windowLocation = window.location;
  const fallbackRedirectUrl = `${windowLocation.protocol}//${windowLocation.host}`;
  const finalRedirectUrl = redirectUri ? redirectUri : fallbackRedirectUrl;
  const encodedRedirectUrl = encodeURIComponent(finalRedirectUrl);

  window.location.assign(
    `${import.meta.env.PLATFORM_API_BASE_URL}/auth/methods/${methodId}/login?redirectUri=${encodedRedirectUrl}`,
  );
}

export function redirectToLogout() {
  window.location.assign(`${import.meta.env.PLATFORM_API_BASE_URL}/auth/logout`);
}

export async function authenticateUser() {
  try {
    const authResponse = await PlatformApiSdk.getAuthenticatedUser();
    return authResponse.data;
  } catch (error) {
    return undefined;
  }
}

/**
 * @see `src/api/platformApi/platformApi.ts`
 */
export async function refreshToken() {
  try {
    const refreshResponse = await PlatformApiSdk.refreshToken();
    return refreshResponse.status === 204;
  } catch (error) {
    return false;
  }
}
