import { IProjectFile } from 'api/platformApi/platformApi.types';
import useWorkspaceProjectFiles from 'api/platformApi/services/workspaceProjectFilesService/useWorkspaceProjectFiles';
import { ITableOrderDirection } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import PageFeedback from 'components/ui/PageFeedback/PageFeedback';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import createFileIntentUrl from 'utils/fileIntentUrl';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import useInfiniteScrollRef from '../../../../hooks/useInfiniteScrollRef';
import { IProjectFileWithCreatedByString, IProjectFilesOrderBy } from './ProjectFiles.types';
import { getProjectFilesComparator } from './ProjectFiles.utils';
import {
  ProjectFilesTable,
  ProjectFilesTableRow,
  ProjectFilesTableRowSkeleton,
} from './components/ProjectFilesTable/ProjectFilesTable';

// On a 1000px height viewport triggers only the loading of the first page
const PROJECT_FILES_PAGE_SIZE = 15;

const ProjectFiles = () => {
  const params = useParams();
  const workspaceId = useActiveWorkspace()?.id ?? '';
  const workspaceSlug = useActiveWorkspace()?.slug ?? '';
  const projectId = params.projectId ?? '';

  const [orderBy, setOrderBy] = useState<IProjectFilesOrderBy>('name');
  const [orderDirection, setOrderDirection] = useState<ITableOrderDirection>('asc');
  const [selectedFile, setSelectedFile] = useState<IProjectFile | null>(null);

  const {
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    ...projectFilesQuery
  } = useWorkspaceProjectFiles({
    projectId,
    workspaceId,
    limit: PROJECT_FILES_PAGE_SIZE,
  });
  const projectFiles = projectFilesQuery.data?.pages.flatMap((page) => page.data) ?? [];

  const infiniteScrollRef = useInfiniteScrollRef({
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  });

  const sortedProjectFiles: IProjectFileWithCreatedByString[] = projectFiles
    .map((projectFile) => {
      return {
        createdByString: projectFile.createdBy.name,
        ...projectFile,
      };
    })
    .sort(getProjectFilesComparator(orderDirection, orderBy));

  const handleSort = (headCellId: IProjectFilesOrderBy) => {
    if (orderBy === headCellId) {
      setOrderDirection((orderDirection) => (orderDirection === 'desc' ? 'asc' : 'desc'));
      return;
    }

    setOrderBy(headCellId);
    setOrderDirection('asc');
  };

  const handleSelectFile = (file: IProjectFile) => {
    setSelectedFile(file);
  };

  const handleOpenFile = (file: IProjectFile) => {
    const url = createFileIntentUrl(file, 'open', { workspaceSlug, projectId });
    window.open(url, '_blank');
  };

  if (projectFilesQuery.data) {
    if (projectFiles.length === 0) {
      return (
        <PageFeedback
          title="No project files"
          description="No project files have been created for this project yet. Get started by opening an application and creating a new file for this project."
        />
      );
    }

    return (
      <ProjectFilesTable orderedBy={orderBy} orderedDirection={orderDirection} onSort={handleSort}>
        {sortedProjectFiles.map((projectFile) => (
          <ProjectFilesTableRow
            key={projectFile.id}
            projectFile={projectFile}
            onOpen={handleOpenFile}
            onSelect={handleSelectFile}
            selected={selectedFile?.id === projectFile.id}
            ref={infiniteScrollRef}
          />
        ))}

        {isFetchingNextPage && <ProjectFilesSkeleton />}
      </ProjectFilesTable>
    );
  }

  if (projectFilesQuery.isError) {
    return (
      <PageFeedback
        title="Files failed to load"
        description="An error occurred and files could not be loaded. Please try refreshing the page to access project files."
      />
    );
  }

  return (
    <ProjectFilesTable orderedBy={orderBy} orderedDirection={orderDirection} onSort={handleSort}>
      <ProjectFilesSkeleton />
    </ProjectFilesTable>
  );
};

export default ProjectFiles;

const ProjectFilesSkeleton = () =>
  Array.from({ length: PROJECT_FILES_PAGE_SIZE - 5 }).map((_, index) => (
    <ProjectFilesTableRowSkeleton key={index} />
  ));
