import { IApp } from 'api/platformApi/platformApi.types';

function createOpenSolutionUrl(
  app: IApp,
  params: {
    workspaceSlug: string;
    pipelineId: string;
  },
): string | null {
  const pipelineFileType = app.files.find((type) => type.name === 'pipeline-execution');
  const openExecutionIntent = pipelineFileType?.intents.find((i) => i.name === 'open');
  const template = openExecutionIntent?.template;
  if (!template) return null;

  const resolvedUrl = template
    .replace(':workspace-slug', params.workspaceSlug)
    .replace(':file-id', params.pipelineId);

  return `${app.hostedUrl}${resolvedUrl}`;
}

export default createOpenSolutionUrl;
