import styled from 'styled-components';

export const Organization = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.surface.dividerMain};
`;

export const OrganizationImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
`;
