import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import * as Styled from './PageHeader.styles';
import { PageHeaderProps } from './PageHeader.types';

const PageHeader = ({ title, backTo, size, isLoading, children }: PageHeaderProps) => {
  return (
    <Styled.Container>
      <Styled.BackdropFilter />
      {backTo && (
        <Link to={backTo}>
          <Styled.BackButton size="small">
            <KeyboardBackspaceIcon sx={{ fontSize: 20 }} />
          </Styled.BackButton>
        </Link>
      )}
      <Styled.Title $size={size}>
        {isLoading ? <Skeleton variant="rounded" width="50%" height={20} /> : title}
      </Styled.Title>
      <Styled.ControlsContainer>{children}</Styled.ControlsContainer>
    </Styled.Container>
  );
};

export default PageHeader;
