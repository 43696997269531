import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  IAddWorkspaceUsersRequestBody,
  IAddWorkspaceUsersResponse,
  IPlatformApiGenericError,
} from 'api/platformApi/platformApi.types';
import { AxiosError } from 'axios';
import { addWorkspaceUsers } from './addWorkspaceUsersService';

function useAddWorkspaceUsers(
  workspaceId: string,
  mutationOptions?: UseMutationOptions<
    IAddWorkspaceUsersResponse,
    AxiosError<IPlatformApiGenericError>,
    IAddWorkspaceUsersRequestBody
  >,
) {
  return useMutation({
    ...mutationOptions,
    mutationFn: (userData: IAddWorkspaceUsersRequestBody) => {
      return addWorkspaceUsers(workspaceId, userData).then((r) => r.data);
    },
  });
}

export default useAddWorkspaceUsers;
