import { Skeleton } from '@mui/material';
import { FC } from 'react';
import * as Styled from './SolutionItem.styles';
import { SolutionItemProps } from './SolutionItem.types';

const SolutionItem: FC<SolutionItemProps> = ({ name, description, onClick, isLoading }) => {
  if (isLoading) {
    return (
      <Styled.SolutionContainer>
        <Skeleton variant="text" />
        <div>
          <Skeleton variant="text" sx={{ height: 20 }} />
          <Skeleton variant="rounded" sx={{ height: 10, width: '65%' }} />
        </div>
      </Styled.SolutionContainer>
    );
  }

  return (
    <Styled.SolutionContainer onClick={onClick}>
      <Styled.SolutionTitle variant="titleMedium">{name}</Styled.SolutionTitle>
      <Styled.SolutionDescription variant="bodySmall">{description}</Styled.SolutionDescription>
    </Styled.SolutionContainer>
  );
};

export default SolutionItem;
