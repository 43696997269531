import platformApi from 'api/platformApi/platformApi';
import {
  ICreateOrganizationServiceRequestBody,
  ICreateOrganizationServiceResponse,
  IPlatformApiGenericError,
} from 'api/platformApi/platformApi.types';
import {
  ErrorSchema,
  InterfaceOperationError,
  isOrganizationNameExistsError,
} from 'utils/httpErrors';
import { CONFLICT } from 'utils/httpStatuses';

export const createOrganizationService = async ({
  body,
}: ServiceFnConfig<undefined, undefined, ICreateOrganizationServiceRequestBody>) => {
  try {
    const response = await platformApi.post<ICreateOrganizationServiceResponse>(
      '/organizations',
      body,
    );

    return response.data;
  } catch (error) {
    const apiError = CreateOrganizationOperationError.unwrapAPIError(error);
    if (apiError) throw new CreateOrganizationOperationError(apiError);
    throw error;
  }
};

interface CreateOrganizationOperationErrorFields {
  name: string;
}

type CreateOrganizationOperationErrorSchema = ErrorSchema<typeof CONFLICT>;

export class CreateOrganizationOperationError extends InterfaceOperationError<
  CreateOrganizationOperationErrorFields,
  CreateOrganizationOperationErrorSchema
> {
  errorSchema: CreateOrganizationOperationErrorSchema = {
    [CONFLICT]: (apiError) => {
      if (isOrganizationNameExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'An organization already exists with this name.';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError, 'An error occurred and the organization could not be created.');
    this.scopeAPIError();
  }
}
