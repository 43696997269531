import { Button as MuiButton } from '@mui/material';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const sharedStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.common.whiteOutlinedBorder};
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.common.white};
  }

  &.active {
    color: ${({ theme }) => theme.palette.common.white};
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    opacity: 0.38;
  }
`;

export const SideNavItemLink = styled(RouterNavLink)`
  ${sharedStyles}
`;

export const SideNavItemButton = styled.button`
  ${sharedStyles}
`;

export const IconContainer = styled(MuiButton)`
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
  color: ${({ theme }) => theme.palette.common.whiteOutlinedBorder};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.whiteHover};
  }

  ${SideNavItemLink}:hover &,
  ${SideNavItemButton}:hover & {
    background-color: ${({ theme }) => theme.palette.common.whiteHover};
  }

  ${SideNavItemLink}.active &,
  ${SideNavItemButton}.active & {
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.common.whiteSelected};
    &:hover {
      background-color: ${({ theme }) => theme.palette.common.whiteSelected};
    }
  }
`;
