import { MenuItem } from '@mui/material';
import styled, { css } from 'styled-components';
import { Styles } from 'styled-components/dist/types';

export const SelectOption = styled(MenuItem)`
  &.MuiMenuItem-root {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SelectOptionLabel = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const SelectOptionDescription = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
  ${({ theme }) => css(theme.typography.bodySmall as Styles<object>)};
  line-height: 16px;

  .MuiInput-input & {
    display: none;
  }
`;
