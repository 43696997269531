import { Alert, Button, CircularProgress } from '@mui/material';
import { AppDrawerFooter } from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter.styles';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CustomAppDrawerFooter = styled(AppDrawerFooter)`
  justify-content: end;
`;

export const CreateProjectButton = styled(Button)`
  border-radius: 24px;
  padding: 8px 24px;
  min-height: 42px;
  min-width: 155px;
`;

export const MuiCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.action.disabled};
`;

export const MuiAlert = styled(Alert)`
  color: #5f2120;
  background-color: #fdeded;
`;
