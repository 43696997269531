import platformApi from 'api/platformApi/platformApi';
import {
  IUpdateOrganizationAppsServiceParamsPath,
  IUpdateOrganizationAppsServiceRequestBody,
  IUpdateOrganizationAppsServiceResponse,
} from 'api/platformApi/platformApi.types';

export const updateOrganizationAppsService = async ({
  params: {
    path: { organizationId },
  },
  body,
}: ServiceFnConfig<
  IUpdateOrganizationAppsServiceParamsPath,
  undefined,
  IUpdateOrganizationAppsServiceRequestBody
>) => {
  const response = await platformApi.post<IUpdateOrganizationAppsServiceResponse>(
    `/organizations/${organizationId}/apps`,
    body,
  );

  return response.data;
};
