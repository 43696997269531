import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  IUpdateInvitationRequestBody,
  IUpdateInvitationResponse,
  IUpdateInvitationServiceParamsPath,
} from 'api/platformApi/platformApi.types';
import { updateInvitation } from './updateInvitationService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseUpdateInvitationParams extends IUpdateInvitationServiceParamsPath {}

function useUpdateInvitation(
  params: UseUpdateInvitationParams,
  mutationOptions?: UseMutationOptions<
    IUpdateInvitationResponse,
    unknown,
    IUpdateInvitationRequestBody
  >,
) {
  return useMutation({
    ...mutationOptions,
    mutationFn: (body) => {
      return updateInvitation({
        params: {
          path: {
            invitationId: params.invitationId,
          },
        },
        body,
      });
    },
  });
}

export default useUpdateInvitation;
