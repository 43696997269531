import { MenuItem, Typography } from '@mui/material';
import React, { FC } from 'react';
import * as Styled from './UserMenu.styles';
import { UserMenuProps } from './UserMenu.types';
import ContactSupportLink from './components/ContactSupportLink/ContactSupportLink';
import LegalInformationLink from './components/LegalInformationLink/LegalInformationLink';

const UserMenu: FC<UserMenuProps> = ({ user, onSignOutClick, actions }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Styled.IconButton
        id="user-menu-button"
        aria-controls={open ? 'user-menu-selector' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size="small"
        onClick={handleClick}
      >
        <Styled.Avatar>{user?.name.charAt(0).toUpperCase()}</Styled.Avatar>
      </Styled.IconButton>

      <Styled.UserMenu
        id="user-menu-selector"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
      >
        <Styled.UserInfo>
          <Typography variant="labelLarge" color="text.primary" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="bodySmall" color="text.secondary" noWrap>
            {user?.email}
          </Typography>
        </Styled.UserInfo>

        <Styled.Divider />

        {actions?.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => {
              item.action();
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}

        <MenuItem
          component={LegalInformationLink}
          onClick={() => {
            handleClose();
          }}
        />

        <MenuItem
          component={ContactSupportLink}
          onClick={() => {
            handleClose();
          }}
        />

        <Styled.Divider />

        <MenuItem
          onClick={() => {
            onSignOutClick();
            handleClose();
          }}
        >
          Sign out
        </MenuItem>
      </Styled.UserMenu>
    </>
  );
};

export default UserMenu;
