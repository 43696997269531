import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import styled from 'styled-components';

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const CustomMFTableContainer = styled(MFTableContainer)`
  padding: ${({ theme }) => theme.spacing(3)};
`;
