import { Skeleton, TableCell } from '@mui/material';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import { headCells } from './ProjectsTable.consts';

export const ProjectsTableRowSkeleton = () => {
  return (
    <MFTableBodyRow>
      {headCells.map((cell) => (
        <TableCell key={cell.id}>
          <Skeleton variant="text" />
        </TableCell>
      ))}
    </MFTableBodyRow>
  );
};
