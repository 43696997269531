import platformApi from 'api/platformApi/platformApi';
import {
  IResendOrganizationAdminInvitationResponse,
  IRevokeInvitationParamsPath,
  IRevokeOrganizationInvitationServiceParamsPath,
} from 'api/platformApi/platformApi.types';

export const revokeInvitationService = async (
  config: ServiceFnConfig<IRevokeInvitationParamsPath>,
) => {
  const { invitationId } = config.params.path;
  const networkResponse = await platformApi.post<IResendOrganizationAdminInvitationResponse>(
    `/invitations/${invitationId}/actions/revoke`,
  );
  const apiResponse = networkResponse.data;
  const apiData = apiResponse;

  return apiData;
};

export const revokeOrganizationInvitationService = async ({
  params: {
    path: { invitationId, organizationId },
  },
}: ServiceFnConfig<IRevokeOrganizationInvitationServiceParamsPath>) => {
  const networkResponse = await platformApi.post<IResendOrganizationAdminInvitationResponse>(
    `/organizations/${organizationId}/invitations/${invitationId}/actions/revoke`,
  );
  const apiResponse = networkResponse.data;
  const apiData = apiResponse;

  return apiData;
};
