import { cloneElement, useEffect, useRef, useState } from 'react';

const FitText = ({
  children,
  base,
  min = 16,
  step = 4,
}: {
  children: React.ReactElement;
  base: number;
  min?: number;
  step?: number;
}) => {
  const [observer] = useState(
    () =>
      new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.target instanceof HTMLElement) {
            resetText(entry.target, { base });
            resizeText(entry.target, { base, min, step });
          }
        });
      }),
  );

  const textElementRef = useRef<HTMLElement>();
  const child = cloneElement(children, {
    ref: textElementRef,
  });

  useEffect(() => {
    const { current: textElement } = textElementRef;

    if (textElement && !textElement.style.fontSize) {
      /**
       * Set the desired font size for the element and prevent it from wrapping
       * to the next line so we can accurately measure if it has overflown.
       */
      textElement.style.fontSize = `${base}px`;
      textElement.style.overflow = 'hidden';
      textElement.style.textOverflow = 'ellipsis';
      textElement.style.whiteSpace = 'nowrap';
    }

    if (!textElement) return;

    observer.observe(textElement);

    return () => {
      observer.unobserve(textElement);
    };
  }, [base, min, textElementRef, observer]);

  return child;
};

function resetText(element: HTMLElement, { base }: { base: number }) {
  element.style.fontSize = `${base}px`;
}

function resizeText(
  element: HTMLElement,
  { min, step }: { base: number; min: number; step: number },
) {
  let current = Number.parseFloat(element.style.fontSize);
  let next: number | null = current - step;

  while (hasOverflown(element) && next >= min) {
    element.style.fontSize = `${next}px`;

    current = next;
    next = current - step;
  }
}

function hasOverflown(element: HTMLElement) {
  return element.scrollWidth > element.clientWidth;
}

export default FitText;
