import platformApi from 'api/platformApi/platformApi';
import {
  IAddWorkspaceUsersRequestBody,
  IAddWorkspaceUsersResponse,
} from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const addWorkspaceUsers = (
  workspaceId: string,
  userData: IAddWorkspaceUsersRequestBody,
  axiosConfig?: AxiosRequestConfig,
) => {
  return platformApi.post<
    IAddWorkspaceUsersResponse,
    AxiosResponse<IAddWorkspaceUsersResponse>,
    IAddWorkspaceUsersRequestBody
  >(`/workspaces/${workspaceId}/users`, userData, axiosConfig);
};
