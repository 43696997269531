import { Button as MuiButton, IconButton as MuiIconButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.surface.level1};
  border-radius: ${({ theme }) => theme.spacing(2)};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.02);
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const Form = styled.form`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const OrganizationLogo = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
`;

export const IconButton = styled(MuiIconButton)`
  margin-right: ${({ theme }) => theme.spacing(-1)};
`;

export const Button = styled(MuiButton)`
  min-width: 100px;
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
