import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useReducer } from 'react';
import { ConfirmDialogProps } from './ConfirmDialog.types';

const ConfirmDialog = ({
  id,
  content,
  disabled,
  label,
  loading,
  onCancel,
  onConfirm,
  open,
  title,
}: ConfirmDialogProps) => {
  const [processing, toggleProcessing] = useReducer((state) => !state, false);

  const handleRevoke = async () => {
    try {
      toggleProcessing();
      await onConfirm();
    } finally {
      toggleProcessing();
    }
  };

  const isDisabled = disabled ?? processing;
  const isProcessing = loading ?? processing;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      maxWidth="xs"
    >
      <DialogTitle id={`${id}-title`}>{title}</DialogTitle>

      <DialogContent id={`${id}-description`}>{content}</DialogContent>

      <DialogActions>
        <Button disabled={isDisabled} onClick={onCancel} type="button" variant="text" color="black">
          Cancel
        </Button>

        <Button
          disabled={isDisabled}
          onClick={handleRevoke}
          variant="contained"
          size="medium"
          color="error"
        >
          {isProcessing ? (
            <CircularProgress
              size={20}
              value={90}
              sx={{ color: 'action.disabled' }}
              thickness={4}
            />
          ) : (
            label
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
