import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import { useReducer } from 'react';
import CreateOrganizationDrawer from '../CreateOrganizationDrawer/CreateOrganizationDrawer';

const OrgManagementPageHeader = () => {
  const [isCreateOrgDrawerOpen, toggleCreateOrgDrawer] = useReducer((state) => !state, false);

  return (
    <>
      <CreateOrganizationDrawer open={isCreateOrgDrawerOpen} onClose={toggleCreateOrgDrawer} />

      <PageHeader title="Organizations">
        <Button
          onClick={toggleCreateOrgDrawer}
          startIcon={<AddOutlinedIcon />}
          variant="contained"
          size="small"
        >
          Create organization
        </Button>
      </PageHeader>
    </>
  );
};

export default OrgManagementPageHeader;
