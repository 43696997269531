import { Button as MuiButton } from '@mui/material';
import MuiMenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';

export const Button = styled(MuiButton)`
  height: 32px;
  min-width: auto;
  padding: 0;
  border-radius: 8px;
  background-color: transparent;
  color: #fff;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};

  & .MuiListItemIcon-root {
    min-width: 24px;
  }
`;

export const WorkspaceImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

export const WorkspaceName = styled.span`
  flex: 1;
`;
