import { Button } from '@mui/material';
import AppDrawerFooter from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CustomAppDrawerFooter = styled(AppDrawerFooter)`
  justify-content: end;
`;

export const SaveChangesButton = styled(Button)`
  min-height: 42px;
  min-width: 155px;
`;
