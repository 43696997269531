import MuiButtonBase from '@mui/material/ButtonBase';
import styled, { css } from 'styled-components';

export const Container = styled(MuiButtonBase)<{ $isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  border-radius: 16px;
  gap: 8px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background-color: ${({ theme }) => theme.palette.surface.level1};
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.04);
  transition:
    background-color 300ms ease-out,
    box-shadow 300ms ease-out;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      background-color: ${({ theme }) => theme.palette.common.blackHover};
      box-shadow: none;
      pointer-events: none;
    `}

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.blackHover};
    box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.08);
  }
`;

export const Icon = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 16px;
  margin-bottom: 8px;
`;

export const Name = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.palette.text.primary};
  /* utility ♋/title/medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.1px;
`;

export const Description = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin: 0;
  color: ${({ theme }) => theme.palette.text.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  /* utility ♋/body/small */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;
