import { useEffect, useState } from 'react';

export interface PathwaysPipeline {
  id: string;
  projectId: string;
  workspaceId: string;
  versionId: string;
  name: string;
  description: string;
  createdAt: string;
  createdBy: string;
  createdByUsername: string;
  lastUpdatedBy: string;
  lastUpdatedByUsername: string;
  updatedAt: string;
  deletedAt: string;
  type: 'default';
  executionCount: number;
  publishedVersions: number;
  pendingReviewCount: number;
  failedCount: number;
  mostRecentlyStartedExecutionDate: string;
}

export const mockedPathwaysPipelines: PathwaysPipeline[] = [
  {
    id: '550e8400-e29b-41d4-a716-446655440000',
    projectId: 'proj-123',
    workspaceId: 'ws-456',
    versionId: 'v-789',
    name: 'Data Processing [DEMO Pipeline]',
    description: 'This pipeline processes data from multiple sources.',
    createdAt: '2023-01-01T10:00:00Z',
    createdBy: 'user-001',
    createdByUsername: 'jdoe',
    lastUpdatedBy: 'user-002',
    lastUpdatedByUsername: 'asmith',
    updatedAt: '2023-01-15T12:30:00Z',
    deletedAt: '',
    type: 'default',
    executionCount: 5,
    publishedVersions: 2,
    pendingReviewCount: 0,
    failedCount: 1,
    mostRecentlyStartedExecutionDate: '2023-01-14T09:00:00Z',
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174000',
    projectId: 'proj-456',
    workspaceId: 'ws-789',
    versionId: 'v-012',
    name: 'ETL [DEMO Pipeline]',
    description: 'Extract, transform, and load data to the data warehouse.',
    createdAt: '2023-02-05T08:45:00Z',
    createdBy: 'user-003',
    createdByUsername: 'bwalker',
    lastUpdatedBy: 'user-004',
    lastUpdatedByUsername: 'cjohnson',
    updatedAt: '2023-02-20T14:15:00Z',
    deletedAt: '',
    type: 'default',
    executionCount: 10,
    publishedVersions: 3,
    pendingReviewCount: 1,
    failedCount: 2,
    mostRecentlyStartedExecutionDate: '2023-02-19T07:30:00Z',
  },
  {
    id: '9b1deb4d-cf00-4124-8f9b-5b6b6c6b6b6b',
    projectId: 'proj-789',
    workspaceId: 'ws-012',
    versionId: 'v-345',
    name: 'AI Model Training [DEMO Pipeline]',
    description: 'Pipeline for training and evaluating AI models.',
    createdAt: '2023-03-10T09:00:00Z',
    createdBy: 'user-005',
    createdByUsername: 'dlucas',
    lastUpdatedBy: 'user-006',
    lastUpdatedByUsername: 'emartin',
    updatedAt: '2023-03-25T11:45:00Z',
    deletedAt: '',
    type: 'default',
    executionCount: 7,
    publishedVersions: 1,
    pendingReviewCount: 2,
    failedCount: 0,
    mostRecentlyStartedExecutionDate: '2023-03-24T13:00:00Z',
  },
];

export const useFakeQuery = <T>(data: T, delay = 2000) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [result, setResult] = useState<T | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setResult(data);
      setIsLoading(false);
      setIsError(false);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [data, delay]);

  return { isLoading, isError, data: result };
};

export const useMockedPathwaysPipelines = () =>
  useFakeQuery<PathwaysPipeline[]>(mockedPathwaysPipelines);
