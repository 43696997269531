import {
  ImageFileChooserErrorCode,
  ImageFileRequirements,
} from 'components/ImageFileChooser/ImageFileChooser.types';
import {
  ImageFileChooserError,
  convertBytesToReadable,
  convertToBytes,
} from 'components/ImageFileChooser/ImageFileChooser.utils';

export const logoRequirements = {
  aspectRatio: 1,
  types: ['image/png'],
  maxSize: convertToBytes(400, 'KB'),
  minDimension: 300,
  maxDimension: 300,
} satisfies ImageFileRequirements;

export function translateImageFileChooseErrors(errors: ImageFileChooserError[]) {
  const errorMessages = errors.map((error) => {
    switch (error.code) {
      case ImageFileChooserErrorCode.FileAspectRatioOff:
      case ImageFileChooserErrorCode.FileDimensionsOff:
        return `The image should have between ${logoRequirements.maxDimension} pixels and ${logoRequirements.minDimension} pixels in square format.`;

      case ImageFileChooserErrorCode.FileTypeNotAccepted:
        return 'The image selected contains a non supported format.';

      case ImageFileChooserErrorCode.FileTooBig:
        return `The image is exceeding the maximum size of ${convertBytesToReadable(logoRequirements.maxSize)}.`;

      default:
        return 'Unexpected error.';
    }
  });
  const uniqueErrors = new Set(errorMessages);

  return Array.from(uniqueErrors);
}
