import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { OrgAdminInvitationOptionsProps } from './OrgAdminInvitationOptions.types';

const OrgAdminInvitationOptions: FC<OrgAdminInvitationOptionsProps> = ({
  onResendInvitation,
  onRevokeInvitation,
}) => {
  const [optionsButtonElement, setOptionsButtonElement] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(optionsButtonElement);

  const handleOptionsOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setOptionsButtonElement(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setOptionsButtonElement(null);
  };

  const menuOptions = [
    {
      label: 'Resend invitation',
      handler: () => {
        handleOptionsClose();
        onResendInvitation();
      },
    },
    {
      label: 'Revoke',
      handler: () => {
        handleOptionsClose();
        onRevokeInvitation();
      },
    },
  ];

  return (
    <>
      <IconButton onClick={handleOptionsOpen} size="medium">
        <MoreHorizIcon />
      </IconButton>

      <Menu
        open={isMenuOpen}
        onClose={handleOptionsClose}
        anchorEl={optionsButtonElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuOptions.map((option) => (
          <MenuItem onClick={option.handler} key={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OrgAdminInvitationOptions;
