import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { ComponentProps, forwardRef } from 'react';

interface CheckboxFieldProps extends ComponentProps<typeof Checkbox> {
  label: string;
  helperText?: string;
  size?: 'small';
}

const CheckboxField = forwardRef<HTMLButtonElement, CheckboxFieldProps>(
  ({ helperText, id, label, size, ...props }, ref) => {
    return (
      <FormControl
        sx={{
          '& .MuiFormControlLabel-root': {
            margin: 0,
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              {...props}
              id={id}
              aria-describedby={`${id}-helper`}
              sx={{ padding: 0.5 }}
              size="small"
              ref={ref}
            />
          }
          label={label}
          sx={{
            '& .MuiFormControlLabel-label': {
              marginLeft: size === 'small' ? 1 : 1.5,
              fontSize: (theme) =>
                size === 'small'
                  ? theme.typography.bodyMedium.fontSize
                  : theme.typography.bodyLarge.fontSize,
            },
          }}
        />

        {helperText && (
          <FormHelperText id={`${id}-helper`} sx={{ marginLeft: size === 'small' ? 4.5 : 5 }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

CheckboxField.displayName = 'CheckboxField';

export default CheckboxField;
