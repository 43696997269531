import { ADMIN_HEADER_HEIGHT } from 'components/AdminHeader/AdminHeader.styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - ${ADMIN_HEADER_HEIGHT}px);
  background: ${({ theme }) => theme.palette.surface.level0};
`;

export const Content = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0, 4, 4)};
  overflow-y: auto;
`;
