import { TableCell, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { IInvitationStatus } from 'api/platformApi/platformApi.types';
import { getInvitationsQueryKey } from 'api/platformApi/services/invitationsService/useInvitations';
import useResendInvitation from 'api/platformApi/services/resendInvitationService/useResendInvitation';
import StatusChip from 'components/StatusChip/StatusChip';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { formatDate } from 'date-fns';
import { FC, useState } from 'react';
import { formatRelativeDate } from 'utils/dates';
import InvitationOptions from '../InvitationOptions/InvitationOptions';
import { InvitationRowProps } from './InvitationRow.types';

const InvitationRow: FC<InvitationRowProps> = ({ invitation, onClickEdit, onClickRevoke }) => {
  const [isRefreshingRow, setIsRefreshingRow] = useState(false);

  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();

  const refreshRowData = async () => {
    setIsRefreshingRow(true);
    await queryClient.invalidateQueries({
      queryKey: getInvitationsQueryKey(),
    });
    setIsRefreshingRow(false);
  };

  const resendInvitationMutation = useResendInvitation(invitation.id, {
    onSuccess: () => {
      snackbar.success('Invitation has been sent');
    },
    onError: () => {
      snackbar.error('The invitation was not successfully sent. Please try again.');
    },
  });

  const isInvitationLoading = resendInvitationMutation.isPending;
  const isExpiredOrRevoked =
    invitation.status === IInvitationStatus.REVOKED ||
    invitation.status === IInvitationStatus.EXPIRED;
  const isDisabled = isInvitationLoading || isRefreshingRow || isExpiredOrRevoked;

  return (
    <>
      <MFTableBodyRow
        key={invitation.id}
        sx={{
          'pointer-events': isDisabled ? 'none' : 'auto',
          opacity: isDisabled ? 0.5 : 1,
        }}
      >
        <TableCell>
          <Typography variant="bodyMedium">{invitation.email}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="bodyMedium">{invitation.workspacesString}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="bodyMedium">
            {invitation.dateSent ? formatRelativeDate(invitation.dateSent) : '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="bodyMedium">
            {invitation.expiresAt ? formatDate(invitation.expiresAt, 'MMMM d, yyyy') : '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <StatusChip status={invitation.status} />
        </TableCell>
        <TableCell>
          {invitation.status === IInvitationStatus.PENDING && (
            <InvitationOptions
              onEditInvitation={onClickEdit}
              onResendInvitation={async () => {
                resendInvitationMutation.mutate();
                await refreshRowData();
              }}
              onRevokeInvitation={onClickRevoke}
            />
          )}
        </TableCell>
      </MFTableBodyRow>
    </>
  );
};

export default InvitationRow;
