import {
  skipToken,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { snakeCase } from 'lodash-es';
import {
  ICreateWorkspaceProjectParamsPath,
  ICreateWorkspaceProjectRequestBody,
  ICreateWorkspaceProjectResponse,
  IUpdateWorkspaceProjectServiceParamsPath,
  IUpdateWorkspaceProjectServiceRequestBody,
  IUpdateWorkspaceProjectServiceResponse,
  IWorkspaceProjectServiceParamsPath,
  IWorkspaceProjectsParamsPath,
  IWorkspaceProjectsParamsQuery,
} from '../../platformApi.types';
import { CreateProjectOperationError, UpdateProjectOperationError } from './errors';
import {
  createWorkspaceProject,
  getWorkspaceProject,
  getWorkspaceProjects,
  updateWorkspaceProject,
} from './services';

export const WorkspaceProjectsServiceKeys = {
  root: ['workspaceProjects'] as const,
  lists() {
    return [...this.root, 'list'] as const;
  },
  list(params: Partial<IWorkspaceProjectsParamsPath>, filters?: UseWorkspaceProjectsFilters) {
    return [...this.lists(), params, filters] as const;
  },
  details() {
    return [...this.root, 'detail'] as const;
  },
  detail(params: Partial<IWorkspaceProjectServiceParamsPath>) {
    return [...this.details(), params] as const;
  },
  create() {
    return [...this.root, 'create'] as const;
  },
  delete() {
    return [...this.root, 'delete'] as const;
  },
  update() {
    return [...this.root, 'update'] as const;
  },
};

export interface UseWorkspaceProjectsFilters {
  searchValue?: string;
  sortBy?: 'name' | 'createdAt';
  sortDirection?: 'asc' | 'desc';
}

export type UseWorkspaceProjectsParams = Partial<IWorkspaceProjectsParamsPath> &
  Pick<IWorkspaceProjectsParamsQuery, 'limit'> &
  UseWorkspaceProjectsFilters;

export function useWorkspaceProjects(params: UseWorkspaceProjectsParams) {
  const { workspaceId } = params;

  return useInfiniteQuery({
    queryKey: WorkspaceProjectsServiceKeys.list(
      {
        workspaceId,
      },
      {
        searchValue: params.searchValue,
        sortBy: params.sortBy,
        sortDirection: params.sortDirection,
      },
    ),
    queryFn: workspaceId
      ? ({ pageParam }) =>
          getWorkspaceProjects({
            params: {
              path: {
                workspaceId,
              },
              query: {
                ...transformParamsToServiceQuery(params),
                nextCursor: pageParam.length > 0 ? pageParam : undefined,
              },
            },
          })
      : skipToken,
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
}

/**
 * API query fields and thus service query fields are using snake case.
 * To comply with our camel case convention, we need to transform the query fields to snake case.
 * Objects returned from the API also use camelCase fields, so we need to transform them to snake case.
 */
function transformParamsToServiceQuery(
  params: UseWorkspaceProjectsParams,
): IWorkspaceProjectsParamsQuery {
  const name = (params.searchValue ?? '').trim().length > 0 ? params.searchValue : undefined;
  const limit = params.limit ?? undefined;
  const direction = params.sortDirection
    ? (params.sortDirection.toUpperCase() as Uppercase<
        NonNullable<UseWorkspaceProjectsParams['sortDirection']>
      >)
    : undefined;
  const sortProperty = params.sortBy
    ? (snakeCase(params.sortBy) as SnakeCase<NonNullable<UseWorkspaceProjectsParams['sortBy']>>)
    : undefined;

  return {
    direction,
    limit,
    name,
    sortProperty,
  };
}

export type UseWorkspaceProjectParams = Partial<IWorkspaceProjectServiceParamsPath>;

export const useWorkspaceProject = (params: UseWorkspaceProjectParams) => {
  const { projectId, workspaceId } = params;

  return useQuery({
    queryKey: WorkspaceProjectsServiceKeys.detail(params),
    queryFn:
      projectId && workspaceId
        ? () => getWorkspaceProject({ params: { path: { workspaceId, projectId } } })
        : skipToken,
  });
};

type UseCreateWorkspaceProjectOptions = UseMutationOptions<
  ICreateWorkspaceProjectResponse,
  CreateProjectOperationError,
  ICreateWorkspaceProjectParamsPath & ICreateWorkspaceProjectRequestBody
>;

export function useCreateWorkspaceProject(options?: UseCreateWorkspaceProjectOptions) {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: WorkspaceProjectsServiceKeys.create(),
    mutationFn(variables) {
      return createWorkspaceProject({
        params: { path: { workspaceId: variables.workspaceId } },
        body: variables,
      });
    },
    onSuccess(...args) {
      void queryClient.invalidateQueries({
        queryKey: WorkspaceProjectsServiceKeys.lists(),
      });
      options?.onSuccess?.(...args);
    },
  });
}

type UseUpdateWorkspaceProjectOptions = UseMutationOptions<
  IUpdateWorkspaceProjectServiceResponse,
  UpdateProjectOperationError,
  IUpdateWorkspaceProjectServiceParamsPath & IUpdateWorkspaceProjectServiceRequestBody
>;

export function useUpdateWorkspaceProject(options?: UseUpdateWorkspaceProjectOptions) {
  return useMutation({
    ...options,
    mutationKey: WorkspaceProjectsServiceKeys.update(),
    mutationFn(variables) {
      const { projectId, workspaceId, ...body } = variables;

      return updateWorkspaceProject({
        body,
        params: {
          path: {
            projectId,
            workspaceId,
          },
        },
      });
    },
  });
}
