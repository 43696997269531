import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import * as Styled from './VisibilityAvatar.styles';
import { VisibilityAvatarProps } from './VisibilityAvatar.types';

const VisibilityAvatar = ({ visibility }: VisibilityAvatarProps) => {
  return (
    <Styled.Avatar variant="circular">
      {visibility === 'private' ? (
        <LockOutlinedIcon fontSize="small" color="primary" />
      ) : (
        <GroupsOutlinedIcon fontSize="small" />
      )}
    </Styled.Avatar>
  );
};

export default VisibilityAvatar;
