import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { IconButton } from '@mui/material';
import styled, { css, RuleSet } from 'styled-components';

export const DashboardVideoContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: ${({ theme }) => theme.spacing(22)};
  padding: ${({ theme }) => theme.spacing(3, 4)};
  position: relative;
  width: 100%;
`;

export type Gradient = 'alpha' | 'beta' | 'gamma' | 'delta';

const GRADIENT_COLORS: Record<Gradient, RuleSet> = {
  alpha: css`
    --from-color: #ebcee1;
    --to-color: #e4c29b;
  `,
  beta: css`
    --from-color: #d0cae5;
    --to-color: #f3ccdf;
  `,
  gamma: css`
    --from-color: #a18ddf;
    --to-color: #cfc8e6;
  `,
  delta: css`
    --from-color: #e3d9ea;
    --to-color: #a18de8;
  `,
};

export const DashboardVideoBackground = styled.div<{ $gradient: Gradient }>`
  ${({ $gradient }) => GRADIENT_COLORS[$gradient]};

  backdrop-filter: blur(30px);
  background: linear-gradient(253deg, var(--from-color) 1.11%, var(--to-color) 98.89%);
  box-shadow: ${({ theme }) => theme.monksShadows.level3};

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const DashboardVideoPlayButton = styled(IconButton).attrs({
  children: <PlayCircleIcon sx={{ fontSize: 48 }} />,
})`
  color: ${({ theme }) => theme.palette.surface.glass3};
  opacity: 0.5;

  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
`;

export const DashboardVideoPlayOverlay = styled.a.attrs({
  target: '_blank',
})`
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: grid;
  place-items: center;

  &:hover ${DashboardVideoPlayButton} {
    opacity: 1;
  }
`;
