import { useQuery } from '@tanstack/react-query';
import { getPermissions } from './permissionsService';

export const PERMISSIONS_QUERY_KEY_PREFIX = 'permissions';

export const getPermissionsQueryKey = () => [PERMISSIONS_QUERY_KEY_PREFIX];

function usePermissions() {
  return useQuery({
    queryKey: getPermissionsQueryKey(),
    queryFn: getPermissions,
  });
}

export default usePermissions;
