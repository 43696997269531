import platformApi from 'api/platformApi/platformApi';
import {
  IOrganizationAdminsServiceParamsPath,
  IOrganizationAdminsServiceResponse,
} from 'api/platformApi/platformApi.types';

export const getOrganizationAdmins = async ({
  params: {
    path: { organizationId },
  },
}: ServiceFnConfig<IOrganizationAdminsServiceParamsPath>) => {
  const axiosResponse = await platformApi.get<IOrganizationAdminsServiceResponse>(
    `/organizations/${organizationId}/admins`,
  );
  const apiResponse = axiosResponse.data;
  const apiData = apiResponse.data;

  return apiData;
};
