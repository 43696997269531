import platformApi from 'api/platformApi/platformApi';
import {
  IUpdateWorkspaceSolutionsServiceParamsPath,
  IUpdateWorkspaceSolutionsServiceRequestBody,
  IUpdateWorkspaceSolutionsServiceResponse,
} from 'api/platformApi/platformApi.types';

export const updateWorkspaceSolutionsService = async ({
  params: {
    path: { workspaceId },
  },
  body,
}: ServiceFnConfig<
  IUpdateWorkspaceSolutionsServiceParamsPath,
  undefined,
  IUpdateWorkspaceSolutionsServiceRequestBody
>) => {
  const response = await platformApi.put<IUpdateWorkspaceSolutionsServiceResponse>(
    `/workspaces/${workspaceId}/solutions`,
    body,
  );

  return response.data;
};
