import { Typography } from '@mui/material';
import styled from 'styled-components';
import { BOOKMARK_HORIZONTAL_GAP } from './Bookmarks.consts';

export const Container = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(2, 3)};
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(5)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  background: #fff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.02);
`;

export const BookmarksContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${BOOKMARK_HORIZONTAL_GAP}px;
  overflow: hidden;
`;

export const EmptyState = styled(Typography)`
  padding: 0 6px;
`;

export const DropIndicator = styled.div`
  width: 3px;
  height: 20px;
  border-radius: 1px;
  background-color: ${({ theme }) => theme.palette.action.active};
`;
