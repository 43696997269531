import platformApi from 'api/platformApi/platformApi';
import { IPermissionsServiceResponse } from 'api/platformApi/platformApi.types';

export const getPermissions = async () => {
  const networkResponse = await platformApi.get<IPermissionsServiceResponse>('/permissions');
  const apiResponse = networkResponse.data;
  const apiData = apiResponse.data;

  return apiData;
};
