import AdminHeader from 'components/AdminHeader/AdminHeader';
import AdminSideNav from 'components/AdminSideNav/AdminSideNav';
import useAuthContext from 'contexts/auth/useAuthContext';
import useResetScrollPosition from 'hooks/useResetScrollPosition';
import { Outlet } from 'react-router-dom';
import { ADMIN_SETTINGS_ACTION_LABEL, getUserMenuActions } from 'utils/userMenuActions';
import * as Styled from './AdminLayout.styles';

const AdminRoute = () => {
  const { user, logout } = useAuthContext();
  const scrollableContainerRef = useResetScrollPosition();
  const userActions = getUserMenuActions(user);
  const adminUserActions = userActions.filter(
    (action) => action.label !== ADMIN_SETTINGS_ACTION_LABEL,
  );

  return (
    <main>
      <AdminHeader
        title="admin"
        user={user}
        onSignOutClick={logout}
        userActions={adminUserActions}
      />

      <Styled.Container>
        <AdminSideNav />

        <Styled.Content ref={scrollableContainerRef}>
          <Outlet />
        </Styled.Content>
      </Styled.Container>
    </main>
  );
};

export default AdminRoute;
