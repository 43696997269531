import {
  ListItem as MonksListItem,
  ListItemIcon as MonksListItemIcon,
  ListItemText as MonksListItemText,
  Box,
} from '@mui/material';
import { NavLink as RRNavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled(Box)`
  width: 320px;
  background: ${({ theme }) => theme.palette.surface.level1};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.02);
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

export const Link = styled(RRNavLink)`
  text-decoration: none;
`;

export const ListItem = styled(MonksListItem)<{ $selected: boolean }>`
  ${({ theme, $selected }) => css`
    color: ${$selected ? theme.palette.primary.main : theme.palette.text.secondary};
    background-color: ${$selected ? theme.palette.primary.selected : 'none'};
    border-radius: 12px;

    &:hover {
      background: ${theme.palette.primary.hover};
    }
  `};
`;

export const ListItemIcon = styled(MonksListItemIcon)<{ $selected: boolean }>`
  color: ${({ theme, $selected }) =>
    $selected ? theme.palette.primary.main : theme.palette.action.active};

  min-width: auto;
`;

export const ListItemText = styled(MonksListItemText)`
  margin-left: 12px;
`;
