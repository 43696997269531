import { IconButton as MuiIconButton, Menu } from '@mui/material';
import styled from 'styled-components';

export const OptionsButton = styled(MuiIconButton)`
  border: 1px solid ${({ theme }) => theme.palette.action.active};
`;
export const OptionsMenu = styled(Menu)`
  margin-top: 4px;

  & .MuiPaper-root {
    border-radius: 8px;
  }
`;
