import { AuthenticatedUserResponseDto } from '@monksflow/platform-api-sdk';
import { PERMISSIONS, validatePermissions } from './permissions';
import { routes } from './routes';

export const ADMIN_SETTINGS_ACTION_LABEL = 'Admin settings';
export const INSTANCE_ADMIN_SETTINGS_ACTION_LABEL = 'Organization management';

export const getUserMenuActions = (user: Maybe<AuthenticatedUserResponseDto>) => {
  const canAccessAdminSettings = validatePermissions(
    [[PERMISSIONS.PlatformManageUsers], [PERMISSIONS.PlatformManageWorkspaces]],
    user?.permissions,
  );

  const canAccessOrganizationManagement = validatePermissions(
    [PERMISSIONS.PlatformInstanceManage],
    user?.permissions,
  );

  const userActions = [];

  if (canAccessAdminSettings) {
    userActions.push({
      label: ADMIN_SETTINGS_ACTION_LABEL,
      action: () => {
        window.open(routes.admin(), '_blank');
      },
    });
  }

  if (canAccessOrganizationManagement) {
    userActions.push({
      label: INSTANCE_ADMIN_SETTINGS_ACTION_LABEL,
      action: () => {
        window.open(routes.organizationManagement(), '_blank');
      },
    });
  }

  return userActions;
};
