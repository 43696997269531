import { COLORS } from '@monksflow/monks-ui';
import styled from 'styled-components';

export const ADMIN_HEADER_HEIGHT = 56;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  height: ${ADMIN_HEADER_HEIGHT}px;
  background-color: ${COLORS.tan.tinted};
  color: ${COLORS.grey[400]};
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Separator = styled.span`
  margin: 0px 16px;
`;

export const Title = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;
