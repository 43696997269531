import ErrorIcon from '@mui/icons-material/Error';
import { useQueryClient } from '@tanstack/react-query';
import {
  useCreateWorkspaceProject,
  WorkspaceProjectsServiceKeys,
} from 'api/platformApi/services/workspaceProjectsService/hooks';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import ProjectVisibilitySelect from 'components/ProjectVisibilitySelect/ProjectVisiblitySelect';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import {
  getMaxLengthValidation,
  requiredValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import { routes } from '../../utils/routes';
import * as Styled from './CreateProjectDrawer.styles';
import { CreateProjectDrawerProps, ICreateProjectForm } from './CreateProjectDrawer.types';

export const PROJECT_TITLE_MAX_LENGTH = 100;
export const PROJECT_DESCRIPTION_MAX_LENGTH = 2000;

const CreateProjectDrawer: FC<CreateProjectDrawerProps> = (props) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const snackbar = useSnackbarContext();
  const activeWorkspace = useActiveWorkspace();
  const workspaceId = activeWorkspace?.id ?? '';
  const workspaceSlug = activeWorkspace?.slug ?? '';
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm<ICreateProjectForm>({
    mode: 'all',
    defaultValues: {
      name: '',
      description: '',
      visibility: 'private',
    },
  });

  const createWorkspaceProject = useCreateWorkspaceProject({
    onError(error) {
      if (error.scope === 'field' && error.field) {
        setError(error.field, { message: error.message });
      }
    },
    onSuccess(createWorkspaceProjectResponse) {
      void queryClient.invalidateQueries({
        queryKey: WorkspaceProjectsServiceKeys.lists(),
      });
      reset();
      onClose();
      snackbar.success('New project created');

      navigate(routes.viewProject(workspaceSlug, createWorkspaceProjectResponse.id));
    },
  });

  const onCreateProjectSubmit: SubmitHandler<ICreateProjectForm> = async (data) => {
    await createWorkspaceProject.mutateAsync({ workspaceId, ...data });
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <AppDrawer open={open} onClose={onClose} leftSidebar={true}>
      <Styled.Form onSubmit={handleSubmit(onCreateProjectSubmit)}>
        <AppDrawerHeader title="Create New Project" onClose={onClose} />

        <AppDrawerContent>
          {createWorkspaceProject.isError && createWorkspaceProject.error.scope === 'alert' && (
            <Styled.MuiAlert severity="error" icon={<ErrorIcon />}>
              {createWorkspaceProject.error.message}
            </Styled.MuiAlert>
          )}

          <RHFMonkTextField
            name="name"
            control={control}
            rules={{ ...requiredValidation, ...getMaxLengthValidation(PROJECT_TITLE_MAX_LENGTH) }}
            label="Project title"
            placeholder="Add a title..."
            disabled={isSubmitting}
            required
            fullWidth
            sx={{ marginTop: '24px' }}
          />

          <RHFMonkTextField
            name="description"
            control={control}
            rules={{
              ...getMaxLengthValidation(PROJECT_DESCRIPTION_MAX_LENGTH),
            }}
            label="Description"
            placeholder="Describe the purpose of this project..."
            disabled={isSubmitting}
            fullWidth
            multiline
            rows={5}
            sx={{ marginTop: '24px' }}
          />

          <ProjectVisibilitySelect<ICreateProjectForm>
            name="visibility"
            control={control}
            disabled={isSubmitting}
          />
        </AppDrawerContent>

        <Styled.CustomAppDrawerFooter>
          <Styled.CreateProjectButton
            type="submit"
            disabled={isSubmitDisabled}
            variant="contained"
            size="large"
            color="primary"
          >
            {isSubmitting ? <Styled.MuiCircularProgress size={16} /> : `Create Project`}
          </Styled.CreateProjectButton>
        </Styled.CustomAppDrawerFooter>
      </Styled.Form>
    </AppDrawer>
  );
};

export default CreateProjectDrawer;
