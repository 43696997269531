import platformApi from 'api/platformApi/platformApi';
import {
  IOrganizationAuthMethodResponse,
  IOrganizationAuthMethodServiceParamsPath,
} from 'api/platformApi/platformApi.types';

export const organizationAuthMethodService = async ({
  params: {
    path: { organizationId, methodId },
  },
}: ServiceFnConfig<IOrganizationAuthMethodServiceParamsPath>) => {
  const response = await platformApi.get<IOrganizationAuthMethodResponse>(
    `/organizations/${organizationId}/sso-methods/${methodId}`,
  );

  return response.data;
};
