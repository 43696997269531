import { useCallback, useEffect, useState } from 'react';

type PreferencesScope = 'global' | 'organization' | 'workspace';

// workaround for using a async storage
const BUFFER_KEY = '__buffer__';
const buildStorageKey = (scope: PreferencesScope, key: string) => `${scope}:${key}:preferences`;

/* eslint-disable-next-line @typescript-eslint/no-empty-function */
const noop = () => {};

const storage =
  typeof window !== 'undefined'
    ? window.localStorage
    : {
        getItem: noop,
        setItem: noop,
        removeItem: noop,
      };

/**
 * Custom hook for managing scoped preferences with persistence to the local storage.
 *
 * @param {PreferencesScope} scope - The scope of the preferences ('global', 'organization', or 'workspace').
 * @param {string | undefined} key - The unique key for the preferences. Undefined keys will default to a buffer key that will be cleared after the key gets defined.
 *                                   Useful for when the key is not available at the time of the hook initialization due to the async nature of data loading.
 * @param {Preferences} initial - The initial preferences object.
 *
 * @returns An object with the following properties:
 * @property {Preferences} preferences - The current `preferences` local state. Re-renders when the preferences change.
 * @property {Function} read - Reads a specific setting without subscribing to the `preferences` local state. Avoids unnecessary re-renders.
 * @property {Function} save - Updates `preferences` state and persists the changes to the local storage. Send `sync` as `false` to avoid re-rendering.
 */
export const usePreferences = <Preferences extends object>(
  scope: PreferencesScope,
  key: string = BUFFER_KEY,
  initial: Preferences,
) => {
  const storageKey = buildStorageKey(scope, key);
  const [preferences, setPreferences] = useState(() =>
    usePreferences.getStorage(scope, key, initial),
  );

  // reads state without subscribing to re-renders
  const read = useCallback(
    <Key extends keyof Preferences>(key: Key): Preferences[Key] | null => {
      const state = retrieve(storageKey, initial);
      return state[key];
    },
    [storageKey],
  );

  const save = useCallback(
    (unsaved: Partial<Preferences>, sync = true) => {
      const state = retrieve(storageKey, initial);
      const updated = { ...state, ...unsaved };
      storage.setItem(storageKey, JSON.stringify(updated));
      if (sync) setPreferences(updated);
    },
    [storageKey],
  );

  useEffect(() => {
    if (key === BUFFER_KEY) return;

    const bufferKey = buildStorageKey(scope, BUFFER_KEY);

    // if there's no value saved in the key utilizes the buffer as initial
    const buffer = retrieve(bufferKey, initial);
    const stored = retrieve(storageKey, buffer);

    // updates local storage after key updates
    save(stored, true);
    storage.removeItem(bufferKey);
  }, [key, storageKey]);

  return { preferences, read, save };
};

usePreferences.getStorage = <Preferences extends object>(
  scope: PreferencesScope,
  key: string = BUFFER_KEY,
  initial: Preferences,
) => {
  const bufferKey = buildStorageKey(scope, BUFFER_KEY);
  const storageKey = buildStorageKey(scope, key);

  const buffer = retrieve(bufferKey, initial);
  const stored = retrieve(storageKey, buffer);

  return stored;
};

function retrieve<Preferences extends object>(storageKey: string, initial: Preferences) {
  const stored = storage.getItem(storageKey);
  if (stored) return JSON.parse(stored) as Preferences;
  return initial;
}
