import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  ICreateOrganizationAdminServiceParamsPath,
  ICreateOrganizationAdminServiceRequestBody,
  ICreateOrganizationAdminServiceResponse,
} from 'api/platformApi/platformApi.types';
import {
  CreateOrganizationAdminOperationError,
  createOrganizationAdminService,
} from './createOrganizationAdminService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseCreateOrganizationAdminParams extends ICreateOrganizationAdminServiceParamsPath {}

const useCreateOrganizationAdmin = (
  params: UseCreateOrganizationAdminParams,
  mutationOptions?: UseMutationOptions<
    ICreateOrganizationAdminServiceResponse,
    CreateOrganizationAdminOperationError,
    ICreateOrganizationAdminServiceRequestBody
  >,
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: ['createOrganizationAdmin'],
    mutationFn: (adminData: ICreateOrganizationAdminServiceRequestBody) => {
      return createOrganizationAdminService({
        params: {
          path: {
            organizationId: params.organizationId,
          },
        },
        body: adminData,
      });
    },
  });
};

export default useCreateOrganizationAdmin;
