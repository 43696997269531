import { useQuery } from '@tanstack/react-query';
import { getOrganization } from './organizationService';

export const ORGANIZATION_QUERY_KEY_PREFIX = 'organization';

export const getOrganizationQueryKey = (organizationId: string) => [
  ORGANIZATION_QUERY_KEY_PREFIX,
  { organizationId },
];

const useOrganization = (organizationId: string) => {
  return useQuery({
    queryKey: getOrganizationQueryKey(organizationId),
    queryFn: () => getOrganization(organizationId).then((r) => r.data),
  });
};

export default useOrganization;
