import { Avatar as MuiAvatar, Typography } from '@mui/material';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import styled from 'styled-components';

export const CustomMFTableHeadCell = styled(MFTableHeadCell)`
  width: 25%;
`;

export const FileNameContent = styled.div`
  display: flex;
`;

export const AppLogo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 8px;
`;

export const FileName = styled(Typography)`
  margin-left: 12px;
`;

export const CreatedByContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled(MuiAvatar)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.palette.primary.dark};
  background-color: ${({ theme }) => theme.palette.primary.light};
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 166%;
  letter-spacing: 0.4px;
`;

export const UserName = styled(Typography)`
  margin-left: 12px;
`;
