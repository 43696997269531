import { createElement, PropsWithChildren } from 'react';

const StopEventPropagation = ({
  children,
  eventTypes = ['onClick'],
}: PropsWithChildren<{ eventTypes: ('onClick' | 'onChange')[] }>) => {
  function stopPropagation(event: Event) {
    event.stopPropagation();
  }

  const handlers = eventTypes.reduce<Record<string, typeof stopPropagation>>(
    (handlers, eventType) => {
      handlers[eventType] = stopPropagation;
      return handlers;
    },
    {},
  );

  return createElement('div', handlers, children);
};

export default StopEventPropagation;
