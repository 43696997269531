import { AddOutlined } from '@mui/icons-material';
import { Button, Table, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { IOrganizationAdminInvitation } from 'api/platformApi/platformApi.types';
import useOrganizationAdmins, {
  getOrganizationAdminsQueryKey,
} from 'api/platformApi/services/organizationAdminsService/useOrganizationAdmins';
import useResendOrganizationAdminInvitation from 'api/platformApi/services/resendOrganizationAdminInvitationService/useResendOrganizationAdminInvitation';
import { useRevokeOrganizationInvitation } from 'api/platformApi/services/revokeInvitationService/useRevokeInvitation';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import { ITableHeadCell } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { useReducer } from 'react';
import AddOrganizationAdminDrawer from '../AddOrganizationAdminDrawer/AddOrganizationAdminDrawer';
import * as Styled from './OrganizationAdmins.styles';
import { OrganizationAdminsProps } from './OrganizationAdmins.types';
import OrgAdminInvitationRow from './components/OrgAdminInvitationRow/OrgAdminInvitationRow';

const headCells: ITableHeadCell<keyof IOrganizationAdminInvitation>[] = [
  { id: 'email', label: 'Email address' },
  { id: 'dateSent', label: 'Last sent date' },
  { id: 'expiresAt', label: 'Expires on' },
  { id: 'status', label: 'Status' },
];

const OrganizationAdmins: React.FC<OrganizationAdminsProps> = ({ organizationId }) => {
  const { data: admins = [] } = useOrganizationAdmins({ organizationId });
  const [isAddOrgAdminDrawerOpen, toggleAddOrgAdminDrawer] = useReducer((state) => !state, false);

  const snackbar = useSnackbarContext();
  const queryClient = useQueryClient();

  const resendInvitationMutation = useResendOrganizationAdminInvitation(
    { organizationId },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getOrganizationAdminsQueryKey({ organizationId }),
        });

        snackbar.success('Invitation has been sent');
      },
      onError: () => {
        snackbar.error('The invitation was not successfully sent. Please try again.');
      },
    },
  );

  const revokeOrgInvitationMutation = useRevokeOrganizationInvitation(
    { organizationId },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getOrganizationAdminsQueryKey({ organizationId }),
        });

        snackbar.success('Invitation has been revoked');
      },
      onError: () => {
        snackbar.error('The invitation was not successfully revoked. Please try again.');
      },
    },
  );

  return (
    <>
      <Styled.CustomMFTableContainer>
        <Styled.TitleHeader>
          <Typography component="h2" variant="titleMedium">
            Invited administrators
          </Typography>

          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={toggleAddOrgAdminDrawer}
            startIcon={<AddOutlined />}
          >
            Add admin
          </Button>
        </Styled.TitleHeader>

        <Table>
          <MFTableHead>
            <MFTableHeadRow>
              {headCells.map((headCell) => (
                <MFTableHeadCell
                  variant="head"
                  key={headCell.id}
                  sx={{ width: `${100 / headCells.length}%` }}
                >
                  {headCell.label}
                </MFTableHeadCell>
              ))}
              <MFTableHeadCell variant="head" />
            </MFTableHeadRow>
          </MFTableHead>

          <MFTableBody>
            {admins.map((admin) => {
              const invitationId = admin.id;

              return (
                <OrgAdminInvitationRow
                  key={admin.email}
                  admin={admin}
                  onClickResend={async () => {
                    await resendInvitationMutation.mutateAsync({ invitationId });
                  }}
                  onClickRevoke={async () => {
                    await revokeOrgInvitationMutation.mutateAsync({ invitationId });
                  }}
                />
              );
            })}
          </MFTableBody>
        </Table>
      </Styled.CustomMFTableContainer>

      <AddOrganizationAdminDrawer
        open={isAddOrgAdminDrawerOpen}
        onClose={toggleAddOrgAdminDrawer}
      />
    </>
  );
};

export default OrganizationAdmins;
