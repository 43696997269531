import usePermissions from 'api/platformApi/services/permissionsService/usePermissions';
import CheckboxField from 'components/ui/CheckboxField/CheckboxField';
import { useEffect } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

const RHFPermissionsField = <FormFields extends FieldValues>({
  checked,
  control,
  disabled,
  name,
}: UseControllerProps<FormFields> & { checked?: boolean }) => {
  const { data: permissions = [] } = usePermissions();
  const { field } = useController({
    control,
    name,
    disabled,
  });

  const { onChange } = field;
  useEffect(() => {
    if (!checked) return;

    const fields = permissions.reduce<Record<string, boolean>>((acc, permission) => {
      acc[permission.key] = true;
      return acc;
    }, {});

    // Check all permissions when `checked` prop is true
    onChange(fields);
  }, [checked, onChange, permissions]);

  return permissions.map((permission) => (
    <CheckboxField
      checked={!!field.value[permission.key]}
      disabled={field.disabled}
      helperText={permission.description}
      id={permission.key}
      key={permission.key}
      label={permission.name}
      onBlur={field.onBlur}
      onChange={(event) => {
        field.onChange({ ...field.value, [event.target.value]: event.target.checked });
      }}
      ref={field.ref}
      value={permission.key}
    />
  ));
};

export default RHFPermissionsField;
