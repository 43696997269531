import { FC } from 'react';
import ProjectCard from '../../ProjectCard/ProjectCard';
import { ProjectsListLoaderProps } from './ProjectsListLoader.types';

const ProjectsListLoader: FC<ProjectsListLoaderProps> = (props) => {
  return [...Array(props.projects).keys()].map((project) => (
    <ProjectCard name={''} owner={''} visibility="private" isLoading={true} key={project} />
  ));
};

export default ProjectsListLoader;
