import { useWorkspaceProjects } from 'api/platformApi/services/workspaceProjectsService/hooks';
import useInfiniteScrollRef from 'hooks/useInfiniteScrollRef';
import { FC, useMemo } from 'react';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import PageFeedback from '../../../../../../components/ui/PageFeedback/PageFeedback';
import ProjectsList from '../ProjectsList/ProjectsList';
import { ProjectsTable } from '../ProjectsTable/ProjectsTable';
import { ProjectsTableRow } from '../ProjectsTable/ProjectsTableRow';
import { ProjectsTableRowSkeleton } from '../ProjectsTable/ProjectsTableRowSkeleton';
import { ProjectsViewProps } from './ProjectsView.types';

const WORKSPACE_PROJECTS_PAGE_SIZE = 20;

const ProjectsView: FC<ProjectsViewProps> = ({
  onSort,
  searchValue,
  sortBy = 'name',
  sortDirection = 'asc',
  viewMode = 'list',
}) => {
  const activeWorkspace = useActiveWorkspace();
  const activeWorkspaceId = activeWorkspace?.id;
  const activeWorkspaceSlug = activeWorkspace?.slug;

  const { data, isLoading, isError, hasNextPage, isFetching, isFetchingNextPage, fetchNextPage } =
    useWorkspaceProjects({
      workspaceId: activeWorkspaceId,
      searchValue,
      sortBy,
      sortDirection,
      limit: WORKSPACE_PROJECTS_PAGE_SIZE,
    });

  const workspaceProjects = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data]);

  const infiniteScrollRef = useInfiniteScrollRef({
    isLoading,
    hasNextPage,
    isFetching,
    fetchNextPage,
  });

  // finished loading and data is populated
  if (data) {
    if (workspaceProjects.length === 0) {
      return (
        <PageFeedback
          title="No projects found"
          description="Projects can be used to organize work for a campaign, work request, or other groups of work that requires combining many files."
        />
      );
    }

    if (viewMode === 'table') {
      return (
        <ProjectsTable onSort={onSort} sortBy={sortBy} sortDirection={sortDirection}>
          {workspaceProjects.map((project) => (
            <ProjectsTableRow
              key={project.id}
              project={project}
              workspaceSlug={activeWorkspaceSlug ?? ''}
              ref={infiniteScrollRef}
            />
          ))}

          {isFetchingNextPage &&
            Array.from({ length: WORKSPACE_PROJECTS_PAGE_SIZE - 5 }).map((_, index) => (
              <ProjectsTableRowSkeleton key={index} />
            ))}
        </ProjectsTable>
      );
    }

    return (
      <ProjectsList
        projects={workspaceProjects}
        isLoading={isLoading}
        isError={isError}
        isLoadingMore={isFetchingNextPage}
        lastProjectRef={infiniteScrollRef}
      />
    );
  }

  // error when loading
  if (isError) {
    return (
      <PageFeedback
        title="Projects failed to load"
        description="An error occurred and your projects could not be loaded. Please try refreshing the page to access your projects."
      />
    );
  }

  // still loading
  if (viewMode === 'table') {
    return (
      <ProjectsTable sortBy={sortBy} sortDirection={sortDirection}>
        {Array.from({ length: WORKSPACE_PROJECTS_PAGE_SIZE - 5 }).map((_, index) => (
          <ProjectsTableRowSkeleton key={index} />
        ))}
      </ProjectsTable>
    );
  }

  return (
    <ProjectsList projects={[]} isError={false} isLoading lastProjectRef={infiniteScrollRef} />
  );
};

export default ProjectsView;
