import styled from 'styled-components';

export const DashboardStatContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: ${({ theme }) => theme.spacing(22)};
  padding: ${({ theme }) => theme.spacing(3, 4)};
  position: relative;
  width: 100%;
`;

export const DashboardStatBackground = styled.div`
  backdrop-filter: blur(30px);
  background-color: ${({ theme }) => theme.palette.primary.focus};
  box-shadow: ${({ theme }) => theme.monksShadows.level3};

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;
