import { IOrganization } from 'api/platformApi/platformApi.types';
import { ITableOrderDirection } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import { generateStringFromArray } from 'utils/arraryString';
import {
  IOrganizationOrderBy,
  IOrganizationWithAppsAdminsStrings,
} from './OrganizationManagementPage.types';

function orderOrganizationsByString(organizationProp: string, otherOrganizationProp: string) {
  const organizationPropLowerCase = organizationProp.toLowerCase();
  const otherOrganizationPropLowerCase = otherOrganizationProp.toLowerCase();
  return organizationPropLowerCase.localeCompare(otherOrganizationPropLowerCase, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

export function getOrganizationsComparator(
  orderDirection: ITableOrderDirection,
  orderBy: IOrganizationOrderBy,
) {
  return function organizationsComparator(
    organization: IOrganization,
    otherOrganization: IOrganization,
  ) {
    const orderResult = orderOrganizationsByString(
      organization[orderBy],
      otherOrganization[orderBy],
    );
    const orderSign = orderDirection === 'asc' ? 1 : -1;

    return orderResult * orderSign;
  };
}

export function mapOrganizationToOrgWithAppsAdminsString(
  organization: IOrganization,
): IOrganizationWithAppsAdminsStrings {
  const appsString = generateStringFromArray(organization.apps, 2, (app) => app.name);
  const adminsString = generateStringFromArray(organization.admins, 1, (admin) => admin.email);
  return {
    ...organization,
    appsString,
    adminsString,
  };
}
