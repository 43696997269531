import platformApi from 'api/platformApi/platformApi';
import {
  IPlatformApiGenericError,
  IUpdateOrganizationRequestBody,
  IUpdateOrganizationResponse,
} from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  ErrorSchema,
  InterfaceOperationError,
  isOrganizationNameExistsError,
} from 'utils/httpErrors';
import { CONFLICT } from 'utils/httpStatuses';

export const updateOrganization = async (
  organizationId: string,
  organizationData: IUpdateOrganizationRequestBody,
  axiosConfig?: AxiosRequestConfig,
) => {
  try {
    const response = await platformApi.put<
      IUpdateOrganizationResponse,
      AxiosResponse<IUpdateOrganizationResponse>,
      IUpdateOrganizationRequestBody
    >(`/organizations/${organizationId}/`, organizationData, axiosConfig);

    return response.data;
  } catch (error) {
    const apiError = UpdateOrganizationOperationError.unwrapAPIError(error);
    if (apiError) throw new UpdateOrganizationOperationError(apiError);
    throw error;
  }
};

type UpdateOrganizationOperationErrorSchema = ErrorSchema<typeof CONFLICT>;

export class UpdateOrganizationOperationError extends InterfaceOperationError<
  IUpdateOrganizationRequestBody,
  UpdateOrganizationOperationErrorSchema
> {
  errorSchema: UpdateOrganizationOperationErrorSchema = {
    [CONFLICT]: (apiError) => {
      if (isOrganizationNameExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'An organization already exists with this name.';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError, 'An error occurred and the organization could not be updated.', 'snackbar');
    this.scopeAPIError();
  }
}
