import ErrorPage from '../ErrorPage/ErrorPage';

const ErrorAuthPage = () => {
  return (
    <ErrorPage
      title="Authentication Error"
      description="We are not able to authenticate your access to Monks.Flow at this time. 
        If this problem persists, please contact a Monks.Flow administrator to assist with your access."
    />
  );
};

export default ErrorAuthPage;
