import {
  ICreateWorkspaceProjectRequestBody,
  IPlatformApiGenericError,
  IUpdateWorkspaceProjectServiceRequestBody,
} from 'api/platformApi/platformApi.types';
import {
  ErrorSchema,
  InterfaceOperationError,
  isProjectNameEmptyError,
  isProjectNameExistsError,
} from 'utils/httpErrors';
import { BAD_REQUEST, CONFLICT, NOT_FOUND } from 'utils/httpStatuses';

type CreateProjectOperationErrorSchema = ErrorSchema<typeof BAD_REQUEST | typeof CONFLICT>;

export class CreateProjectOperationError extends InterfaceOperationError<
  ICreateWorkspaceProjectRequestBody,
  CreateProjectOperationErrorSchema
> {
  errorSchema: CreateProjectOperationErrorSchema = {
    [BAD_REQUEST]: (apiError) => {
      if (isProjectNameEmptyError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A project title is required.';
      }
    },
    [CONFLICT]: (apiError) => {
      if (isProjectNameExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A project already exists with this name.';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError);
    this.scopeAPIError();
  }
}

type UpdateProjectOperationErrorSchema = ErrorSchema<
  typeof BAD_REQUEST | typeof CONFLICT | typeof NOT_FOUND
>;

export class UpdateProjectOperationError extends InterfaceOperationError<
  IUpdateWorkspaceProjectServiceRequestBody,
  UpdateProjectOperationErrorSchema
> {
  errorSchema: UpdateProjectOperationErrorSchema = {
    [BAD_REQUEST]: (apiError) => {
      if (isProjectNameEmptyError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A project title is required.';
      }
    },
    [CONFLICT]: (apiError) => {
      if (isProjectNameExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A project already exists with this name.';
      }
    },
    [NOT_FOUND]: () => {
      this.scope = 'alert';
      this.message = 'Project not found while trying to update. Make sure that you have access.';
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError);
    this.scopeAPIError();
  }
}
