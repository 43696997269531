import { createContext } from 'react';
import { ISnackbarContext } from './SnackbarContext.types';

const DEFAULT_SNACKBAR_CONTEXT: ISnackbarContext = {
  isOpen: false,
  message: '',
  severity: undefined,
  open: () => {
    return;
  },
  info: () => {
    return;
  },
  warning: () => {
    return;
  },
  error: () => {
    return;
  },
  success: () => {
    return;
  },
  close: () => {
    return;
  },
};

const SnackbarContext = createContext(DEFAULT_SNACKBAR_CONTEXT);

export default SnackbarContext;
