import { useQuery } from '@tanstack/react-query';
import { getCountUsers } from './countUsersService';

export const COUNT_USERS_QUERY_KEY_PREFIX = 'usersCount';

export const getCountUsersQueryKey = () => {
  return [COUNT_USERS_QUERY_KEY_PREFIX];
};

interface PartialUseQueryOptions {
  enabled?: boolean;
}

function useCountUsers(queryOptions?: PartialUseQueryOptions) {
  return useQuery({
    ...queryOptions,
    queryKey: getCountUsersQueryKey(),
    queryFn: () => getCountUsers(),
  });
}

export default useCountUsers;
