import { Table } from '@mui/material';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import MFTableHeadCellSortable from 'components/ui/MFTableHeadCellSortable/MFTableHeadCellSortable';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import { PropsWithChildren } from 'react';
import { headCells } from './ProjectsTable.consts';
import { ProjectsTableProps } from './ProjectsTable.types';

export const ProjectsTable = ({
  children,
  onSort,
  sortBy = 'createdAt',
  sortDirection = 'asc',
}: PropsWithChildren<ProjectsTableProps>) => {
  return (
    <MFTableContainer>
      <Table>
        <MFTableHead>
          <MFTableHeadRow>
            {headCells.map((headCell) =>
              headCell.sortable ? (
                <MFTableHeadCellSortable
                  key={headCell.id}
                  active={headCell.id === sortBy}
                  direction={sortDirection}
                  onSort={() => {
                    onSort?.(headCell.id);
                  }}
                  sx={{ width: `${100 / headCells.length}%` }}
                >
                  {headCell.label}
                </MFTableHeadCellSortable>
              ) : (
                <MFTableHeadCell key={headCell.id} sx={{ width: `${100 / headCells.length}%` }}>
                  {headCell.label}
                </MFTableHeadCell>
              ),
            )}
          </MFTableHeadRow>
        </MFTableHead>

        <MFTableBody>{children}</MFTableBody>
      </Table>
    </MFTableContainer>
  );
};
