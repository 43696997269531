import { IInvitationStatus } from 'api/platformApi/platformApi.types';

const INVITATION_STATUS_MAP: Record<IInvitationStatus, { label: string }> = {
  pending: { label: 'Pending' },
  accepted: { label: 'Accepted' },
  expired: { label: 'Expired' },
  revoked: { label: 'Revoked' },
};

export const getInvitationStatusLabel = (status: IInvitationStatus): string =>
  INVITATION_STATUS_MAP[status].label || status;
