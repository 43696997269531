import loginHeaderBg from 'assets/img/login-header-bg.jpg';
import waveformBlurredBg from 'assets/img/waveform-blurred-bg.jpg';
import styled from 'styled-components';

export const Main = styled.main`
  background: ${({ theme }) => theme.palette.surface.level0} url(${waveformBlurredBg}) no-repeat
    center center / cover fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

export const LoginContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.spacing(59)};
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.surface.level1};
  box-shadow: -8px 6px 32px 0px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  overflow: hidden;
`;

export const LoginHeader = styled.div`
  background: ${({ theme }) => theme.palette.surface.level0} url(${loginHeaderBg}) no-repeat;
  width: 100%;
  text-align: center;
`;

export const LoginHeaderImg = styled.img`
  width: ${({ theme }) => theme.spacing(32)};
  padding: ${({ theme }) => theme.spacing(9, 0)};
`;

export const LoginBody = styled.div`
  background: ${({ theme }) => theme.palette.surface.level0};
  text-align: center;
  padding: ${({ theme }) => theme.spacing(4.5)};
`;

export const LoginMethods = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(4.5, 0, 2)};
`;
