type LabelExtractor<T> = (item: T) => string;

/**
 * Transforms an array into a formatted string showing a limited number of items.
 * @param arr - The array to process.
 * @param count - Number of items to show before adding the "more" suffix.
 * @param getLabel - Function to extract the label from each item.
 * @returns A formatted string.
 */
function generateStringFromArray<T>(arr: T[], count: number, getLabel: LabelExtractor<T>): string {
  if (!Array.isArray(arr) || typeof getLabel !== 'function' || typeof count !== 'number') {
    throw new Error('Invalid arguments');
  }

  const firstItems = arr.slice(0, count);
  let resultString = firstItems.map(getLabel).join(', ');

  if (arr.length > count) {
    resultString += ` & ${arr.length - count} more`;
  }

  return resultString;
}

export { generateStringFromArray };
