import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import * as Styled from './AppDrawerHeader.styles';
import { AppDrawerHeaderProps } from './AppDrawerHeader.types';

const AppDrawerHeader: FC<AppDrawerHeaderProps> = (props) => {
  return (
    <Styled.AppDrawerHeader className={props.className}>
      {props.title ? <Typography variant="titleLargeMd">{props.title}</Typography> : props.children}

      <Tooltip title="Close (Esc)" placement="left">
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </Styled.AppDrawerHeader>
  );
};

export default AppDrawerHeader;
