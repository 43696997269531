import { skipToken, useQuery } from '@tanstack/react-query';
import { IOrganizationAdminsServiceParamsPath } from 'api/platformApi/platformApi.types';
import { getOrganizationAdmins } from './organizationAdminsService';

export type UseOrganizationAdminsParams = Partial<IOrganizationAdminsServiceParamsPath>;

export const ORGANIZATION_ADMINS_QUERY_KEY_PREFIX = 'organizationAdmins';

export const getOrganizationAdminsQueryKey = (params: UseOrganizationAdminsParams) => [
  ORGANIZATION_ADMINS_QUERY_KEY_PREFIX,
  params.organizationId,
];

const useOrganizationAdmins = (params: UseOrganizationAdminsParams) => {
  const { organizationId } = params;

  return useQuery({
    queryKey: getOrganizationAdminsQueryKey(params),
    queryFn: organizationId
      ? () => getOrganizationAdmins({ params: { path: { organizationId } } })
      : skipToken,
  });
};

export default useOrganizationAdmins;
