import { Button as MuiButton, Menu as MuiMenu } from '@mui/material';
import styled from 'styled-components';
import { MORE_MENU_VERTICAL_PADDING } from '../../Bookmarks.consts';

export const Button = styled(MuiButton)`
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.56);

  &:hover {
    background: rgba(0, 0, 0, 0.12);
  }

  && .MuiTouchRipple-child {
    background-color: rgba(0, 0, 0, 0.56);
  }
`;

export const Menu = styled(MuiMenu)`
  margin-top: 4px;

  & .MuiPaper-root {
    border-radius: 8px;
  }

  & .MuiMenu-list {
    padding: ${MORE_MENU_VERTICAL_PADDING}px 0;
  }
` as typeof MuiMenu;

export const DropIndicator = styled.div`
  height: 3px;
  margin: ${({ theme }) => theme.spacing(0, 2)};
  border-radius: 1px;
  background-color: ${({ theme }) => theme.palette.action.active};
`;
