import { Link } from '@mui/material';
import { FC } from 'react';
import { ContactSupportLinkProps } from './ContactSupportLink.types';

const ContactSupportLink: FC<ContactSupportLinkProps> = (props) => (
  <Link
    href="mailto:support@monksflow.ai"
    target="_blank"
    rel="noopener noreferrer"
    color="text.primary"
    underline="none"
    {...props}
  >
    Contact support
  </Link>
);

export default ContactSupportLink;
