import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.palette.surface.level2};
  padding: 12px;
  border-radius: 16px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  line-height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.palette.action.active};
`;

export const Text = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
