import PageHeader from 'components/PageHeader/PageHeader';
import { useParams } from 'react-router-dom';
import { routes } from 'utils/routes';
import * as Styled from './ViewOrganizationPage.styles';
import EditOrganizationSection from './components/EditOrganizationSection/EditOrganizationSection';
import OrganizationAdmins from './components/OrganizationAdmins/OrganizationAdmins';
import OrganizationApps from './components/OrganizationApps/OrganizationApps';
import OrganizationAuthMethods from './components/OrganizationAuthMethods/OrganizationAuthMethods';

const ViewOrganizationPage = () => {
  const { organizationId = '' } = useParams();

  return (
    <>
      <PageHeader
        title="Manage organization"
        backTo={routes.organizationManagement()}
        size="small"
      />

      <Styled.Container>
        <EditOrganizationSection organizationId={organizationId} />

        <OrganizationApps organizationId={organizationId} />
        <OrganizationAuthMethods organizationId={organizationId} />
        <OrganizationAdmins organizationId={organizationId} />
      </Styled.Container>
    </>
  );
};

export default ViewOrganizationPage;
