import platformApi from 'api/platformApi/platformApi';
import 'api/platformApi/platformApi.types';
import {
  ICreateWorkspaceProjectParamsPath,
  ICreateWorkspaceProjectRequestBody,
  ICreateWorkspaceProjectResponse,
  IUpdateWorkspaceProjectServiceParamsPath,
  IUpdateWorkspaceProjectServiceRequestBody,
  IUpdateWorkspaceProjectServiceResponse,
  IWorkspaceProjectServiceParamsPath,
  IWorkspaceProjectServiceResponse,
  IWorkspaceProjectsParamsPath,
  IWorkspaceProjectsParamsQuery,
  IWorkspaceProjectsResponse,
} from 'api/platformApi/platformApi.types';
import { AxiosResponse } from 'axios';
import { WorkspacesServiceEndpoints } from '../workspacesService/services';
import { CreateProjectOperationError, UpdateProjectOperationError } from './errors';

export const WorkspaceProjectsServiceEndpoints = {
  root: 'projects' as const,
  resources({ workspaceId }: { workspaceId: string }) {
    return `${WorkspacesServiceEndpoints.resource({ workspaceId })}/${this.root}` as const;
  },
  resource({ projectId, workspaceId }: { projectId: string; workspaceId: string }) {
    return `${this.resources({ workspaceId })}/${projectId}` as const;
  },
};

export const getWorkspaceProjects = async ({
  params: {
    path: { workspaceId },
    query: { limit, direction, name, nextCursor, sortProperty },
  },
}: ServiceFnConfig<IWorkspaceProjectsParamsPath, IWorkspaceProjectsParamsQuery>) => {
  const networkResponse = await platformApi.get<IWorkspaceProjectsResponse>(
    WorkspaceProjectsServiceEndpoints.resources({ workspaceId }),
    {
      params: { limit, direction, name, nextCursor, sortProperty },
    },
  );
  const apiResponse = networkResponse.data;
  const apiData = apiResponse;

  return apiData;
};

export const getWorkspaceProject = async ({
  params: {
    path: { projectId, workspaceId },
  },
}: ServiceFnConfig<IWorkspaceProjectServiceParamsPath>) => {
  const networkResponse = await platformApi.get<IWorkspaceProjectServiceResponse>(
    WorkspaceProjectsServiceEndpoints.resource({ projectId, workspaceId }),
  );
  const apiResponse = networkResponse.data;
  const apiData = apiResponse;

  return apiData;
};

export const createWorkspaceProject = async ({
  body,
  params: {
    path: { workspaceId },
  },
}: ServiceFnConfig<
  ICreateWorkspaceProjectParamsPath,
  undefined,
  ICreateWorkspaceProjectRequestBody
>) => {
  try {
    const response = await platformApi.post<
      ICreateWorkspaceProjectResponse,
      AxiosResponse<ICreateWorkspaceProjectResponse>,
      ICreateWorkspaceProjectRequestBody
    >(WorkspaceProjectsServiceEndpoints.resources({ workspaceId }), body);

    return response.data;
  } catch (error) {
    const apiError = CreateProjectOperationError.unwrapAPIError(error);

    if (apiError) {
      throw new CreateProjectOperationError(apiError);
    }

    throw error;
  }
};

export const updateWorkspaceProject = async ({
  body,
  params: {
    path: { projectId, workspaceId },
  },
}: ServiceFnConfig<
  IUpdateWorkspaceProjectServiceParamsPath,
  undefined,
  IUpdateWorkspaceProjectServiceRequestBody
>) => {
  try {
    const networkResponse = await platformApi.put<IUpdateWorkspaceProjectServiceResponse>(
      WorkspaceProjectsServiceEndpoints.resource({ projectId, workspaceId }),
      body,
    );
    const apiResponse = networkResponse.data;
    const apiData = apiResponse;

    return apiData;
  } catch (error) {
    const apiError = UpdateProjectOperationError.unwrapAPIError(error);

    if (apiError) {
      throw new UpdateProjectOperationError(apiError);
    }

    throw error;
  }
};
