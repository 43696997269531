import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, CircularProgress } from '@mui/material';
import ImageFileChooser from 'components/ImageFileChooser/ImageFileChooser';
import { ImageFileChooserError } from 'components/ImageFileChooser/ImageFileChooser.utils';
import { FC } from 'react';
import {
  EditLogoButton,
  EditLogoContainer,
  LogoContainer,
  LogoEmptyIcon,
  LogoImg,
  LogoPlaceholder,
  LogoPreview,
} from './LogoField.styles';
import { LogoFieldProps } from './LogoField.types';
import { logoRequirements, translateImageFileChooseErrors } from './LogoField.utils';

const LogoField: FC<LogoFieldProps> = ({
  alt,
  defaultSrc,
  disabled,
  loading,
  onChange,
  onError,
  value,
}) => {
  const logoDefault = defaultSrc ? <LogoImg alt={alt} src={defaultSrc} /> : <LogoEmptyIcon />;

  const handleFileErrors = (errors: ImageFileChooserError[]) => {
    const errorMessages = translateImageFileChooseErrors(errors);
    onError?.(errorMessages);
  };

  return (
    <Box position="relative" maxWidth={64}>
      <LogoContainer>
        <LogoPlaceholder />

        {value ? <LogoPreview alt={alt} file={value} /> : logoDefault}

        {loading && (
          <Box
            display="grid"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={0}
            height={1}
            width={1}
          >
            <CircularProgress thickness={4} />
          </Box>
        )}
      </LogoContainer>

      {!disabled && (
        <EditLogoContainer>
          <ImageFileChooser
            {...logoRequirements}
            onChoose={onChange}
            onError={onError ? handleFileErrors : undefined}
          >
            <EditLogoButton size="small">
              <EditOutlinedIcon sx={{ fontSize: '1rem' }} />
            </EditLogoButton>
          </ImageFileChooser>
        </EditLogoContainer>
      )}
    </Box>
  );
};

export default LogoField;
