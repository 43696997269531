import useAuthContext from 'contexts/auth/useAuthContext';
import { ProjectsViewMode } from 'pages/ProjectsPage/components/ProjectsPanel/components/ProjectsView/ProjectsView.types';
import {
  SortDirection,
  SortProperty,
} from 'pages/ProjectsPage/components/ProjectsPanel/components/SortMenu/SortMenu.types';
import { usePreferences } from './usePreferences';

export interface OrganizationPreferences {
  projectsViewMode: ProjectsViewMode;
  projectsSortBy: SortProperty;
  projectsSortDirection: SortDirection;
}

export const defaultPreferences: OrganizationPreferences = {
  projectsViewMode: 'table',
  projectsSortBy: 'name',
  projectsSortDirection: 'asc',
};

export function useOrganizationPreferences(
  initialPreferences: OrganizationPreferences = defaultPreferences,
) {
  const { user } = useAuthContext();
  return usePreferences('organization', user?.organizationId, initialPreferences);
}

useOrganizationPreferences.getStorage = (
  organizationId: string,
  initialPreferences: OrganizationPreferences = defaultPreferences,
) => usePreferences.getStorage('organization', organizationId, initialPreferences);
