import { Link } from '@mui/material';
import monksFlowLogoUrl from 'assets/img/monks.flow-logo-white.svg';
import UserMenu from 'components/UserMenu/UserMenu';
import { FC } from 'react';
import { routes } from 'utils/routes';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import * as Styled from './AdminHeader.styles';
import { AdminHeaderProps } from './AdminHeader.types';

const AdminHeader: FC<AdminHeaderProps> = ({ user, title, onSignOutClick, userActions }) => {
  const activeWorkspace = useActiveWorkspace();
  const activeWorkspaceSlug = activeWorkspace?.slug ?? '';

  return (
    <Styled.Header>
      <Styled.LogoContainer>
        <Link href={routes.dashboard(activeWorkspaceSlug)} target="_blank">
          <img src={monksFlowLogoUrl} alt="monks.flow" title="monks.flow" />
        </Link>
        <Styled.Separator>|</Styled.Separator>
        <Styled.Title>{title}</Styled.Title>
      </Styled.LogoContainer>

      <UserMenu user={user} onSignOutClick={onSignOutClick} actions={userActions} />
    </Styled.Header>
  );
};

export default AdminHeader;
