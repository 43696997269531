import platformApi from 'api/platformApi/platformApi';
import {
  IUploadsImagesRequestBody,
  IUploadsImagesResponse,
} from 'api/platformApi/platformApi.types';

export const uploadImageService = async ({
  body,
}: ServiceFnConfig<undefined, undefined, IUploadsImagesRequestBody>) => {
  const response = await platformApi.post<IUploadsImagesResponse>('/uploads/images', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
