import platformApi from 'api/platformApi/platformApi';
import 'api/platformApi/platformApi.types';
import {
  IWorkspaceMetricsServiceParamsPath,
  IWorkspaceMetricsServiceResponse,
} from 'api/platformApi/platformApi.types';
import { WorkspacesServiceEndpoints } from '../workspacesService/services';

export const WorkspaceMetricsServiceEndpoints = {
  root: 'metrics',
  resource({ workspaceId }: { workspaceId: string }) {
    return `${WorkspacesServiceEndpoints.resource({ workspaceId })}/${this.root}`;
  },
};

export const getWorkspaceMetrics = async ({
  params: {
    path: { workspaceId },
  },
}: ServiceFnConfig<IWorkspaceMetricsServiceParamsPath>) => {
  const axiosResponse = await platformApi.get<IWorkspaceMetricsServiceResponse>(
    WorkspaceMetricsServiceEndpoints.resource({ workspaceId }),
  );
  const apiResponse = axiosResponse.data;
  const apiData = apiResponse;

  return apiData;
};
