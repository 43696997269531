import { forwardRef } from 'react';
import * as Styled from './AppDrawerContent.styles';
import { AppDrawerContentProps } from './AppDrawerContent.types';

const AppDrawerContent = forwardRef<HTMLDivElement, AppDrawerContentProps>((props, ref) => {
  return (
    <Styled.AppDrawerContent ref={ref} className={props.className}>
      {props.children}
    </Styled.AppDrawerContent>
  );
});

AppDrawerContent.displayName = 'AppDrawerContent';

export default AppDrawerContent;
