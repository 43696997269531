import styled, { css } from 'styled-components';

export const Header = styled.header`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(4)};
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 26px;
  font-weight: ${({ theme }) => theme.typography.fontWeightExtraBold};
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.pageBar};

  ${({ theme }) => {
    return css`
      @media (min-width: ${theme.breakpoints.values.sm}px) {
        font-size: 32px;
      }

      @media (min-width: ${theme.breakpoints.values.md}px) {
        font-size: 40px;
      }
    `;
  }}
`;
