import platformApi from 'api/platformApi/platformApi';
import { IAuthOrganizationMethodsResponse } from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig } from 'axios';

export const getAuthOrganizationMethods = (
  organizationSlug: string,
  axiosConfig?: AxiosRequestConfig,
) => {
  return platformApi.get<IAuthOrganizationMethodsResponse>(
    `/auth/organizations/${organizationSlug}/methods`,
    axiosConfig,
  );
};
