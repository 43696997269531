import { IProjectFile } from 'api/platformApi/platformApi.types';

function createFileIntentUrl(
  file: IProjectFile,
  intentType: 'open',
  params: {
    workspaceSlug: string;
    projectId: string;
  },
): string {
  const template = file.fileType.intents[intentType];
  if (!template) {
    throw new Error(
      `Intent type ${intentType} is not supported for file type ${file.fileType.name}`,
    );
  }
  const resolvedTemplate = template
    .replace(':workspace-slug', params.workspaceSlug)
    .replace(':project-id', params.projectId)
    .replace(':file-id', file.id);
  return `${file.app.hostedUrl}${resolvedTemplate}`;
}

export default createFileIntentUrl;
