import { DoNotDisturbOnOutlined } from '@mui/icons-material';
import { IconButton, MenuItem, TextField, Typography } from '@mui/material';
import useUsers from 'api/platformApi/services/usersService/useUsers';
import SelectMultiple from 'components/ui/SelectMultiple/SelectMultiple';
import { FC, useState } from 'react';
import { useDebounce } from 'utils/debounce';
import * as Styled from './SelectUsers.styles';
import { SelectUsersProps } from './SelectUsers.types';

const MIN_SEARCH_LENGTH = 3;

const SelectUsers: FC<SelectUsersProps> = ({
  label,
  value,
  onChange,
  inputLabel,
  inputPlaceholder,
  disabled,
  filter,
  sx,
}) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const search = useDebounce(searchInputValue, 300);
  const usersQuery = useUsers(
    {
      search,
    },
    {
      enabled: search.length >= MIN_SEARCH_LENGTH,
    },
  );
  const users = usersQuery.data?.data ?? [];
  const usersOptions = filter ? filter(users) : users;

  return (
    <SelectMultiple
      label={label}
      options={usersOptions}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} label={inputLabel} placeholder={inputPlaceholder} variant="filled" />
      )}
      minSearchLength={MIN_SEARCH_LENGTH}
      onSearchChange={(value) => {
        setSearchInputValue(value);
      }}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          <Styled.Avatar sizes="small">{option.name[0]}</Styled.Avatar>
          {option.name}
        </MenuItem>
      )}
      renderSelectedOption={(selectedUser, onRemoveUser) => (
        <Styled.MemberRow key={selectedUser.id}>
          <Styled.MemberDetails>
            <Styled.MemberAvatar>{selectedUser.name[0]}</Styled.MemberAvatar>
            <div>
              <Typography component="span" variant="bodyMedium">
                {selectedUser.name}
              </Typography>
            </div>
          </Styled.MemberDetails>
          {onRemoveUser && (
            <IconButton
              onClick={() => {
                onRemoveUser(selectedUser);
              }}
              color="error"
            >
              <DoNotDisturbOnOutlined />
            </IconButton>
          )}
        </Styled.MemberRow>
      )}
      onRemoveSelectedOption={(member) => {
        onChange(value.filter((selectedMember) => selectedMember.id !== member.id));
      }}
      disabled={disabled}
      sx={sx}
    />
  );
};

export default SelectUsers;
