import { HEADER_HEIGHT } from 'components/AppHeader/AppHeader.styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  background: ${({ theme }) => theme.palette.surface.level0};
  justify-content: center;
`;

export const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacing(0, 2, 4)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(0, 8, 4)};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }) => theme.spacing(0, 16, 4)};
    max-width: ${({ theme }) => theme.spacing(232)};
  }
`;
