import { IInvitationStatus } from 'api/platformApi/platformApi.types';
import useInvitation from 'api/platformApi/services/invitationService/useInvitation';
import Loader from 'components/Loader/Loader';
import { Navigate, useParams } from 'react-router-dom';
import { routes } from 'utils/routes';
import * as Styled from './ActivateAccountPage.styles';

const ActivateAccountPage = () => {
  const { invitationId = '' } = useParams<{ invitationId: string }>();
  const invitationQuery = useInvitation(invitationId);
  const invitation = invitationQuery.data;

  const isValidInvitationStatus = invitation?.status === IInvitationStatus.PENDING;

  if (invitationQuery.isLoading) {
    return <Loader />;
  }

  if (invitationQuery.isError || !isValidInvitationStatus) {
    return (
      <Styled.Container>
        <Styled.PageFeedback
          title="Link does not exist"
          description="The URL you clicked was deleted or was already used. If you are having issues logging in, please reach out to
  your onboarding team"
        />
      </Styled.Container>
    );
  }

  return <Navigate to={routes.login(invitation.organization.slug)} replace />;
};

export default ActivateAccountPage;
