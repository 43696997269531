import { IconButton as MuiIconButton, Menu } from '@mui/material';
import { Divider as MuiDivider } from '@mui/material';
import styled from 'styled-components';

export const IconButton = styled(MuiIconButton)`
  background-color: transparent;
  color: #fff;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

export const Avatar = styled.div`
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-radius: 50%;
  font-size: 14px;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.common.black};
`;

export const UserMenu = styled(Menu)`
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  max-width: 320px;

  & .MuiPaper-root {
    border-radius: 8px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
`;

export const Divider = styled(MuiDivider)`
  margin: ${({ theme }) => theme.spacing(1, 0, 1, 0)};
  border-color: ${({ theme }) => theme.palette.surface.dividerMain};
`;
