import { Alert as MuiAlert, Button } from '@mui/material';
import { AppDrawerFooter } from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter.styles';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AddUserAppDrawerFooter = styled(AppDrawerFooter)`
  justify-content: end;
`;

export const AddUserButton = styled(Button)`
  min-width: 122px;
`;

export const Alert = styled(MuiAlert)`
  color: #5f2120;
  background-color: #fdeded;
`;
