import waveformBlurredBg from 'assets/img/waveform-blurred-bg.jpg';
import styled from 'styled-components';

export const MainContainer = styled.main`
  background: ${({ theme }) => theme.palette.surface.level0} url(${waveformBlurredBg}) no-repeat
    center center / cover fixed;

  display: grid;
  place-items: center;
  position: fixed;
  inset: 0;
  z-index: 0;
`;

export const EmbeddedContainer = styled.section`
  display: grid;
  place-items: center;
  height: max(45vh, 450px);
  width: 100%;
`;
