import { Box } from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import { useParams } from 'react-router-dom';
import { routes } from 'utils/routes';
import EditWorkspace from './components/EditWorkspace/EditWorkspace';
import WorkspaceMembers from './components/WorkspaceMembers/WorkspaceMembers';
import WorkspaceSolutions from './components/WorkspaceSolutions/WorkspaceSolutions';

const ViewWorkspacePage = () => {
  const { workspaceId = '' } = useParams();

  return (
    <>
      <PageHeader title="Manage workspace" backTo={routes.adminWorkspaces()} size="small" />

      <Box display="flex" flexDirection="column" gap={3}>
        <EditWorkspace workspaceId={workspaceId} />
        <WorkspaceSolutions workspaceId={workspaceId} />
        <WorkspaceMembers workspaceId={workspaceId} />
      </Box>
    </>
  );
};

export default ViewWorkspacePage;
