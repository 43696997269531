import useAuthContext from 'contexts/auth/useAuthContext';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useActiveWorkspace = () => {
  const { user } = useAuthContext();
  const { workspaceSlug: activeWorkspaceSlug = '' } = useParams();

  const activeWorkspace = useMemo(() => {
    // If no workspaces are available, return null
    if (!user?.workspaces.length) {
      return null;
    }

    // Try to find the active workspace in the user's workspaces
    const userOwnedWorkspace = user.workspaces.find(
      (workspace) => workspace.slug === activeWorkspaceSlug,
    );

    // If the active workspace is owned by the user, use it. Otherwise, default to the first workspace.
    // Note: In the future, a workspace selection page will be implemented.
    return userOwnedWorkspace ?? user.workspaces[0];
  }, [user, activeWorkspaceSlug]);

  return activeWorkspace;
};
