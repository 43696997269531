import { QueryClientConfig } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { shouldThrowToBoundary } from 'components/ErrorBoundary/ErrorBoundary.utils';
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED } from 'utils/httpStatuses';

const MAX_FAILURE_COUNT = 3;

export const reactQueryConfig: QueryClientConfig = {
  defaultOptions: {
    mutations: {
      throwOnError: shouldThrowToBoundary,
    },
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        const isWithinMaxFailureCount = failureCount <= MAX_FAILURE_COUNT;

        if (isAxiosError(error)) {
          const status = error.response?.status ?? Number.MIN_VALUE;

          /**
           * In case unauthorized, retry because the access token might be refreshing.
           * Otherwise the user will be redirected to login.
           *
           * @see `src/api/platformApi/platformApi.ts`
           **/
          const isUnauthorizedError = status === UNAUTHORIZED;
          const isInternalServerError = status >= INTERNAL_SERVER_ERROR;

          return (isUnauthorizedError || isInternalServerError) && isWithinMaxFailureCount;
        }

        return isWithinMaxFailureCount;
      },
      throwOnError: shouldThrowToBoundary,
    },
  },
};
