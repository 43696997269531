import platformApi from 'api/platformApi/platformApi';
import {
  ICreateInvitationRequestBody,
  ICreateInvitationResponse,
  IPlatformApiGenericError,
} from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  ErrorSchema,
  InterfaceOperationError,
  isInvitationEmailExistsError,
  isInvitationUserExistsError,
} from 'utils/httpErrors';
import { BAD_REQUEST, CONFLICT } from 'utils/httpStatuses';

export const createInvitation = async (
  invitationData: ICreateInvitationRequestBody,
  axiosConfig?: AxiosRequestConfig,
) => {
  try {
    const response = await platformApi.post<
      ICreateInvitationResponse,
      AxiosResponse<ICreateInvitationResponse>,
      ICreateInvitationRequestBody
    >('/invitations', invitationData, axiosConfig);

    return response.data;
  } catch (error) {
    const apiError = CreateInvitationOperationError.unwrapAPIError(error);

    if (apiError) {
      throw new CreateInvitationOperationError(apiError);
    }

    throw error;
  }
};

type CreateInvitationOperationErrorSchema = ErrorSchema<typeof BAD_REQUEST | typeof CONFLICT>;
interface CreateInvitationOperationErrorFields {
  email: string;
}

export class CreateInvitationOperationError extends InterfaceOperationError<
  CreateInvitationOperationErrorFields,
  CreateInvitationOperationErrorSchema
> {
  errorSchema: CreateInvitationOperationErrorSchema = {
    [BAD_REQUEST]: (apiError) => {
      if (isInvitationUserExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'email';
        this.message = 'A user with this email address already exists in this organization.';
      }
    },
    [CONFLICT]: (apiError) => {
      if (isInvitationEmailExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'email';
        this.message = 'An invitation already exists for this email address.';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError);
    this.scopeAPIError();
  }
}
