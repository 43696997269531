import { IconButton as MuiIconButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  height: ${({ theme }) => theme.spacing(13)};
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.pageBar};
`;

// Adding the backdrop-filter in a separate div to isolate its effects,
// preventing it from disrupting the position or layout of other elements,
// e.g., the AppDrawer component within the PageHeader.
export const BackdropFilter = styled.div`
  backdrop-filter: blur(16px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

export const BackButton = styled(MuiIconButton)`
  width: 32px;
  height: 32px;
  line-height: 32px;
  background-color: #000;
  color: #fff;

  &:hover {
    background-color: #3c3c3e;
  }
`;

export const Title = styled.h1<{ $size?: 'small' }>`
  margin: 0;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.text.primary};
  /* utility ♋/headline/large-md */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
  letter-spacing: -0.16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    font-size: ${({ $size }) => ($size === 'small' ? '22px' : '32px')};
  }
`;

export const ControlsContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 16px;
`;
