import { Box, Typography } from '@mui/material';
import { DashboardBannerBackgroundImage } from './DashboardBanner.styles';

const DashboardBanner = () => {
  return (
    <Box
      component="section"
      borderRadius={4}
      position="relative"
      overflow="hidden"
      paddingY={{ xs: 3, sm: 4, lg: 5 }}
      paddingX={{ xs: 4, sm: 5, lg: 6 }}
    >
      <DashboardBannerBackgroundImage />

      <Box display="flex" flexDirection="column" gap={{ xs: 6, md: 8, xl: 10 }} position="relative">
        <Typography
          component="h1"
          color="common.white"
          fontSize={{ xs: 36, lg: 48 }}
          fontWeight="fontWeightExtraBold"
          lineHeight={1}
          width={{ xs: 0.88, sm: 0.75, md: 0.625, lg: 0.5 }}
        >
          Welcome to the future of marketing.
        </Typography>

        <Typography
          component="p"
          color="common.white"
          fontSize={16}
          fontWeight="fontWeightMedium"
          width={{ xs: 0.75, sm: 0.5, xl: 0.25 }}
        >
          Explore the tools, data and process that will transform your marketing workflow.
        </Typography>
      </Box>
    </Box>
  );
};

export default DashboardBanner;
