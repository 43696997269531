import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import { useReducer } from 'react';
import CreateWorkspaceDrawer from '../CreateWorkspaceDrawer/CreateWorkspaceDrawer';

const WorkspacesPageHeader = () => {
  const [isCreateWorkspaceOpen, toggleCreateWorkspace] = useReducer((state) => !state, false);

  return (
    <>
      <PageHeader title="Workspaces" size="small">
        <Button onClick={toggleCreateWorkspace} startIcon={<AddOutlinedIcon />} variant="contained">
          Create workspace
        </Button>
      </PageHeader>

      <CreateWorkspaceDrawer open={isCreateWorkspaceOpen} onClose={toggleCreateWorkspace} />
    </>
  );
};

export default WorkspacesPageHeader;
