import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;

  ${({ theme }) => {
    return css`
      @media (min-width: ${theme.breakpoints.values.sm}px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: ${theme.breakpoints.values.lg}px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (min-width: ${theme.breakpoints.values.xl}px) {
        grid-template-columns: repeat(4, 1fr);
      }
    `;
  }}
`;

export const Link = styled(RouterLink)`
  display: block;
  width: 100%;
  text-decoration: none;
`;
