import { Menu } from '@mui/material';
import styled from 'styled-components';

export const SortMenu = styled(Menu)`
  margin-top: ${({ theme }) => theme.spacing(0.5)};

  & .MuiPaper-root {
    border-radius: 8px;
  }
`;
