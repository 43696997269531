import { Skeleton, TableCell } from '@mui/material';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';

export const ProjectFilesTableRowSkeleton = () => {
  return (
    <MFTableBodyRow>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
    </MFTableBodyRow>
  );
};
