import { Avatar as MuiAvatar } from '@mui/material';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import styled from 'styled-components';

export const UserAvatar = styled(MuiAvatar)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.palette.primary.dark};
  background-color: ${({ theme }) => theme.palette.primary.light};
  font-size: 12px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.fontWeightExtraBold};
  line-height: 166%;
  letter-spacing: 0.4px;
`;

export const RecentProjectBodyRow = styled(MFTableBodyRow)`
  &:hover {
    cursor: pointer;
  }
`;
