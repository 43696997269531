import UserMenu from '../UserMenu/UserMenu';
import * as Styled from './AppHeader.styles';
import { AppsHeaderProps } from './AppHeader.types';
import WorkspaceSelector from './components/WorkspaceSelector/WorkspaceSelector';

const AppHeader = ({ flow, user, onSignOutClick, userActions }: AppsHeaderProps) => {
  return (
    <Styled.Header>
      <Styled.LeftSection>
        <WorkspaceSelector />
      </Styled.LeftSection>

      <Styled.CenterSection>
        <Styled.FlowName>{flow}</Styled.FlowName>
      </Styled.CenterSection>

      <Styled.RightSection>
        <UserMenu user={user} onSignOutClick={onSignOutClick} actions={userActions} />
      </Styled.RightSection>
    </Styled.Header>
  );
};

export default AppHeader;
