import { SortConfig, SortDirection, SortProperty } from './SortMenu.types';

export const sortLabels = {
  name: {
    asc: 'Ascending title',
    desc: 'Descending title',
  },
  createdAt: {
    asc: 'Oldest first',
    desc: 'Recent first',
  },
} satisfies Record<SortProperty, Record<SortDirection, string>>;

export const sortOptions = [
  { property: 'name', direction: 'asc' },
  { property: 'name', direction: 'desc' },
  { property: 'createdAt', direction: 'asc' },
  { property: 'createdAt', direction: 'desc' },
] satisfies SortConfig[];
