import { ContentCopyOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { MouseEvent, forwardRef } from 'react';

interface ClipboardTextFieldProps extends Omit<TextFieldProps, 'value'> {
  onCopy?: () => void;
  value: string;
}

const ClipboardTextField = forwardRef<HTMLInputElement, ClipboardTextFieldProps>(
  ({ onCopy, ...props }, ref) => {
    const copy = async (event: MouseEvent<HTMLButtonElement>) => {
      await navigator.clipboard.writeText(event.currentTarget.value);
      onCopy?.();
    };

    const select = (event: MouseEvent<HTMLInputElement>) => {
      event.currentTarget.select();
    };

    return (
      <TextField
        {...props}
        ref={ref}
        inputProps={{
          onClick: select,
        }}
        InputProps={{
          sx: {
            '&&& input': {
              color: 'text.disabled',
            },
          },
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Copy text field value"
                edge="end"
                onClick={copy}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
                size="small"
                value={props.value}
              >
                <ContentCopyOutlined fontSize="medium" />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
    );
  },
);

ClipboardTextField.displayName = 'ClipboardTextField';

export default ClipboardTextField;
