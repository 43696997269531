import * as Styled from './SideNavItem.styles';
import { SideNavItemProps } from './SideNavItem.types';

const SideNavItem = ({ label, active, icon, route, onClick, disabled }: SideNavItemProps) => {
  const content = (
    <>
      <Styled.IconContainer>{icon}</Styled.IconContainer>
      <span>{label}</span>
    </>
  );

  if (route) {
    return (
      <Styled.SideNavItemLink to={route} className={disabled ? 'disabled' : ''} end>
        {content}
      </Styled.SideNavItemLink>
    );
  }

  return (
    <Styled.SideNavItemButton
      onClick={onClick}
      className={active ? 'active' : ''}
      disabled={disabled}
    >
      {content}
    </Styled.SideNavItemButton>
  );
};

export default SideNavItem;
