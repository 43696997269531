import { Table, TableCell, Typography } from '@mui/material';
import useOrganizations from 'api/platformApi/services/organizationsService/useOrganizations';
import ListSkeleton from 'components/ui/ListSkeleton/ListSkeleton';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import { ITableOrderDirection } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import MFTableHeadCellSortable from 'components/ui/MFTableHeadCellSortable/MFTableHeadCellSortable';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import PageFeedback from 'components/ui/PageFeedback/PageFeedback';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ORGANIZATION_DEFAULT_LOGO } from 'utils/mediaPaths';
import { routes } from 'utils/routes';
import { headCells } from './OrganizationManagementPage.consts';
import * as Styled from './OrganizationManagementPage.styles';
import {
  IOrganizationOrderBy,
  IOrganizationWithAppsAdminsStrings,
} from './OrganizationManagementPage.types';
import {
  getOrganizationsComparator,
  mapOrganizationToOrgWithAppsAdminsString,
} from './OrganizationManagementPage.utils';
import OrgManagementPageHeader from './components/OrgManagementPageHeader/OrgManagementPageHeader';

const OrganizationManagementPage = () => {
  const [orderBy, setOrderBy] = useState<IOrganizationOrderBy>('name');
  const [orderDirection, setOrderDirection] = useState<ITableOrderDirection>('asc');
  const navigate = useNavigate();

  const organizationsQuery = useOrganizations();
  const organizations = organizationsQuery.data?.data ?? [];
  const sortedOrganizations: IOrganizationWithAppsAdminsStrings[] = organizations
    .map(mapOrganizationToOrgWithAppsAdminsString)
    .sort(getOrganizationsComparator(orderDirection, orderBy));

  const handleSort = (headCellId: IOrganizationOrderBy) => {
    setOrderBy(headCellId);
    setOrderDirection((orderDirection) => (orderDirection === 'desc' ? 'asc' : 'desc'));
  };

  if (organizationsQuery.isLoading) {
    return (
      <>
        <OrgManagementPageHeader />
        <ListSkeleton headCells={headCells} rows={5} />
      </>
    );
  }

  if (organizationsQuery.isError) {
    return (
      <PageFeedback
        title="Organizations failed to load"
        description="An error occurred and organizations could not be loaded. Please try refreshing the page to access organizations."
      />
    );
  }

  if (organizations.length === 0) {
    return (
      <>
        <OrgManagementPageHeader />

        <PageFeedback
          title="No organizations created yet"
          description={
            'Create your first organization with the "Create organization" action above.'
          }
        />
      </>
    );
  }

  return (
    <>
      <OrgManagementPageHeader />

      <MFTableContainer>
        <Table>
          <MFTableHead>
            <MFTableHeadRow>
              {headCells.map((headCell) =>
                headCell.sortable ? (
                  <MFTableHeadCellSortable
                    key={headCell.id}
                    active
                    direction={orderDirection}
                    onSort={() => {
                      handleSort(headCell.id);
                    }}
                    sx={{ width: `${100 / headCells.length}%` }}
                  >
                    {headCell.label}
                  </MFTableHeadCellSortable>
                ) : (
                  <MFTableHeadCell key={headCell.id} sx={{ width: `${100 / headCells.length}%` }}>
                    {headCell.label}
                  </MFTableHeadCell>
                ),
              )}
            </MFTableHeadRow>
          </MFTableHead>

          <MFTableBody>
            {sortedOrganizations.map((organization) => (
              <MFTableBodyRow
                key={organization.id}
                onClick={() => {
                  navigate(routes.viewOrganization(organization.id));
                }}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>
                  <Styled.NameContent>
                    <Styled.OrganizationLogo
                      src={organization.logoUrl ? organization.logoUrl : ORGANIZATION_DEFAULT_LOGO}
                    />
                    <Styled.Name variant="titleSmall" color="text.primary">
                      {organization.name}
                    </Styled.Name>
                  </Styled.NameContent>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyMedium">{organization.appsString}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyMedium">{organization.adminsString}</Typography>
                </TableCell>
              </MFTableBodyRow>
            ))}
          </MFTableBody>
        </Table>
      </MFTableContainer>
    </>
  );
};

export default OrganizationManagementPage;
