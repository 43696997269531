import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  IPlatformApiGenericError,
  IUpdateOrganizationAppsServiceParamsPath,
  IUpdateOrganizationAppsServiceRequestBody,
} from 'api/platformApi/platformApi.types';
import { AxiosError } from 'axios';
import { updateOrganizationAppsService } from './updateOrganizationAppsService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseUpdateOrganizationAppsParams extends IUpdateOrganizationAppsServiceParamsPath {}

const useUpdateOrganizationApps = (
  params: UseUpdateOrganizationAppsParams,
  mutationOptions?: UseMutationOptions<
    unknown,
    AxiosError<IPlatformApiGenericError>,
    IUpdateOrganizationAppsServiceRequestBody
  >,
) => {
  return useMutation({
    ...mutationOptions,
    mutationFn: (orgAppsData: IUpdateOrganizationAppsServiceRequestBody) => {
      return updateOrganizationAppsService({
        params: {
          path: {
            organizationId: params.organizationId,
          },
        },
        body: orgAppsData,
      });
    },
  });
};

export default useUpdateOrganizationApps;
