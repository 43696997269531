import { ITableOrderDirection } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import { IInvitationWithWorkspacesString, IUsersOrderBy } from './InvitationsList.types';

export function descendingComparator(
  a: IInvitationWithWorkspacesString,
  b: IInvitationWithWorkspacesString,
  orderBy: IUsersOrderBy,
) {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (valueA == null && valueB == null) {
    return 0;
  }
  if (valueA == null) {
    return 1;
  }
  if (valueB == null) {
    return -1;
  }
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

export function getComparator(
  order: ITableOrderDirection,
  orderBy: IUsersOrderBy,
): (a: IInvitationWithWorkspacesString, b: IInvitationWithWorkspacesString) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
