import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem } from '@mui/material';
import EditProjectDrawer from 'components/EditProjectDrawer/EditProjectDrawer';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { useProjectAccessLevel } from 'hooks/useAccessLevel';
import { FC, MouseEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import * as Styled from './ProjectOptions.styles';

const ProjectOptions: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const activeWorkspace = useActiveWorkspace();
  const workspaceId = activeWorkspace?.id;
  const projectAccessLevel = useProjectAccessLevel({ workspaceId, projectId });

  const [optionsButtonElement, setOptionsButtonElement] = useState<null | HTMLElement>(null);
  const [isEditProjectOpen, setIsEditProjectOpen] = useState(false);
  const isMenuOpen = Boolean(optionsButtonElement);
  const snackbar = useSnackbarContext();

  const handleOptionsOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setOptionsButtonElement(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setOptionsButtonElement(null);
  };

  const handleEditProjectOpen = () => {
    handleOptionsClose();
    setIsEditProjectOpen(true);
  };

  const handleEditProjectClose = () => {
    setIsEditProjectOpen(false);
  };

  const handleCopyLink = () => {
    const pageUrl = window.location.href;
    void navigator.clipboard.writeText(pageUrl).then(() => {
      snackbar.open('Link copied to clipboard');
    });
  };

  return (
    <>
      <Styled.OptionsButton onClick={handleOptionsOpen} size="small">
        <MoreHorizIcon />
      </Styled.OptionsButton>

      <Styled.OptionsMenu
        open={isMenuOpen}
        onClose={handleOptionsClose}
        anchorEl={optionsButtonElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleCopyLink}>Copy link</MenuItem>

        {projectAccessLevel === 'write' && (
          <MenuItem onClick={handleEditProjectOpen}>Edit project</MenuItem>
        )}
      </Styled.OptionsMenu>

      <EditProjectDrawer open={isEditProjectOpen} onClose={handleEditProjectClose} />
    </>
  );
};

export default ProjectOptions;
