import { Table, TableSortLabel } from '@mui/material';
import useInvitations from 'api/platformApi/services/invitationsService/useInvitations';
import { useRevokeInvitation } from 'api/platformApi/services/revokeInvitationService/useRevokeInvitation';
import ListSkeleton from 'components/ui/ListSkeleton/ListSkeleton';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import {
  ITableHeadCell,
  ITableOrderDirection,
} from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import PageFeedback from 'components/ui/PageFeedback/PageFeedback';
import { FC, useState } from 'react';
import { generateStringFromArray } from 'utils/arraryString';
import * as Styled from './InvitationsList.styles';
import {
  IInvitationWithWorkspacesString,
  InvitationsListProps,
  IUsersOrderBy,
} from './InvitationsList.types';
import { getComparator } from './InvitationsList.utils';
import EditInvitationDrawer from './components/EditInvitationDrawer/EditInvitationDrawer';
import InvitationRow from './components/InvitationRow/InvitationRow';
import RevokeInvitationDialog from './components/RevokeInvitationDialog/RevokeInvitationDialog';
import {
  InvitationActionType,
  useInvitationActionManager,
} from './hooks/useInvitationActionManager';

const headCells: ITableHeadCell<IUsersOrderBy>[] = [
  { id: 'email', label: 'Email address' },
  { id: 'workspacesString', label: 'Workspaces' },
  { id: 'dateSent', label: 'Last sent date' },
  { id: 'expiresAt', label: 'Expires on' },
  { id: 'status', label: 'Status' },
];

const InvitationsList: FC<InvitationsListProps> = ({ searchValue }) => {
  const [orderBy, setOrderBy] = useState<IUsersOrderBy>('dateSent');
  const [orderDirection, setOrderDirection] = useState<ITableOrderDirection>('desc');
  const { startAction, selectedInvitation, endAction, isActionActive } =
    useInvitationActionManager();

  const revokeInvitationMutation = useRevokeInvitation();
  const { data: invitations = [], isLoading, isError, refetch } = useInvitations();
  const filteredInvitations = invitations.filter((invitation) => {
    return invitation.email.includes(searchValue);
  });
  const sortedInvitations: IInvitationWithWorkspacesString[] = filteredInvitations
    .map((invitation) => {
      const workspacesString = generateStringFromArray(invitation.workspaces, 1, (ws) => ws.name);
      return {
        ...invitation,
        workspacesString,
      };
    })
    .sort(getComparator(orderDirection, orderBy));

  if (isLoading) {
    return <ListSkeleton headCells={headCells} rows={8} />;
  }

  if (isError) {
    return (
      <PageFeedback
        title="Invitations failed to load"
        description="An error occurred and invitations could not be loaded. Please try refreshing the page to access."
      />
    );
  }

  if (filteredInvitations.length === 0) {
    return (
      <PageFeedback
        title="No search results"
        description={`No results for ${searchValue}. Please check your search query and try again.`}
      />
    );
  }

  if (invitations.length === 0) {
    return (
      <PageFeedback
        title="No invitations sent yet"
        description='No user invitations have been sent yet. Get started by clicking the "Invite User" action above'
      />
    );
  }

  return (
    <>
      <MFTableContainer>
        <Table>
          <MFTableHead>
            <MFTableHeadRow>
              {headCells.map((headCell) => (
                <Styled.InvitationsMFTableHeadCell variant="head" key={headCell.id}>
                  <TableSortLabel
                    onClick={() => {
                      setOrderBy(headCell.id);
                      setOrderDirection((orderDirection) =>
                        orderDirection === 'desc' ? 'asc' : 'desc',
                      );
                    }}
                    active={headCell.id === orderBy}
                    direction={orderDirection}
                    hideSortIcon={false}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </Styled.InvitationsMFTableHeadCell>
              ))}
              <Styled.InvitationsMFTableHeadCell variant="head" />
            </MFTableHeadRow>
          </MFTableHead>

          <MFTableBody>
            {sortedInvitations.map((invitation) => (
              <InvitationRow
                key={invitation.id}
                invitation={invitation}
                onClickRevoke={() => {
                  startAction(InvitationActionType.REVOKE, invitation);
                }}
                onClickEdit={() => {
                  startAction(InvitationActionType.EDIT, invitation);
                }}
              />
            ))}
          </MFTableBody>
        </Table>
      </MFTableContainer>

      <RevokeInvitationDialog
        open={isActionActive(InvitationActionType.REVOKE)}
        onCancel={endAction}
        onRevoke={() => {
          if (!selectedInvitation?.id) return;

          revokeInvitationMutation.mutate({ invitationId: selectedInvitation.id });
          endAction();
        }}
      />

      <EditInvitationDrawer
        open={isActionActive(InvitationActionType.EDIT)}
        invitation={selectedInvitation}
        onClose={endAction}
        onEditSuccess={refetch}
      />
    </>
  );
};

export default InvitationsList;
