import MuiButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const SolutionContainer = styled(MuiButtonBase)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  height: 96px;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.surface.level1};
  box-shadow: ${({ theme }) => theme.monksShadows.level1};
  transition: background-color 300ms ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.palette.surface.level4};
  }
`;

export const SolutionTitle = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SolutionDescription = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
