import axios, { AxiosError } from 'axios';
import { UNAUTHORIZED } from 'utils/httpStatuses';
import { useGlobalPreferences } from 'utils/preferences/useGlobalPreferences';
import { redirectToLogout, refreshToken } from '../../utils/auth';

let refreshInProgress = false;

const platformApi = axios.create({
  baseURL: import.meta.env.PLATFORM_API_BASE_URL,
  headers: {
    Accept: 'application/json;v=1',
  },
  withCredentials: true,
});

const globalPreferences = useGlobalPreferences.getStorage();

platformApi.interceptors.response.use(
  (response) => {
    return response;
  },
  /**
   * The following code dictates how the application handles unauthorized requests.
   * Here's how the flow should work:
   *
   * 1. The token expires or is not present, access is invalid.
   * 2. The application makes a new API request. This request fails because the access is invalid.
   * 3. The failed request is intercepted by this code, which either:
   *   - Triggers a token refresh if user was previously authenticated (`globalPreferences.enableRefreshToken`) and waits for it to complete; or
   *   - Redirects the user to the login page if the user has not authenticated previously but initiated the authentication process (`authInitiatedCookie`).
   * 4. If the refresh is successful, an error is dispatched and picked up by `react-query`.
   * 5. `react-query` enters `retry` mode and makes the previously failed request again.
   *    This time, the user has a new, valid token and is authenticated correctly.
   * 6. If the refresh is not successful, the user is logged out.
   *
   * For more information:
   *
   * @see `src/utils/auth.ts`
   * @see `src/main.tsx`
   */
  async function handleUnauthorizedError(error: AxiosError) {
    const isUnauthorizedError = error.response?.status === UNAUTHORIZED;
    const isOnAuthErrorPage = window.location.href.includes('/auth/error');

    if (!isUnauthorizedError) return Promise.reject(error);

    /**
     * Multiple requests can be failing at the same time while we wait for the
     * token to be refreshed. Other failed requests will just error and `react-query`
     * will retry them. After a successful refresh, we hope that the failed requests
     * get retried once more for then to be resolved successfully.
     *
     * This check prevents multiple refreshes from happening on every failed
     * subsequent request.
     *
     * @see `src/main.tsx` for more information about the retry mechanism.
     */
    if (refreshInProgress) return Promise.reject(error);

    // prevents infinite loop redirect when on the auth error page
    if (isOnAuthErrorPage) return Promise.reject(error);

    if (globalPreferences.enableRefreshToken) {
      refreshInProgress = true;

      const hasRefreshed = await refreshToken();

      refreshInProgress = false;

      if (hasRefreshed) {
        // dispatch error to be picked up by react-query and retried without redirecting
        return Promise.reject(error);
      }

      // invalid refresh logs out the user
      redirectToLogout();
    }

    return Promise.reject(error);
  },
);

export default platformApi;
