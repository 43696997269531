import { useQuery } from '@tanstack/react-query';
import { getInvitations } from './invitationsService';

export const INVITATIONS_QUERY_KEY_PREFIX = 'invitations';

export const getInvitationsQueryKey = () => [INVITATIONS_QUERY_KEY_PREFIX];

function useInvitations() {
  return useQuery({
    queryKey: getInvitationsQueryKey(),
    queryFn: getInvitations,
  });
}

export default useInvitations;
