import PageFeedback from 'components/ui/PageFeedback/PageFeedback';
import { FC } from 'react';
import WorkspacesPageHeader from '../WorkspacesPageHeader/WorkspacesPageHeader';
import { WorkspacesFeedbackProps } from './WorkspacesFeedback.types';

const WorkspacesFeedback: FC<WorkspacesFeedbackProps> = (props) => {
  return (
    <>
      <WorkspacesPageHeader />

      <PageFeedback title={props.title} description={props.description} />
    </>
  );
};

export default WorkspacesFeedback;
