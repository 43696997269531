import { FC } from 'react';
import * as Styled from './AppDrawerSubHeader.styles';
import { AppDrawerSubHeaderProps } from './AppDrawerSubHeader.types';

const AppDrawerSubHeader: FC<AppDrawerSubHeaderProps> = (props) => {
  return (
    <Styled.AppDrawerSubHeader className={props.className}>
      {props.children}
    </Styled.AppDrawerSubHeader>
  );
};

export default AppDrawerSubHeader;
