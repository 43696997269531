import { COLORS } from '@monksflow/monks-ui';
import { Avatar as MuiAvatar } from '@mui/material';
import styled from 'styled-components';

export const Avatar = styled(MuiAvatar)`
  width: 24px;
  height: 24px;
  background-color: ${COLORS.blue[200]};

  svg {
    color: #7e57c2;
  }
`;
