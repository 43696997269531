import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import {
  NavigateTo404,
  NavigateToIndex,
  NavigateToLogin,
  NavigateToWorkspace,
} from 'components/NavigateTo/NavigateTo';
import ActivateAccountPage from 'pages/ActivateAccountPage/ActivateAccountPage';
import AdminLayout from 'pages/Admin/AdminLayout';
import UsersPage from 'pages/Admin/UsersPage/UsersPage';
import ViewWorkspacePage from 'pages/Admin/ViewWorkspacePage/ViewWorkspacePage';
import WorkspacesPage from 'pages/Admin/WorkspacesPage/WorkspacesPage';
import AppLayout from 'pages/AppLayout';
import ComingSoonPage from 'pages/ComingSoonPage/ComingSoonPage';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import Error404Page from 'pages/Error404Page/Error404Page';
import ErrorAuthPage from 'pages/ErrorAuthPage/ErrorAuthPage';
import ErrorNoOrganizationPage from 'pages/ErrorNoOrganizationPage/ErrorNoOrganizationPage';
import ErrorNoWorkspacePage from 'pages/ErrorNoWorkspacePage/ErrorNoWorkspacePage';
import EulaAcceptancePage from 'pages/EulaAcceptancePage/EulaAcceptancePage';
import InstanceAdminLayout from 'pages/InstanceAdmin/InstanceAdminLayout';
import OrganizationManagementPage from 'pages/InstanceAdmin/OrganizationManagementPage/OrganizationManagementPage';
import ViewOrganizationPage from 'pages/InstanceAdmin/ViewOrganizationPage/ViewOrganizationPage';
import LegalInformationLayout from 'pages/LegalInformation/LegalInformationLayout';
import OrganizationLoginPage from 'pages/OrganizationLoginPage/OrganizationLoginPage';
import ProjectsPage from 'pages/ProjectsPage/ProjectsPage';
import {
  AgreementsGuard,
  AuthenticatedGuard,
  InstanceAdminGuard,
  OrganizationSlugGuard,
  PermissionsGuard,
  SameAuthOrganizationSlugGuard,
  UnauthenticatedGuard,
  WorkspaceRequiredGuard,
} from 'pages/RouteGuard/RouteGuard';
import { RouteGuardState } from 'pages/RouteGuard/RouteGuard.consts';
import ViewProjectPage from 'pages/ViewProjectPage/ViewProjectPage';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { PERMISSIONS } from 'utils/permissions';
import { routes } from 'utils/routes';

export const reactRouterConfig: RouteObject[] = [
  /*
    ========================================
    Dynamic routes
    ========================================
  */
  {
    path: '/:organizationSlug?/login',
    element: (
      <UnauthenticatedGuard
        fallback={() => (
          <OrganizationSlugGuard
            fallback={(state) => {
              if (state === RouteGuardState.OrganizationSlugInStorage) return <NavigateToLogin />;
              if (state === RouteGuardState.NoOrganizationSlug) return <ErrorNoOrganizationPage />;
              return null;
            }}
          >
            <SameAuthOrganizationSlugGuard
              fallback={(state) => {
                if (state === RouteGuardState.DifferentOrganizationSlug) return <NavigateTo404 />;
                return null;
              }}
            >
              <NavigateToIndex />
            </SameAuthOrganizationSlugGuard>
          </OrganizationSlugGuard>
        )}
      >
        <OrganizationSlugGuard
          fallback={(state) => {
            if (state === RouteGuardState.OrganizationSlugInStorage) return <NavigateToLogin />;
            if (state === RouteGuardState.NoOrganizationSlug) return <ErrorNoOrganizationPage />;
            return null;
          }}
        >
          <Outlet />
        </OrganizationSlugGuard>
      </UnauthenticatedGuard>
    ),
    children: [
      {
        index: true,
        element: <OrganizationLoginPage />,
      },
    ],
  },
  {
    path: '/activate-account/:invitationId',
    element: (
      <UnauthenticatedGuard fallback={() => <NavigateToIndex />}>
        <ActivateAccountPage />
      </UnauthenticatedGuard>
    ),
  },
  {
    path: '/:workspaceSlug',
    errorElement: <ErrorBoundary />,
    element: (
      <AuthenticatedGuard fallback={() => <NavigateToLogin />}>
        <AgreementsGuard
          fallback={(state) => {
            if (state === RouteGuardState.EulaNotAccepted) return <EulaAcceptancePage />;
            return null;
          }}
        >
          <WorkspaceRequiredGuard
            fallback={(state) => {
              if (state === RouteGuardState.NoWorkspaceAssigned) return <ErrorNoWorkspacePage />;
              if (state === RouteGuardState.NoWorkspaceFound) return <NavigateTo404 />;
              return null;
            }}
          >
            <Outlet />
          </WorkspaceRequiredGuard>
        </AgreementsGuard>
      </AuthenticatedGuard>
    ),
    children: [
      {
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <DashboardPage />,
          },
          {
            path: 'projects',
            element: <ProjectsPage />,
          },
          {
            path: 'projects/:projectId',
            element: <ViewProjectPage />,
          },
          {
            path: '*',
            element: <NavigateTo404 />,
          },
        ],
      },
    ],
  },
  /*
    ========================================
    Error routes
    ========================================
  */
  {
    path: 'auth/error',
    Component: ErrorAuthPage,
  },
  {
    path: '/404',
    Component: Error404Page,
  },
  {
    path: '/coming-soon',
    Component: ComingSoonPage,
  },
  /*
    ========================================
    Static routes
    ========================================
  */
  {
    path: '/',
    errorElement: <ErrorBoundary />,
    element: (
      <UnauthenticatedGuard
        fallback={() => (
          <WorkspaceRequiredGuard
            fallback={(state) => {
              if (state === RouteGuardState.NoWorkspaceAssigned) return <ErrorNoWorkspacePage />;
              if (state === RouteGuardState.NoWorkspaceFound) return <NavigateTo404 />;
              return null;
            }}
          >
            <NavigateToWorkspace savePreference />
          </WorkspaceRequiredGuard>
        )}
      >
        <Outlet />
      </UnauthenticatedGuard>
    ),
    children: [
      {
        element: <NavigateToLogin />,
        index: true,
      },
      {
        path: '*',
        element: <NavigateToIndex />,
      },
    ],
  },
  {
    path: '/legal-information',
    errorElement: <ErrorBoundary />,
    element: (
      <AuthenticatedGuard fallback={() => <NavigateToLogin />}>
        <Outlet />
      </AuthenticatedGuard>
    ),
    children: [
      {
        element: <LegalInformationLayout />,
        children: [
          {
            async lazy() {
              const { default: LegalInformationPage } = await import(
                'pages/LegalInformation/LegalInformationPage/LegalInformationPage'
              );
              return { Component: LegalInformationPage };
            },
            index: true,
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    errorElement: <ErrorBoundary />,
    element: (
      <AuthenticatedGuard fallback={() => <NavigateToLogin />}>
        <AgreementsGuard
          fallback={(state) => {
            if (state === RouteGuardState.EulaNotAccepted) return <EulaAcceptancePage />;
            return null;
          }}
        >
          <PermissionsGuard
            fallback={(state) => {
              if (state === RouteGuardState.ForbiddenPermissions) return <NavigateTo404 />;
              return null;
            }}
            ruleset={[[PERMISSIONS.PlatformManageUsers], [PERMISSIONS.PlatformManageWorkspaces]]}
          >
            <Outlet />
          </PermissionsGuard>
        </AgreementsGuard>
      </AuthenticatedGuard>
    ),
    children: [
      {
        Component: AdminLayout,
        children: [
          {
            element: (
              <PermissionsGuard
                ruleset={[PERMISSIONS.PlatformManageUsers]}
                fallback={() => <Navigate to={routes.adminWorkspaces()} replace />}
              >
                <Navigate to={routes.adminUsers()} replace />
              </PermissionsGuard>
            ),
            index: true,
          },
          {
            path: 'users',
            element: (
              <PermissionsGuard
                fallback={(state) => {
                  if (state === RouteGuardState.ForbiddenPermissions) return <NavigateTo404 />;
                  return null;
                }}
                ruleset={[PERMISSIONS.PlatformManageUsers]}
              >
                <Outlet />
              </PermissionsGuard>
            ),
            children: [
              {
                index: true,
                Component: UsersPage,
              },
            ],
          },
          {
            path: 'workspaces',
            element: (
              <PermissionsGuard
                fallback={(state) => {
                  if (state === RouteGuardState.ForbiddenPermissions) return <NavigateTo404 />;
                  return null;
                }}
                ruleset={[PERMISSIONS.PlatformManageWorkspaces]}
              >
                <Outlet />
              </PermissionsGuard>
            ),
            children: [
              {
                index: true,
                Component: WorkspacesPage,
              },
              {
                path: ':workspaceId',
                Component: ViewWorkspacePage,
              },
            ],
          },
          {
            path: '*',
            element: <NavigateTo404 />,
          },
        ],
      },
    ],
  },
  {
    path: '/organization-management',
    element: (
      <AuthenticatedGuard fallback={() => <NavigateToLogin />}>
        <AgreementsGuard
          fallback={(state) => {
            if (state === RouteGuardState.EulaNotAccepted) return <EulaAcceptancePage />;
            return null;
          }}
        >
          <InstanceAdminGuard
            fallback={(state) => {
              if (state === RouteGuardState.ForbiddenRole) return <NavigateTo404 />;
              return null;
            }}
          >
            <Outlet />
          </InstanceAdminGuard>
        </AgreementsGuard>
      </AuthenticatedGuard>
    ),
    children: [
      {
        element: <InstanceAdminLayout />,
        children: [
          {
            element: <OrganizationManagementPage />,
            index: true,
          },
          {
            path: ':organizationId',
            element: <ViewOrganizationPage />,
          },
        ],
      },
    ],
  },
];
