import { Autocomplete, MenuItem, TextField } from '@mui/material';
import useWorkspaceUsers from 'api/platformApi/services/workspaceUsersService/useWorkspaceUsers';
import { FC, useState } from 'react';
import { useDebounce } from 'utils/debounce';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import * as Styled from './MemberSelect.styles';
import { MemberSelectProps } from './MemberSelect.types';

const MIN_SEARCH_LENGTH = 3;

const getNoOptionsText = (searchValue: string) => {
  if (searchValue.length === 0) {
    return 'Begin typing to search workspace members...';
  }
  if (searchValue.length < MIN_SEARCH_LENGTH) {
    return `Type at least ${MIN_SEARCH_LENGTH} characters to search...`;
  }
  return `No results matching "${searchValue}"`;
};

const MemberSelect: FC<MemberSelectProps> = (props) => {
  const { selectedMembers, onMemberSelect, disabled } = props;
  const workspaceId = useActiveWorkspace()?.id ?? '';
  const [searchInputValue, setSearchInputValue] = useState('');
  const search = useDebounce(searchInputValue, 300);
  const { data: workspaceUsers = [], isLoading } = useWorkspaceUsers({
    workspaceId,
    name: search,
    enabled: search.length >= MIN_SEARCH_LENGTH,
  });
  const memberOptions = workspaceUsers;

  return (
    <Autocomplete
      options={memberOptions}
      value={selectedMembers}
      onChange={(_, autocompleteValue, reason) => {
        if (reason !== 'removeOption') {
          onMemberSelect(autocompleteValue);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label="Select members" placeholder="Search..." variant="filled" />
      )}
      onInputChange={(_, inputValue) => {
        setSearchInputValue(inputValue);
      }}
      renderTags={() => null}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          <Styled.Avatar sizes="small">{option.name[0]}</Styled.Avatar>
          {option.name}
        </MenuItem>
      )}
      multiple
      disableClearable
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText={
        <Styled.NoOptionsText component="div" variant="bodyMedium">
          {getNoOptionsText(searchInputValue)}
        </Styled.NoOptionsText>
      }
      loadingText={
        <Styled.NoOptionsText component="div" variant="bodyMedium">
          Searching...
        </Styled.NoOptionsText>
      }
      loading={isLoading}
      disabled={disabled}
    />
  );
};

export default MemberSelect;
