import {
  Avatar as MuiAvatar,
  Chip as MuiChip,
  TableCell as MuiTableCell,
  Typography,
} from '@mui/material';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import styled from 'styled-components';

export const AccountsMFTableHeadCell = styled(MFTableHeadCell)`
  width: 33.33%;
`;

export const UserCell = styled(MuiTableCell)`
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const Avatar = styled(MuiAvatar)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.palette.primary.dark};
  background-color: ${({ theme }) => theme.palette.primary.light};
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 166%;
  letter-spacing: 0.4px;
`;

export const Name = styled(Typography)`
  margin-left: 12px;
`;

export const Chip = styled(MuiChip)`
  margin-left: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-right: 20%;
  }
`;
