import { Typography } from '@mui/material';
import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import styled from 'styled-components';

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const CustomMFTableContainer = styled(MFTableContainer)`
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const ClientId = styled(Typography)`
  display: block;
  max-width: calc((85vw) / 3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
