import {
  ImageFileChooserErrorCode,
  ImageFileMetadata,
  ImageFileRequirements,
} from './ImageFileChooser.types';

export async function validateFile(file: File, requirements: ImageFileRequirements) {
  const object = URL.createObjectURL(file);
  const image = new Image();

  image.src = object;

  // wait for image to load before using the image dimensions
  await new Promise((resolve) => {
    image.onload = resolve;
  });

  const metadata = {
    name: file.name,
    dimensions: { height: image.naturalHeight, width: image.naturalWidth },
    size: file.size,
    type: file.type,
  };

  const errors: ImageFileChooserError[] = [];

  if (requirements.maxSize && metadata.size > requirements.maxSize) {
    errors.push(
      new ImageFileChooserError(
        `Image ${file.name} is exceeding the maximum size of ${convertBytesToReadable(requirements.maxSize)}.`,
        ImageFileChooserErrorCode.FileTooBig,
        metadata,
      ),
    );
  }

  if (requirements.types && !requirements.types.includes(metadata.type)) {
    errors.push(
      new ImageFileChooserError(
        `Image ${file.name} contains a non supported format.`,
        ImageFileChooserErrorCode.FileTypeNotAccepted,
        metadata,
      ),
    );
  }

  if (
    requirements.maxDimension &&
    (metadata.dimensions.width > requirements.maxDimension ||
      metadata.dimensions.height > requirements.maxDimension)
  ) {
    errors.push(
      new ImageFileChooserError(
        `Image ${file.name} is too large. The maximum dimension is ${requirements.maxDimension} pixels.`,
        ImageFileChooserErrorCode.FileDimensionsOff,
        metadata,
      ),
    );
  }

  if (
    requirements.minDimension &&
    (metadata.dimensions.width < requirements.minDimension ||
      metadata.dimensions.height < requirements.minDimension)
  ) {
    errors.push(
      new ImageFileChooserError(
        `Image ${file.name} is too small. The minimum dimension is ${requirements.minDimension} pixels.`,
        ImageFileChooserErrorCode.FileDimensionsOff,
        metadata,
      ),
    );
  }

  const fileAspectRatio = Math.round(
    Math.max(metadata.dimensions.width, metadata.dimensions.height) /
      Math.min(metadata.dimensions.width, metadata.dimensions.height),
  );

  if (requirements.aspectRatio && fileAspectRatio !== requirements.aspectRatio) {
    errors.push(
      new ImageFileChooserError(
        `Image ${file.name} should match the aspect ratio of ${requirements.aspectRatio}.`,
        ImageFileChooserErrorCode.FileAspectRatioOff,
        metadata,
      ),
    );
  }

  URL.revokeObjectURL(object);

  return errors;
}

const BYTES_IN_KB = 1000;

export function convertBytesToReadable(bytes: number): string {
  if (bytes < 0) {
    throw new Error('Number of bytes cannot be negative');
  }

  const units: string[] = ['B', 'KB', 'MB', 'GB'];
  let size: number = bytes;
  let unitIndex = 0;

  while (size >= BYTES_IN_KB && unitIndex < units.length - 1) {
    size /= BYTES_IN_KB;
    unitIndex++;
  }

  return `${Math.floor(size)}${units[unitIndex]}`;
}

export function convertToBytes(value: number, unit: 'B' | 'KB' | 'MB' | 'GB'): number {
  if (value < 0) {
    throw new Error('Value cannot be negative');
  }

  switch (unit) {
    case 'KB':
      return value * BYTES_IN_KB;
    case 'MB':
      return value * BYTES_IN_KB ** 2;
    case 'GB':
      return value * BYTES_IN_KB ** 3;
    default:
      return value;
  }
}

export function convertBytesTo(bytes: number, unit: 'B' | 'KB' | 'MB' | 'GB'): number {
  if (bytes < 0) {
    throw new Error('Number of bytes cannot be negative');
  }

  switch (unit) {
    case 'KB':
      return Math.floor(bytes / BYTES_IN_KB);
    case 'MB':
      return Math.floor(bytes / BYTES_IN_KB ** 2);
    case 'GB':
      return Math.floor(bytes / BYTES_IN_KB ** 3);
    default:
      return bytes;
  }
}

export class ImageFileChooserError extends Error {
  code: ImageFileChooserErrorCode;
  metadata: ImageFileMetadata;

  constructor(message: string, code: ImageFileChooserErrorCode, metadata: ImageFileMetadata) {
    super(message);

    this.code = code;
    this.metadata = metadata;
  }
}
