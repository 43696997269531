import styled from 'styled-components';

export const WorkspaceLogo = styled.img`
  border-radius: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const DefaultWorkspaceLogo = styled.div<{ $width: number; $height: number }>`
  height: ${({ $width }) => $width}px;
  width: ${({ $height }) => $height}px;
  background: #d9d9d9;
  border-radius: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const SelectedWorkspaceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;

export const SelectedWorkspaceDetails = styled.div`
  display: flex;
  align-items: center;
`;
