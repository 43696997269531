import ErrorNoWorkspacePage from 'pages/ErrorNoWorkspacePage/ErrorNoWorkspacePage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { useRouteError } from 'react-router-dom';
import { InterfaceOperationError, isWorkspaceAccessError } from 'utils/httpErrors';
import { FORBIDDEN } from 'utils/httpStatuses';

const ErrorBoundary = () => {
  const error = useRouteError();
  const apiError = InterfaceOperationError.unwrapAPIError(error);

  if (apiError && apiError.statusCode === FORBIDDEN && isWorkspaceAccessError(apiError)) {
    return <ErrorNoWorkspacePage />;
  }

  return (
    <ErrorPage
      title="Unexpected error"
      description="Something unexpected happened. Please refresh the page or contact support."
    />
  );
};

export default ErrorBoundary;
