import { Button } from '@mui/material';
import { routes } from 'utils/routes';
import ErrorPage from '../ErrorPage/ErrorPage';

const Error404Page = () => {
  return (
    <ErrorPage
      title="404 - Page not available"
      description="The page you are looking for might be restricted, moved, had its name changed, or is
          temporarily unavailable."
      content={
        <Button href={routes.welcome()} variant="contained" color="black">
          Go to homepage
        </Button>
      }
    />
  );
};

export default Error404Page;
