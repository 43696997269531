import { IWorkspaceSolution } from 'api/platformApi/platformApi.types';
import { ITableHeadCell } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';

export const headCells = [{ id: 'name', label: 'Name', sortable: false }] satisfies ITableHeadCell<
  keyof IWorkspaceSolution
>[];

export const HEAD_CELLS_SX = {
  width: `${100 / headCells.length}%`,
};
