import { Alert, AlertTitle, Box } from '@mui/material';
import useUploadImage from 'api/platformApi/services/uploadImageService/useUploadImage';
import { useUpdateWorkspace, useWorkspace } from 'api/platformApi/services/workspacesService/hooks';
import LogoField from 'components/LogoField/LogoField';
import { AlertList } from 'components/ui/AlertList/AlertList.styles';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import { requiredValidation } from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { fromTitleToDefaultLogo } from 'pages/Admin/WorkspacesPage/components/CreateWorkspaceDrawer/CreateWorkspaceDrawer.utils';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './EditWorkspace.styles';
import { EditWorkspaceProps, IUpdateWorkspaceForm } from './EditWorkspace.types';

const EditWorkspace: FC<EditWorkspaceProps> = ({ workspaceId = '' }) => {
  const snackbar = useSnackbarContext();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm<IUpdateWorkspaceForm>({
    mode: 'all',
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const uploadImageMutation = useUploadImage();
  const [fileErrors, setFileErrors] = useState<string[]>([]);

  const { data: workspace, refetch } = useWorkspace({ workspaceId });
  const updateWorkspaceMutation = useUpdateWorkspace({
    onSuccess: async () => {
      await refetch();
      snackbar.success('Workspace updated successfully');
    },
    onError: (error) => {
      if (error.scope === 'field' && error.field) {
        setError(error.field, { message: error.message });
      }

      if (error.scope === 'snackbar') {
        reset();
        snackbar.error(error.message);
      }
    },
  });

  const onUpdateWorkspaceSubmit: SubmitHandler<IUpdateWorkspaceForm> = async ({
    description,
    name,
    logoFile,
  }) => {
    let logoUrl: string = fromTitleToDefaultLogo(name);

    if (workspace?.logoUrl) {
      logoUrl = workspace.logoUrl;
    }

    if (logoFile) {
      logoUrl = (await uploadImageMutation.mutateAsync(logoFile)).url;
    }

    await updateWorkspaceMutation.mutateAsync({ workspaceId, description, name, logoUrl });
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  useEffect(() => {
    if (!workspace) return;

    reset({
      name: workspace.name,
      description: workspace.description,
    });
  }, [reset, workspace]);

  return (
    <Styled.Container>
      <Box display="flex" flexDirection="column" gap={2.5}>
        {updateWorkspaceMutation.isError && updateWorkspaceMutation.error.scope === 'alert' && (
          <Alert severity="error">{updateWorkspaceMutation.error.message}</Alert>
        )}

        {fileErrors.length > 0 && (
          <Alert
            severity="error"
            onClose={() => {
              setFileErrors([]);
            }}
          >
            <AlertTitle>Image could not be uploaded</AlertTitle>
            <AlertList>
              {fileErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </AlertList>
          </Alert>
        )}

        <Box
          component="form"
          display="flex"
          gap={3}
          onSubmit={handleSubmit(onUpdateWorkspaceSubmit)}
        >
          <Controller
            control={control}
            name="logoFile"
            render={({ field, formState }) => (
              <LogoField
                alt="Workspace logo"
                defaultSrc={workspace?.logoUrl ?? undefined}
                disabled={formState.isSubmitting}
                loading={formState.isSubmitting}
                onChange={(value) => {
                  setFileErrors([]);
                  field.onChange(value);
                }}
                onError={setFileErrors}
                value={field.value}
              />
            )}
          />

          <RHFMonkTextField
            name="name"
            control={control}
            rules={{ ...requiredValidation }}
            value={workspace?.name}
            label="Workspace name"
            placeholder="Add a name..."
            disabled={isSubmitting}
            required
            fullWidth
          />

          <RHFMonkTextField
            name="description"
            control={control}
            value={workspace?.description}
            label="Description"
            placeholder="Describe the purpose of the workspace..."
            disabled={isSubmitting}
            fullWidth
          />

          <Styled.Button type="submit" variant="outlined" size="large" disabled={isSubmitDisabled}>
            {isSubmitting ? <MFButtonLoader /> : `Update`}
          </Styled.Button>
        </Box>
      </Box>
    </Styled.Container>
  );
};

export default EditWorkspace;
