import { Groups2, LockOutlined } from '@mui/icons-material';
import { FC } from 'react';
import * as Styled from './ProjectVisibility.styles';
import { ProjectVisibilityProps } from './ProjectVisibility.types';

const ProjectVisibility: FC<ProjectVisibilityProps> = ({ visibility }) => {
  return (
    <Styled.Container>
      <Styled.Icon>{visibility === 'private' ? <LockOutlined /> : <Groups2 />}</Styled.Icon>
      <Styled.Text variant="titleSmall" color="text.primary">
        {visibility === 'private' ? (
          <>
            This project is <strong>invite-only</strong>
          </>
        ) : (
          <>
            This project is <strong>visible to all workspace members</strong>
          </>
        )}
      </Styled.Text>
    </Styled.Container>
  );
};

export default ProjectVisibility;
