import { useState } from 'react';
import {
  BOOKMARK_HORIZONTAL_GAP,
  BOOKMARK_HEIGHT,
  BOOKMARK_WIDTH,
  MORE_MENU_VERTICAL_PADDING,
} from '../Bookmarks.consts';
import { BookmarkWithId } from '../Bookmarks.types';
import { calculateDropIndex } from '../Bookmarks.utils';

type DragDirection = 'horizontal' | 'vertical';
interface UseRearrangeBookmarksOptions {
  onChange: (bookmarks: BookmarkWithId[]) => void;
}

interface UseRearrangeBookmarksResult {
  dropIndex: number | null;
  dropIndicatorPosition: 'before' | 'after';
  onDragStart: (bookmarkId: string) => void;
  onDragOver: (e: React.DragEvent<HTMLElement>, direction?: DragDirection) => void;
  onDrop: (e: React.DragEvent<HTMLElement>) => void;
}

export const useRearrangeBookmarks = (
  bookmarks: BookmarkWithId[],
  offsetIndex: number,
  { onChange }: UseRearrangeBookmarksOptions,
): UseRearrangeBookmarksResult => {
  const [dragIndex, setDragIndex] = useState<number | null>(null);
  const [dropIndex, setDropIndex] = useState<number | null>(null);

  const onDragStart = (bookmarkId: string) => {
    setDragIndex(bookmarks.findIndex((bookmark) => bookmark.id === bookmarkId));
  };

  const onDragOver = (e: React.DragEvent<HTMLElement>, direction: DragDirection = 'horizontal') => {
    e.preventDefault();
    const containerRect = e.currentTarget.getBoundingClientRect();
    if (dragIndex === null) return;
    if (direction === 'horizontal') {
      const relativeXPosition = e.clientX - containerRect.left;
      const bookmarkTotalWidth = BOOKMARK_WIDTH + BOOKMARK_HORIZONTAL_GAP;
      const dropIndex = calculateDropIndex(dragIndex, bookmarkTotalWidth, relativeXPosition);
      if (relativeXPosition > containerRect.width || dragIndex === dropIndex) {
        setDropIndex(null);
      } else {
        setDropIndex(dropIndex);
      }
    } else {
      const relativeYPosition = e.clientY - containerRect.top - MORE_MENU_VERTICAL_PADDING;
      const dropIndex = calculateDropIndex(
        dragIndex,
        BOOKMARK_HEIGHT,
        relativeYPosition,
        offsetIndex,
      );
      if (relativeYPosition > containerRect.height || dragIndex === dropIndex) {
        setDropIndex(null);
      } else {
        setDropIndex(dropIndex);
      }
    }
  };

  const onDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    if (dragIndex !== null && dropIndex !== null) {
      const newBookmarks = [...bookmarks];
      const draggedItem = newBookmarks.splice(dragIndex, 1)[0];
      newBookmarks.splice(dropIndex, 0, draggedItem);
      onChange(newBookmarks);
      setDropIndex(null);
      setDragIndex(null);
    }
  };

  const isDropBeforeDrag = dropIndex !== null && dragIndex !== null && dropIndex < dragIndex;
  const dropIndicatorPosition = isDropBeforeDrag ? 'before' : 'after';

  return {
    dropIndex,
    dropIndicatorPosition,
    onDragStart,
    onDragOver,
    onDrop,
  };
};
