import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const Avatar = styled.div`
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-radius: 50%;
  font-size: 14px;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.common.black};
`;
