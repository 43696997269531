import { Skeleton } from '@mui/material';
import * as Styled from './ToolCard.styles';
import { ToolCardProps } from './ToolCard.types';

const ToolCard = ({ name, logoUrl, description, onClick, isLoading }: ToolCardProps) => {
  return (
    <Styled.Container onClick={onClick} $isLoading={isLoading}>
      {isLoading ? (
        <>
          <Skeleton variant="rounded" width={48} height={48} sx={{ marginBottom: '8px' }} />
          <Skeleton variant="rounded" width="50%" height={24} />
          <Skeleton variant="rounded" width="100%" height={12} />
          <Skeleton variant="rounded" width="80%" height={12} />
        </>
      ) : (
        <>
          <Styled.Icon src={logoUrl} alt={name} />
          <Styled.Name>{name}</Styled.Name>
          <Styled.Description>{description}</Styled.Description>
        </>
      )}
    </Styled.Container>
  );
};

export default ToolCard;
