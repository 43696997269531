import { Button as MuiButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.surface.level1};
  border-radius: ${({ theme }) => theme.spacing(2)};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.02);
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const Button = styled(MuiButton)`
  min-width: 100px;
  margin-top: 22px;
`;
