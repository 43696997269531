import { Menu as MuiMenu } from '@mui/material';
import styled from 'styled-components';

export const OptionsMenu = styled(MuiMenu)`
  margin-top: ${({ theme }) => theme.spacing(0.5)};

  & .MuiPaper-root {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
    min-width: 139px;
  }
`;
