import { FC } from 'react';
import * as Styled from './AppDrawerFooter.styles';
import { AppDrawerFooterProps } from './AppDrawerFooter.types';

const AppDrawerFooter: FC<AppDrawerFooterProps> = (props) => {
  return (
    <Styled.AppDrawerFooter className={props.className}>{props.children}</Styled.AppDrawerFooter>
  );
};

export default AppDrawerFooter;
