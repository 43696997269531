import platformApi from 'api/platformApi/platformApi';
import { AxiosRequestConfig } from 'axios';

export const updateWorkspaceProjectUsers = (
  workspaceId: string,
  projectId: string,
  userIds: string[],
  axiosConfig?: AxiosRequestConfig,
) => {
  if (!workspaceId) throw new Error('workspaceId is required');
  if (!projectId) throw new Error('projectId is required');
  return platformApi.put(
    `/workspaces/${workspaceId}/projects/${projectId}/users`,
    { userIds },
    axiosConfig,
  );
};
