import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import * as Styled from './ConfirmDeleteDialog.styles';
import { ConfirmDeleteDialogProps } from './ConfirmDeleteDialog.types';

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
}) => {
  return (
    <Styled.Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText color="text.primary">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="black">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Remove
        </Button>
      </DialogActions>
    </Styled.Dialog>
  );
};

export default ConfirmDeleteDialog;
