import PlatformApiSdk from '@monksflow/platform-api-sdk';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Snackbar from 'components/ui/Snackbar/Snackbar';
import { platformSdkConfig } from 'config/platform-sdk';
import { reactQueryConfig } from 'config/react-query';
import { reactRouterConfig } from 'config/react-router';
import { sentryConfig } from 'config/sentry';
import AgreementsProvider from 'contexts/agreements/AgreementsProvider';
import AuthProvider from 'contexts/auth/AuthProvider';
import SnackbarProvider from 'contexts/snackbar/SnackbarProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { platformTheme } from 'utils/theme';
import './index.css';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('No element with id "root" found.');
}

/*
  ========================================
  Third-party libs configuration
  ========================================
*/

/*
  Platform SDK
  ========================================
*/
PlatformApiSdk.init(platformSdkConfig);

/*
  Sentry
  ========================================
*/
if (import.meta.env.SENTRY_ENABLED) {
  Sentry.init(sentryConfig);
}

/*
  React-query
  ========================================
*/
const queryClient = new QueryClient(reactQueryConfig);

/*
  React-router
  ========================================
*/
const createRouter = (() => {
  if (import.meta.env.SENTRY_ENABLED) {
    /**
     * Use Sentry tracing together with react-router.
     *
     * @see https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v6
     */
    return Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  }

  return createBrowserRouter;
})();
const router = createRouter(reactRouterConfig);

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <ThemeProvider theme={platformTheme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AgreementsProvider>
            <SnackbarProvider>
              <RouterProvider router={router} />
              <Snackbar />
            </SnackbarProvider>
          </AgreementsProvider>
        </AuthProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
