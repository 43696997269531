export const routes = {
  welcome: () => '/',
  legalInformation: () => '/legal-information',

  /*
    ========================================
    Auth routes
    ========================================
  */
  login: (organizationSlug?: string) =>
    organizationSlug ? `/${organizationSlug}/login` : '/login',

  /*
    ========================================
    Workspace routes
    ========================================
  */
  workspace: (workspaceSlug: string) => `/${workspaceSlug}`,

  /*
    Dashboard route
    ========================================
  */
  dashboard: (workspaceSlug: string) => routes.workspace(workspaceSlug),

  /*
    Projects routes
    ========================================
  */
  projects: (workspaceSlug: string) => routes.dashboard(workspaceSlug) + '/projects',
  viewProject: (workspaceSlug: string, projectId: string | number) =>
    routes.projects(workspaceSlug) + `/${projectId}`,

  /*
    ========================================
    Admin routes
    ========================================
  */
  admin: () => '/admin',
  adminUsers: () => routes.admin() + '/users',
  adminWorkspaces: () => routes.admin() + '/workspaces',
  adminWorkspace: (workspaceId: string) => routes.adminWorkspaces() + `/${workspaceId}`,

  /*
    ========================================
    Organization management routes
    ========================================
  */
  organizationManagement: () => '/organization-management',
  viewOrganization: (organizationId: string) =>
    routes.organizationManagement() + `/${organizationId}`,
};
