import platformApi from 'api/platformApi/platformApi';
import {
  IResendOrganizationAdminInvitationResponse,
  IResendOrganizationAdminInvitationServiceParamsPath,
} from 'api/platformApi/platformApi.types';

export const resendOrganizationAdminInvitationService = async ({
  params: {
    path: { organizationId, invitationId },
  },
}: ServiceFnConfig<IResendOrganizationAdminInvitationServiceParamsPath>) => {
  const response = await platformApi.post<IResendOrganizationAdminInvitationResponse>(
    `/organizations/${organizationId}/invitations/${invitationId}/actions/resend`,
  );

  return response.data;
};
