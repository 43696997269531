import { AddOutlined } from '@mui/icons-material';
import { Button, Table, TableCell, Tooltip, Typography } from '@mui/material';
import useOrganizationAuthMethod from 'api/platformApi/services/organizationAuthMethodService/useOrganizationAuthMethod';
import useOrganizationAuthMethods from 'api/platformApi/services/organizationAuthMethodsService/useOrganizationAuthMethods';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import { ITableHeadCell } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import TableRowActionsMenu from 'components/ui/TableRowActionsMenu/TableRowActionsMenu';
import { FC, useState } from 'react';
import CreateAuthMethodDrawer from '../CreateAuthMethodDrawer/CreateAuthMethodDrawer';
import EditAuthMethodDrawer from '../EditAuthMethodDrawer/EditAuthMethodDrawer';
import RemoveAuthMethodDialog from '../RemoveAuthMethodDialog/RemoveAuthMethodDialog';
import * as Styled from './OrganizationAuthMethods.styles';
import { OrganizationAuthMethodsProps } from './OrganizationAuthMethods.types';

const headCells: ITableHeadCell<string>[] = [
  { id: 'methodName', label: 'Name' },
  { id: 'issuerUrl', label: 'Issuer URL' },
  { id: 'clientId', label: 'Client ID' },
];

const OrganizationAuthMethods: FC<OrganizationAuthMethodsProps> = ({ organizationId }) => {
  const [isCreateAuthMethodDrawerOpen, setIsCreateAuthMethodDrawerOpen] = useState(false);
  const [editingMethodId, setEditingMethodId] = useState<string>();
  const [removingMethodId, setRemovingMethodId] = useState<string>();

  const methodsQuery = useOrganizationAuthMethods(organizationId);
  const methods = methodsQuery.data?.data ?? [];

  const editingMethodQuery = useOrganizationAuthMethod({
    organizationId,
    methodId: editingMethodId ?? '',
  });
  const editingMethod = editingMethodQuery.data;

  return (
    <>
      <Styled.CustomMFTableContainer>
        <Styled.TitleHeader>
          <Typography component="h2" variant="titleMedium">
            Authentication Methods
          </Typography>

          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              setIsCreateAuthMethodDrawerOpen(true);
            }}
            startIcon={<AddOutlined />}
          >
            Add new
          </Button>

          <CreateAuthMethodDrawer
            open={isCreateAuthMethodDrawerOpen}
            organizationId={organizationId}
            onClose={() => {
              setIsCreateAuthMethodDrawerOpen(false);
            }}
          />
        </Styled.TitleHeader>
        <Table>
          <MFTableHead>
            <MFTableHeadRow>
              {headCells.map((headCell) => (
                <MFTableHeadCell
                  variant="head"
                  key={headCell.id}
                  sx={{ width: `${100 / headCells.length}%` }}
                >
                  {headCell.label}
                </MFTableHeadCell>
              ))}
              <MFTableHeadCell variant="head" sx={{ width: 20 }} />
            </MFTableHeadRow>
          </MFTableHead>

          <MFTableBody>
            {methods.map((method) => (
              <MFTableBodyRow key={method.id}>
                <TableCell>
                  <Typography variant="bodyMedium">{method.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyMedium">{method.issuerUrl}</Typography>
                </TableCell>
                <TableCell>
                  <Tooltip title={method.clientId}>
                    <Styled.ClientId variant="bodyMedium">{method.clientId}</Styled.ClientId>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <TableRowActionsMenu
                    actions={[
                      {
                        label: 'Edit',
                        onClick: () => {
                          setEditingMethodId(method.id);
                        },
                      },
                      {
                        label: 'Remove',
                        onClick: () => {
                          setRemovingMethodId(method.id);
                        },
                      },
                    ]}
                  />
                </TableCell>
              </MFTableBodyRow>
            ))}
          </MFTableBody>
        </Table>
      </Styled.CustomMFTableContainer>

      {editingMethod?.id && (
        <EditAuthMethodDrawer
          open
          onClose={() => {
            setEditingMethodId(undefined);
          }}
          organizationId={organizationId}
          method={editingMethod}
        />
      )}

      {removingMethodId && (
        <RemoveAuthMethodDialog
          open
          organizationId={organizationId}
          methodId={removingMethodId}
          onClose={() => {
            setRemovingMethodId(undefined);
          }}
        />
      )}
    </>
  );
};

export default OrganizationAuthMethods;
