import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Button, MenuItem } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { sortLabels, sortOptions } from './SortMenu.consts';
import * as Styled from './SortMenu.styles';
import { SortConfig, SortMenuProps } from './SortMenu.types';

const SortMenu: FC<SortMenuProps> = ({ projectsSort, onSortChange }) => {
  const [sortButtonElement, setSortButtonElement] = useState<null | HTMLButtonElement>(null);
  const isSortMenuOpen = Boolean(sortButtonElement);

  const handleSortMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setSortButtonElement(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortButtonElement(null);
  };

  const handleSort = (config: SortConfig) => {
    onSortChange(config);
    handleSortMenuClose();
  };

  return (
    <>
      <Button
        onClick={handleSortMenuOpen}
        variant="text"
        color="black"
        endIcon={<ArrowDropDownOutlinedIcon />}
      >
        {sortLabels[projectsSort.property][projectsSort.direction]}
      </Button>

      <Styled.SortMenu
        open={isSortMenuOpen}
        onClose={handleSortMenuClose}
        anchorEl={sortButtonElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {sortOptions.map(({ direction, property }) => {
          const label = sortLabels[property][direction];

          return (
            <MenuItem
              onClick={() => {
                handleSort({ property, direction });
              }}
              key={label}
            >
              {label}
            </MenuItem>
          );
        })}
      </Styled.SortMenu>
    </>
  );
};

export default SortMenu;
