import * as Styled from './SideNav.styles';
import { SideNavProps } from './SideNav.types';
import SideNavItem from './components/SideNavItem/SideNavItem';
import SideNavItemWithSubmenu from './components/SideNavItemWithSubmenu/SideNavItemWithSubmenu';

const SideNav = ({ navItems }: SideNavProps) => {
  return (
    <Styled.Container>
      <Styled.NegativeRoundedDetail />
      <Styled.Nav>
        {navItems.map((item) => {
          if ('route' in item) {
            return (
              <SideNavItem
                key={item.label}
                label={item.label}
                icon={item.icon}
                route={item.route}
              />
            );
          }
          if ('submenu' in item) {
            return (
              <SideNavItemWithSubmenu
                key={item.label}
                label={item.label}
                icon={item.icon}
                renderMenuItems={item.submenu.renderItems}
              />
            );
          }
          return null;
        })}
      </Styled.Nav>
    </Styled.Container>
  );
};

export default SideNav;
