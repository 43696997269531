import { SvgIcon } from '@mui/material';

const AdminWorkspacesIcon = () => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <mask id="mask0_1427_4226" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1427_4226)">
          <path
            d="M13.25 8.99998V3.5H20.5V8.99998H13.25ZM3.5 12.5V3.5H10.75V12.5H3.5ZM13.25 20.5V11.5H20.5V20.5H13.25ZM3.5 20.5V15H10.75V20.5H3.5ZM4.99997 11H9.25V4.99998H4.99997V11ZM14.75 19H19V13H14.75V19ZM14.75 7.5H19V4.99998H14.75V7.5ZM4.99997 19H9.25V16.5H4.99997V19Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default AdminWorkspacesIcon;
