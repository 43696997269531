import { Box, Button, Table, TableCell, Typography } from '@mui/material';
import { useWorkspaceProjects } from 'api/platformApi/services/workspaceProjectsService/hooks';
import VisibilityAvatar from 'components/VisibilityAvatar/VisibilityAvatar';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatRelativeDate } from 'utils/dates';
import { routes } from 'utils/routes';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import { headCells } from './DashboardRecentProjects.consts';
import * as Styled from './DashboardRecentProjects.styles';

const DashboardRecentProjects = () => {
  const activeWorkspace = useActiveWorkspace();
  const activeWorkspaceId = activeWorkspace?.id;
  const activeWorkspaceSlug = activeWorkspace?.slug ?? '';

  const navigate = useNavigate();

  const { data } = useWorkspaceProjects({
    workspaceId: activeWorkspaceId,
    sortBy: 'createdAt',
    sortDirection: 'desc',
    limit: 6,
  });

  const recentProjects = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="titleMedium">Recent projects</Typography>

        <Button
          onClick={() => {
            navigate(routes.projects(activeWorkspaceSlug));
          }}
          variant="contained"
          color="black"
          size="small"
        >
          All projects
        </Button>
      </Box>

      <MFTableContainer>
        <Table>
          <MFTableHead>
            <MFTableHeadRow>
              {headCells.map((headCell) => (
                <MFTableHeadCell variant="head" key={headCell.id}>
                  {headCell.label}
                </MFTableHeadCell>
              ))}
            </MFTableHeadRow>
          </MFTableHead>

          <MFTableBody>
            {recentProjects.map((project) => (
              <Styled.RecentProjectBodyRow
                onClick={() => {
                  navigate(routes.viewProject(activeWorkspaceSlug, project.id));
                }}
                key={project.id}
              >
                <TableCell>
                  <Typography
                    color="text.primary"
                    display="inline-block"
                    variant="bodyMedium"
                    sx={{ textDecoration: 'none' }}
                    width={1}
                  >
                    {project.name}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Styled.UserAvatar>
                      {project.owner.name.charAt(0).toUpperCase()}
                    </Styled.UserAvatar>
                    <Typography variant="bodyMedium">{project.owner.name}</Typography>
                  </Box>
                </TableCell>

                <TableCell>
                  <Typography
                    color="text.primary"
                    display="inline-block"
                    variant="bodyMedium"
                    sx={{ textDecoration: 'none' }}
                    width={1}
                  >
                    {formatRelativeDate(project.createdAt)}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Box display="flex" alignItems="center" gap={1}>
                    <VisibilityAvatar visibility={project.visibility} />
                    <Typography variant="bodyMedium">
                      {project.visibility === 'private' ? `Invited members` : `Workspace`}
                    </Typography>
                  </Box>
                </TableCell>
              </Styled.RecentProjectBodyRow>
            ))}
          </MFTableBody>
        </Table>
      </MFTableContainer>
    </Box>
  );
};

export default DashboardRecentProjects;
