import { InfiniteQueryObserverBaseResult } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';

interface InfiniteScrollRefArgs {
  isLoading: InfiniteQueryObserverBaseResult['isLoading'];
  hasNextPage: InfiniteQueryObserverBaseResult['hasNextPage'];
  isFetching: InfiniteQueryObserverBaseResult['isFetching'];
  fetchNextPage: InfiniteQueryObserverBaseResult['fetchNextPage'];
}

function useInfiniteScrollRef({
  isLoading,
  hasNextPage,
  isFetching,
  fetchNextPage,
}: InfiniteScrollRefArgs) {
  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          void fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading],
  );

  return lastElementRef;
}

export default useInfiniteScrollRef;
