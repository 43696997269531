import { TableSortLabel } from '@mui/material';
import { FC } from 'react';
import * as Styled from './MFTableHeadCell.styles';
import { MFTableHeadCellSortableProps } from './MFTableHeadCellSortable.types';

const MFTableHeadCellSortable: FC<MFTableHeadCellSortableProps> = ({
  active,
  children,
  direction,
  onSort,
  ...props
}) => {
  return (
    <Styled.TableCell {...props} variant="head">
      <TableSortLabel active={active} direction={direction} onClick={onSort} hideSortIcon>
        {children}
      </TableSortLabel>
    </Styled.TableCell>
  );
};

export default MFTableHeadCellSortable;
