import ConfirmDialog from 'components/ui/ConfirmDialog/ConfirmDialog';
import { RevokeInvitationDialogProps } from './RevokeInvitationDialog.types';

const RevokeInvitationDialog = ({ open, onCancel, onRevoke }: RevokeInvitationDialogProps) => {
  return (
    <ConfirmDialog
      content="The user will not be able to access the workspace if they revoke the invitation. Would you like to proceed?"
      id="revoke-invitation-dialog"
      label="Revoke"
      onCancel={onCancel}
      onConfirm={onRevoke}
      open={open}
      title="Revoke user invitation"
    />
  );
};

export default RevokeInvitationDialog;
