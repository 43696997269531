import { useQuery } from '@tanstack/react-query';
import { IWorkspaceProjectUsersServiceResponse } from 'api/platformApi/platformApi.types';
import { getWorkspaceProjectUsers } from './workspaceProjectUsersService';

export const getWorkspaceProjectUsersQueryKey = (workspaceId: string, projectId: string) => [
  'workspaceProjectUsers',
  { workspaceId, projectId },
];

const useWorkspaceProjectUsers = (workspaceId: string, projectId: string) => {
  return useQuery<IWorkspaceProjectUsersServiceResponse>({
    queryKey: getWorkspaceProjectUsersQueryKey(workspaceId, projectId),
    queryFn: () => getWorkspaceProjectUsers(workspaceId, projectId).then((r) => r.data),
    enabled: !!workspaceId && !!projectId,
  });
};

export default useWorkspaceProjectUsers;
