import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { IconButton } from '@mui/material';
import ImageFilePreview from 'components/ImageFilePreview/ImageFilePreview';
import styled from 'styled-components';

export const LogoContainer = styled.div`
  border-radius: 25%;
  overflow: hidden;
  position: relative;
  height: 64px;
  width: 64px;
`;

export const LogoPlaceholder = styled.div`
  border-radius: 25%;
  position: absolute;
  outline: 2px dashed ${({ theme }) => theme.palette.grey[600]};
  outline-offset: -4px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

LogoContainer.displayName = 'LogoContainer';

export const LogoImg = styled.img`
  position: relative;
  aspect-ratio: 1;
  display: block;
  max-width: 100%;
`;
LogoImg.displayName = 'LogoImg';

export const LogoPreview = styled(ImageFilePreview)`
  position: relative;
  aspect-ratio: 1;
  display: block;
  max-width: 100%;
`;
LogoPreview.displayName = 'LogoPreview';

export const LogoEmptyIcon = styled(ImageOutlinedIcon)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.surface.level4};
  fill: ${({ theme }) => theme.palette.text.secondary};
  font-size: 4rem;
  display: block;
  padding: ${({ theme }) => theme.spacing(1)};
`;
LogoEmptyIcon.displayName = 'LogoEmptyIcon';

export const EditLogoContainer = styled.div`
  position: absolute;
  bottom: -${({ theme }) => theme.spacing(1)};
  right: -${({ theme }) => theme.spacing(1)};
`;
EditLogoContainer.displayName = 'EditLogoContainer';

export const EditLogoButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(0.5)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;
EditLogoButton.displayName = 'EditLogoButton';

export const RemoveLogoButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(0.5)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.error.dark};
  }
`;
RemoveLogoButton.displayName = 'RemoveLogoButton';
