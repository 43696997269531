import { IInvitation } from 'api/platformApi/platformApi.types';
import { useState } from 'react';

export enum InvitationActionType {
  REVOKE = 'REVOKE',
  EDIT = 'EDIT',
}

type InvitationActionState =
  | { type: InvitationActionType; invitation: IInvitation }
  | { type: null; invitation: null };

export const useInvitationActionManager = () => {
  const [state, setState] = useState<InvitationActionState>({ type: null, invitation: null });

  const startAction = (actionType: InvitationActionType, invitation: IInvitation) => {
    setState({ type: actionType, invitation });
  };

  const endAction = () => {
    setState({ type: null, invitation: null });
  };

  const isActionActive = (actionType: InvitationActionType) => state.type === actionType;

  return {
    state,
    startAction,
    endAction,
    isActionActive,
    selectedInvitation: state.invitation,
  };
};
