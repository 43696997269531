import platformApi from 'api/platformApi/platformApi';
import {
  IAgreementServiceParamsPath,
  IAgreementServiceResponse,
} from 'api/platformApi/platformApi.types';

export const getAgreement = async ({
  params: {
    path: { agreementId },
  },
}: ServiceFnConfig<IAgreementServiceParamsPath>) => {
  const response = await platformApi.get<IAgreementServiceResponse>(`/agreements/${agreementId}`);

  return response.data;
};
