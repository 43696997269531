import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, MenuItem } from '@mui/material';
import useAuthContext from 'contexts/auth/useAuthContext';
import { FC, MouseEvent, MouseEventHandler, useState } from 'react';
import { PERMISSIONS, validatePermissions } from 'utils/permissions';
import * as Styled from './UserOptions.styles';
import { MenuOption, UserOptionsProps } from './UserOptions.types';

const UserOptions: FC<UserOptionsProps> = ({ onClickEdit }) => {
  const [optionsButtonElement, setOptionsButtonElement] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(optionsButtonElement);

  const { user } = useAuthContext();
  const canManageUsers = validatePermissions([PERMISSIONS.PlatformManageUsers], user?.permissions);

  const handleOptionsOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setOptionsButtonElement(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setOptionsButtonElement(null);
  };

  const menuOptions: MenuOption[] = [];

  const handleClickEdit: MouseEventHandler<HTMLLIElement> = (event) => {
    onClickEdit(event);
    handleOptionsClose();
  };

  if (canManageUsers) {
    menuOptions.push({
      label: 'Edit',
      handler: handleClickEdit,
    });
  }

  return menuOptions.length > 0 ? (
    <>
      <IconButton size="medium" onClick={handleOptionsOpen}>
        <MoreHorizIcon />
      </IconButton>

      <Styled.OptionsMenu
        open={isMenuOpen}
        onClose={handleOptionsClose}
        anchorEl={optionsButtonElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuOptions.map((menuOption) => (
          <MenuItem onClick={menuOption.handler} key={menuOption.label}>
            {menuOption.label}
          </MenuItem>
        ))}
      </Styled.OptionsMenu>
    </>
  ) : null;
};

export default UserOptions;
