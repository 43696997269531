import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  IPlatformApiGenericError,
  IResendOrganizationAdminInvitationResponse,
  IResendOrganizationAdminInvitationServiceParamsPath,
} from 'api/platformApi/platformApi.types';
import { AxiosError } from 'axios';
import { resendOrganizationAdminInvitationService } from './resendOrganizationAdminInvitationService';

type UseResendOrganizationAdminInvitationParams = Pick<
  IResendOrganizationAdminInvitationServiceParamsPath,
  'organizationId'
>;

const useResendOrganizationAdminInvitation = (
  params: UseResendOrganizationAdminInvitationParams,
  mutationOptions?: UseMutationOptions<
    IResendOrganizationAdminInvitationResponse,
    AxiosError<IPlatformApiGenericError>,
    Pick<IResendOrganizationAdminInvitationServiceParamsPath, 'invitationId'>
  >,
) => {
  return useMutation({
    ...mutationOptions,
    mutationFn(variables) {
      const organizationId = params.organizationId;
      const invitationId = variables.invitationId;

      return resendOrganizationAdminInvitationService({
        params: {
          path: {
            organizationId,
            invitationId,
          },
        },
      });
    },
  });
};

export default useResendOrganizationAdminInvitation;
