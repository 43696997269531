import AdminHeader from 'components/AdminHeader/AdminHeader';
import useAuthContext from 'contexts/auth/useAuthContext';
import useResetScrollPosition from 'hooks/useResetScrollPosition';
import { Outlet } from 'react-router-dom';
import { getUserMenuActions, INSTANCE_ADMIN_SETTINGS_ACTION_LABEL } from 'utils/userMenuActions';
import * as Styled from './InstanceAdminLayout.styles';

const InstanceAdminLayout = () => {
  const { user, logout } = useAuthContext();
  const scrollableContainerRef = useResetScrollPosition();
  const userActions = getUserMenuActions(user);
  const instanceAdminUserActions = userActions.filter(
    (action) => action.label !== INSTANCE_ADMIN_SETTINGS_ACTION_LABEL,
  );

  return (
    <main>
      <AdminHeader
        title="organization management"
        user={user}
        onSignOutClick={logout}
        userActions={instanceAdminUserActions}
      />

      <Styled.Container>
        <Styled.Content ref={scrollableContainerRef}>
          <Outlet />
        </Styled.Content>
      </Styled.Container>
    </main>
  );
};

export default InstanceAdminLayout;
