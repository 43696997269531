import { Box } from '@mui/material';
import { useState } from 'react';
import * as Styled from './SelectMultiple.styles';
import { OptionBase, SelectMultipleProps } from './SelectMultiple.types';
import { getNoOptionsText } from './SelectMultiple.utils';

const SelectMultiple = <T extends OptionBase>({
  label,
  options,
  renderOption,
  renderInput,
  value: selectedOptions,
  onChange: onChangeSelectedOptions,
  onSearchChange,
  renderSelectedOption,
  onRemoveSelectedOption,
  minSearchLength = 3,
  disabled,
  sx,
}: SelectMultipleProps<T>) => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <Box sx={sx}>
      {label && (
        <Styled.Label variant="titleMedium" color="text.primary">
          {label}
        </Styled.Label>
      )}
      <Styled.SelectContainer>
        <Styled.Autocomplete
          options={options}
          value={selectedOptions}
          onChange={(_, autocompleteValue, reason) => {
            if (reason !== 'removeOption') {
              onChangeSelectedOptions(autocompleteValue);
            }
          }}
          renderInput={renderInput}
          inputValue={searchValue}
          onInputChange={(_, inputValue) => {
            setSearchValue(inputValue);
            onSearchChange && onSearchChange(inputValue);
          }}
          renderTags={() => null}
          getOptionLabel={(option) => option.name}
          renderOption={renderOption}
          multiple
          disableClearable
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText={
            <Styled.NoOptionsText component="div" variant="bodyMedium">
              {getNoOptionsText(searchValue, minSearchLength)}
            </Styled.NoOptionsText>
          }
          disabled={disabled}
          PopperComponent={(props) => <Styled.AutocompletePopper {...props} />}
        />

        {selectedOptions.length > 0 && (
          <Styled.SelectedOptionsContainer $disabled={disabled}>
            {selectedOptions.map((selectedOption) =>
              renderSelectedOption(selectedOption, onRemoveSelectedOption),
            )}
          </Styled.SelectedOptionsContainer>
        )}
      </Styled.SelectContainer>
    </Box>
  );
};

export default SelectMultiple;
