import platformApi from 'api/platformApi/platformApi';
import {
  IWorkspaceUsersServiceParamsPath,
  IWorkspaceUsersServiceParamsQuery,
  IWorkspaceUsersServiceResponse,
} from 'api/platformApi/platformApi.types';

export const getWorkspaceUsers = async ({
  params: {
    path: { workspaceId },
    query: { name },
  },
}: ServiceFnConfig<IWorkspaceUsersServiceParamsPath, IWorkspaceUsersServiceParamsQuery>) => {
  const networkResponse = await platformApi.get<IWorkspaceUsersServiceResponse>(
    `/workspaces/${workspaceId}/users`,
    { params: { name } },
  );
  const apiResponse = networkResponse.data;
  const apiData = apiResponse.data;

  return apiData;
};
