import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  ICreateOrganizationAuthMethodRequestBody,
  ICreateOrganizationAuthMethodResponse,
  IPlatformApiGenericError,
} from 'api/platformApi/platformApi.types';
import { AxiosError } from 'axios';
import { createOrganizationAuthMethod } from './createOrganizationAuthMethodService';

function useCreateOrganizationAuthMethod(
  organizationId: string,
  mutationOptions?: UseMutationOptions<
    ICreateOrganizationAuthMethodResponse,
    AxiosError<IPlatformApiGenericError>,
    ICreateOrganizationAuthMethodRequestBody
  >,
) {
  return useMutation({
    ...mutationOptions,
    mutationKey: ['createOrganizationAuthMethod', { organizationId }],
    mutationFn: (methodData: ICreateOrganizationAuthMethodRequestBody) => {
      return createOrganizationAuthMethod(organizationId, methodData);
    },
  });
}

export default useCreateOrganizationAuthMethod;
