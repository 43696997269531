import { FC, useEffect, useState } from 'react';
import { ImageFilePreviewProps } from './ImageFilePreview.types';

const ImageFilePreview: FC<ImageFilePreviewProps> = ({ alt, file, ...props }) => {
  const [src, set] = useState<string>();
  const [reader] = useState(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      set(reader.result as string);
    };
    return reader;
  });

  useEffect(() => {
    if (reader.readyState === FileReader.LOADING) return;
    reader.readAsDataURL(file);
  }, [file, reader]);

  return <img {...props} alt={alt} src={src}></img>;
};

export default ImageFilePreview;
