import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Snackbar as MuiSnackbar } from '@mui/material';
import { ISnackbarState } from 'contexts/snackbar/SnackbarContext.types';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC } from 'react';
import * as Styled from './Snackbar.styles';

const Snackbar: FC = () => {
  const snackbarContext = useSnackbarContext();

  const getSeverityIcon = (severity: ISnackbarState['severity']) => {
    switch (severity) {
      case 'info':
        return <InfoIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'success':
        return <CheckCircleIcon />;
      default:
        return false;
    }
  };

  return (
    <MuiSnackbar
      open={snackbarContext.isOpen}
      autoHideDuration={6000}
      onClose={snackbarContext.close}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ minWidth: '320px' }}
    >
      <Styled.Alert
        onClose={snackbarContext.close}
        severity={snackbarContext.severity}
        variant="filled"
        icon={getSeverityIcon(snackbarContext.severity)}
      >
        {snackbarContext.message}
      </Styled.Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
