import { useQuery } from '@tanstack/react-query';
import { IAgreementServiceParamsPath } from 'api/platformApi/platformApi.types';
import { getAgreement } from './agreementService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseAgreementParams extends IAgreementServiceParamsPath {}

export const AGREEMENT_QUERY_KEY_PREFIX = 'agreement';

export const getAgreementQueryKey = (params: UseAgreementParams) => [
  AGREEMENT_QUERY_KEY_PREFIX,
  params.agreementId,
];

const useAgreement = (params: UseAgreementParams) => {
  return useQuery({
    queryKey: getAgreementQueryKey(params),
    queryFn: () =>
      getAgreement({
        params: {
          path: {
            agreementId: params.agreementId,
          },
        },
      }),
  });
};

export default useAgreement;
