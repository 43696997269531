import animatedLogoUrl from 'assets/img/animated-logo.gif';
import * as Styled from './Loader.styles';

interface LoaderProps {
  show?: boolean;
  children?: React.ReactNode;
}

const Loader = ({ show = true, children }: LoaderProps) => {
  return (
    <Styled.Container>
      <Styled.AnimatedLogo src={animatedLogoUrl} $show={show} />
      <Styled.Children $show={!show}>{children}</Styled.Children>
    </Styled.Container>
  );
};

export default Loader;
