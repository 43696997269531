import { IPlatformApiGenericError } from 'api/platformApi/platformApi.types';
import { ErrorSchema, InterfaceOperationError, isWorkspaceNameExistsError } from 'utils/httpErrors';
import { CONFLICT } from 'utils/httpStatuses';
import {
  ICreateWorkspaceServiceRequestBody,
  IUpdateWorkspaceServiceRequestBody,
} from '../../platformApi.types';

type CreateWorkspaceOperationErrorSchema = ErrorSchema<typeof CONFLICT>;

export class CreateWorkspaceOperationError extends InterfaceOperationError<
  Omit<ICreateWorkspaceServiceRequestBody, 'description' | 'logoUrl'>,
  CreateWorkspaceOperationErrorSchema
> {
  errorSchema: CreateWorkspaceOperationErrorSchema = {
    [CONFLICT]: (apiError) => {
      if (isWorkspaceNameExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A workspace already exists with this name.';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError, 'An error occurred and the workspace could not be created.');
    this.scopeAPIError();
  }
}

type UpdateWorkspaceOperationErrorSchema = ErrorSchema<typeof CONFLICT>;

export class UpdateWorkspaceOperationError extends InterfaceOperationError<
  Omit<IUpdateWorkspaceServiceRequestBody, 'logoUrl'>,
  UpdateWorkspaceOperationErrorSchema
> {
  errorSchema: UpdateWorkspaceOperationErrorSchema = {
    [CONFLICT]: (apiError) => {
      if (isWorkspaceNameExistsError(apiError)) {
        this.scope = 'field';
        this.field = 'name';
        this.message = 'A workspace already exists with this name.';
      }
    },
  };

  constructor(apiError: IPlatformApiGenericError) {
    super(apiError, 'An error occurred and the workspace could not be updated.', 'snackbar');
    this.scopeAPIError();
  }
}
