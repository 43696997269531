import { useQuery } from '@tanstack/react-query';
import { getInvitation } from './invitationService';

const useInvitation = (invitationId: string) => {
  return useQuery({
    queryKey: ['invitation', { invitationId }],
    queryFn: () => getInvitation(invitationId).then((r) => r.data),
  });
};

export default useInvitation;
