import { Button } from '@mui/material';
import { routes } from 'utils/routes';
import ErrorPage from '../ErrorPage/ErrorPage';

const ComingSoonPage = () => {
  return (
    <ErrorPage
      title="Coming soon..."
      description="The feature you are looking for is coming soon."
      content={
        <Button href={routes.welcome()} variant="contained" color="black">
          Go to homepage
        </Button>
      }
    />
  );
};

export default ComingSoonPage;
