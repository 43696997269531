import styled from 'styled-components';

export const PaginationContainer = styled.div`
  max-width: fit-content;
  margin: ${({ theme }) => theme.spacing(4, 'auto', 0)};
  padding: ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => theme.palette.surface.level1};
  border-radius: ${({ theme }) => theme.spacing(2)};
  box-shadow: ${({ theme }) => theme.monksShadows.level1};
`;
