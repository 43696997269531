import ErrorIcon from '@mui/icons-material/Error';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useQueryClient } from '@tanstack/react-query';
import useCreateInvitation from 'api/platformApi/services/createInvitationService/useCreateInvitation';
import { getInvitationsQueryKey } from 'api/platformApi/services/invitationsService/useInvitations';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerFooter from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import SelectWorkspaces from 'components/SelectWorkspaces/SelectWorkspaces';
import CheckboxField from 'components/ui/CheckboxField/CheckboxField';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import RHFPermissionsField from 'components/ui/rhf-components/RHFPermissionsField/RHFPermissionsField';
import {
  emailValidation,
  requiredValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './InviteUserDrawer.styles';
import { IInviteUserForm, InviteUserDrawerProps } from './InviteUserDrawer.types';

const InviteUserDrawer: FC<InviteUserDrawerProps> = (props) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
    watch,
  } = useForm<IInviteUserForm>({
    mode: 'all',
    defaultValues: {
      email: '',
      isAdmin: false,
      permissions: {},
      workspaces: [],
    },
  });

  const handleClose = () => {
    reset();
    props.onClose();
  };

  const inviteUserMutation = useCreateInvitation({
    onError(error) {
      if (error.scope === 'field' && error.field) {
        setError(error.field, { message: error.message });
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getInvitationsQueryKey(),
      });
      handleClose();
      snackbar.success('Invitation has been sent');
      props.onSuccess();
    },
  });

  const onInviteUserSubmit: SubmitHandler<IInviteUserForm> = (data) => {
    const email = data.email.trim();
    const workspaceIds = data.workspaces.map((workspace) => workspace.id);
    const permissions = data.permissions ?? {};
    const permissionsKeys = Object.entries(permissions)
      .filter(([, checked]) => checked)
      .map(([permissionKey]) => permissionKey);

    return inviteUserMutation.mutateAsync({
      email,
      permissionsKeys,
      role: data.isAdmin ? 'admin' : 'default',
      workspaceIds,
    });
  };

  const isAdminChecked = watch('isAdmin');
  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <AppDrawer open={props.open} onClose={handleClose}>
      <Styled.Form onSubmit={handleSubmit(onInviteUserSubmit)}>
        <AppDrawerHeader title="Invite user" onClose={handleClose} />

        <AppDrawerContent>
          <Box display="flex" flexDirection="column" gap={3}>
            {inviteUserMutation.isError && inviteUserMutation.error.scope === 'alert' && (
              <Styled.Alert severity="error" icon={<ErrorIcon />}>
                {inviteUserMutation.error.message}
              </Styled.Alert>
            )}

            <RHFMonkTextField
              name="email"
              control={control}
              rules={{ ...requiredValidation, ...emailValidation }}
              label="Email address"
              placeholder="username@organization.com"
              disabled={isSubmitting}
              required
              fullWidth
            />

            <Controller
              name="workspaces"
              control={control}
              render={({ field }) => (
                <SelectWorkspaces
                  value={field.value}
                  onChange={field.onChange}
                  disabled={isSubmitting}
                />
              )}
            />

            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="titleMedium">Administrative permission</Typography>

              <Accordion>
                <AccordionSummary>
                  <Typography variant="titleSmall">Monks.Flow</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Controller
                    control={control}
                    name="isAdmin"
                    render={({ field }) => (
                      <CheckboxField
                        checked={field.value}
                        helperText="This user will automatically be granted all administrative permissions"
                        id="platform-administrator"
                        label="Platform administrator"
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        ref={field.ref}
                        value="platform-administrator"
                      />
                    )}
                  />

                  <Box display="flex" flexDirection="column" gap={2} marginLeft={4} marginTop={2}>
                    <RHFPermissionsField
                      checked={isAdminChecked}
                      control={control}
                      disabled={isAdminChecked}
                      name="permissions"
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </AppDrawerContent>

        <AppDrawerFooter>
          <Box ml="auto">
            <Styled.InviteUserButton
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isSubmitDisabled}
            >
              {isSubmitting ? <MFButtonLoader /> : `Send invite`}
            </Styled.InviteUserButton>
          </Box>
        </AppDrawerFooter>
      </Styled.Form>
    </AppDrawer>
  );
};

export default InviteUserDrawer;
