import { FC, useState } from 'react';
import SnackbarContext from './SnackbarContext';
import { ISnackbarContext, ISnackbarState } from './SnackbarContext.types';
import { SnackbarProviderProps } from './SnackbarProvider.types';

const defaultSnackbarState: ISnackbarState = {
  isOpen: false,
  message: '',
  severity: undefined,
};

const SnackbarProvider: FC<SnackbarProviderProps> = (props) => {
  const [snackbarState, setSnackbarState] = useState<ISnackbarState>(defaultSnackbarState);

  const handleSnackbarOpen = (message: ISnackbarState['message']) => {
    setSnackbarState({ isOpen: true, severity: undefined, message: message });
  };

  const handleSnackbarInfo = (message: ISnackbarState['message']) => {
    setSnackbarState({ isOpen: true, severity: 'info', message: message });
  };

  const handleSnackbarWarning = (message: ISnackbarState['message']) => {
    setSnackbarState({ isOpen: true, severity: 'warning', message: message });
  };

  const handleSnackbarError = (message: ISnackbarState['message']) => {
    setSnackbarState({ isOpen: true, severity: 'error', message: message });
  };

  const handleSnackbarSuccess = (message: ISnackbarState['message']) => {
    setSnackbarState({ isOpen: true, severity: 'success', message: message });
  };

  const handleSnackbarClose = () => {
    setSnackbarState({ isOpen: false, message: '', severity: undefined });
  };

  const snackbarContextProviderValue: ISnackbarContext = {
    isOpen: snackbarState.isOpen,
    message: snackbarState.message,
    severity: snackbarState.severity,
    open: handleSnackbarOpen,
    info: handleSnackbarInfo,
    warning: handleSnackbarWarning,
    error: handleSnackbarError,
    success: handleSnackbarSuccess,
    close: handleSnackbarClose,
  };

  return (
    <SnackbarContext.Provider value={snackbarContextProviderValue}>
      {props.children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
