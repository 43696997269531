import { useQuery } from '@tanstack/react-query';
import { getUserApps } from './userAppsService';

export const USER_APPS_QUERY_KEY_PREFIX = 'userApps';

export const getUserAppsQueryKey = () => [USER_APPS_QUERY_KEY_PREFIX];

function useUserApps() {
  return useQuery({
    queryKey: getUserAppsQueryKey(),
    queryFn: getUserApps,
  });
}

export default useUserApps;
