import LinkIcon from '@mui/icons-material/LinkOutlined';
import { Button as MuiButton, MenuItem as MuiMenuItem, Typography } from '@mui/material';
import { BOOKMARK_WIDTH } from 'pages/ViewProjectPage/components/Bookmarks/Bookmarks.consts';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  // Apply 3D transform to create a new stacking context and prevent Chrome from dragging underlying elements
  transform: translateZ(0);
`;

export const Button = styled(MuiButton)`
  width: ${BOOKMARK_WIDTH}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 4px;
  border-radius: 8px;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  && .MuiTouchRipple-child {
    background-color: rgba(0, 0, 0, 0.56);
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  width: 100%;
  display: flex;
  gap: 12px;
  max-width: 275px;
`;

export const BookmarkName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CustomLinkIcon = styled(LinkIcon)`
  color: ${({ theme }) => theme.palette.action.active};
  width: 16px;
  height: 16px;
`;
