import ErrorPage from '../ErrorPage/ErrorPage';

const ErrorNoOrganizationPage = () => {
  return (
    <ErrorPage
      title="Organization not found"
      description={`This URL could not be found. Please contact your Monks.Flow administrator for your organization's login information.`}
    />
  );
};

export default ErrorNoOrganizationPage;
