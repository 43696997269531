import platformApi from 'api/platformApi/platformApi';
import { IUsersServiceParamsQuery, IUsersServiceResponse } from 'api/platformApi/platformApi.types';

export const getUsers = async ({
  params: {
    query: { search, sortProperty, direction, pageNumber, pageSize },
  },
}: ServiceFnConfig<undefined, IUsersServiceParamsQuery>) => {
  const response = await platformApi.get<IUsersServiceResponse>('/users', {
    params: {
      search,
      sortProperty,
      direction,
      pageNumber,
      pageSize,
    },
  });
  return response.data;
};
