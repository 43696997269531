const TRAINING_VIDEOS_URL =
  'https://monksflow-prod-monksflow-public-uploads.s3.us-west-2.amazonaws.com/training-videos';

export const LOGGING_IN_VIDEO_URL = TRAINING_VIDEOS_URL + '/Monks.Flow+-+Logging+in.mp4';
export const CREATING_PROJECTS_VIDEO_URL =
  TRAINING_VIDEOS_URL + '/Monks.Flow+-+Creating+Projects.mp4';
export const INVITING_USERS_VIDEO_URL =
  TRAINING_VIDEOS_URL + '/Monks.Flow+Administration+-+Inviting+Users.mp4';
export const MANAGING_WORKSPACES_VIDEO_URL =
  TRAINING_VIDEOS_URL + '/Monks.Flow+Administration+-+Creating+and+Assigning+Workspaces.mp4';
