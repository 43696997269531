import { Button, FormControlLabel as MuiFormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const InviteUserButton = styled(Button)`
  min-width: 122px;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin: ${({ theme }) => theme.spacing(1, 0, 0, 0)};
`;
