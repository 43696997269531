import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button } from '@mui/material';
import useWorkspaceProjectUsers from 'api/platformApi/services/workspaceProjectUsersService/useWorkspaceProjectUsers';
import ShareProjectDrawer from 'components/ShareProjectDrawer/ShareProjectDrawer';
import { FC, useState } from 'react';
import { isProjectInviteOnly } from 'utils/project';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import * as Styled from './ShareProject.styles';
import { ShareProjectProps } from './ShareProject.types';
import ProjectMemberAvatars from './components/ProjectMemberAvatars/ProjectMemberAvatars';

const ShareProject: FC<ShareProjectProps> = ({ project }) => {
  const workspaceId = useActiveWorkspace()?.id ?? '';
  const projectId = project?.id ?? '';
  const [isShareProjectOpen, setIsShareProjectOpen] = useState(false);

  const projectUsersQuery = useWorkspaceProjectUsers(workspaceId, projectId);
  const projectUsers = projectUsersQuery.data?.data ?? [];
  const members = isProjectInviteOnly(project)
    ? projectUsers
    : projectUsers.filter((user) => user.id === project?.owner.id);

  const handleShareProject = () => {
    setIsShareProjectOpen(true);
  };

  const isReadyToShare = project && !projectUsersQuery.isLoading;

  return (
    <Styled.Container>
      {project?.visibility === 'private' ? (
        <Styled.VisibilityInfo
          label="Invite-only project"
          icon={<LockOutlinedIcon fontSize="small" />}
        />
      ) : (
        <Styled.VisibilityInfo
          label="Visible to Workspace"
          icon={<GroupsOutlinedIcon fontSize="small" />}
        />
      )}
      <ProjectMemberAvatars members={members} />
      <Button
        variant="contained"
        color="black"
        onClick={handleShareProject}
        disabled={!isReadyToShare}
      >
        Share
      </Button>
      {isReadyToShare && (
        <ShareProjectDrawer
          project={project}
          currentMembers={members}
          open={isShareProjectOpen}
          onClose={() => {
            setIsShareProjectOpen(false);
          }}
        />
      )}
    </Styled.Container>
  );
};

export default ShareProject;
