import { Alert as MuiAlert, Button, Popover as MuiPopover } from '@mui/material';
import styled from 'styled-components';

export const AddBookmarkButton = styled(Button)`
  min-width: 138px;
`;

export const Popover = styled(MuiPopover)`
  .MuiPopover-paper {
    border-radius: 16px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 480px;
  padding: 24px;
  border-radius: 16px;
`;

export const Alert = styled(MuiAlert)`
  color: #5f2120;
  background-color: #fdeded;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
`;
