import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  IDeleteOrganizationAuthMethodServiceParamsPath,
  IPlatformApiGenericError,
} from 'api/platformApi/platformApi.types';
import { AxiosError } from 'axios';
import { deleteOrganizationAuthMethodService } from './deleteOrganizationAuthMethodService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseDeleteOrganizationAuthMethodParams
  extends IDeleteOrganizationAuthMethodServiceParamsPath {}

function useDeleteOrganizationAuthMethod(
  params: UseDeleteOrganizationAuthMethodParams,
  mutationOptions?: UseMutationOptions<unknown, AxiosError<IPlatformApiGenericError>>,
) {
  return useMutation({
    ...mutationOptions,
    mutationFn: () => {
      return deleteOrganizationAuthMethodService({
        params: {
          path: {
            organizationId: params.organizationId,
            methodId: params.methodId,
          },
        },
      });
    },
  });
}

export default useDeleteOrganizationAuthMethod;
