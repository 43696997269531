import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import styled from 'styled-components';

export const InvitationsMFTableHeadCell = styled(MFTableHeadCell)`
  width: 25%;
`;

export const DateSentContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
