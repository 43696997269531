import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import useUpdateInvitation from 'api/platformApi/services/updateInvitationService/useUpdateInvitation';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerFooter from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import SelectWorkspaces from 'components/SelectWorkspaces/SelectWorkspaces';
import CheckboxField from 'components/ui/CheckboxField/CheckboxField';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import RHFPermissionsField from 'components/ui/rhf-components/RHFPermissionsField/RHFPermissionsField';
import {
  emailValidation,
  requiredValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './EditInvitationDrawer.styles';
import { EditInvitationDrawerProps, EditInvitationForm } from './EditInvitationDrawer.types';

const EditInvitationDrawer: FC<EditInvitationDrawerProps> = (props) => {
  const { open, invitation } = props;
  const snackbar = useSnackbarContext();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
    watch,
  } = useForm<EditInvitationForm>({
    mode: 'all',
    defaultValues: {
      isAdmin: false,
      permissions: {},
      workspaces: [],
    },
    values: {
      email: invitation?.email ?? '',
      isAdmin: invitation?.role === 'admin',
      permissions:
        invitation?.permissions.reduce<Record<string, boolean>>((acc, permission) => {
          acc[permission.key] = true;
          return acc;
        }, {}) ?? {},
      workspaces: invitation?.workspaces ?? [],
    },
  });

  const handleClose = () => {
    reset();
    props.onClose();
  };

  const updateInvitationMutation = useUpdateInvitation(
    {
      invitationId: invitation?.id ?? '',
    },
    {
      onError: () => {
        snackbar.error('An error occurred and the changes could not be saved');
      },
      onSuccess: () => {
        snackbar.success('User invitation updated successfully');
        handleClose();
        props.onEditSuccess();
      },
    },
  );

  const onEditInvitedUserSubmit: SubmitHandler<EditInvitationForm> = async (data) => {
    const workspaceIds = data.workspaces.map((workspace) => workspace.id);
    const permissions = data.permissions ?? {};
    const permissionsKeys = Object.entries(permissions)
      .filter(([, checked]) => checked)
      .map(([permissionKey]) => permissionKey);

    return updateInvitationMutation.mutateAsync({
      permissionsKeys,
      role: data.isAdmin ? 'admin' : 'default',
      workspaceIds,
    });
  };

  const isAdminChecked = watch('isAdmin');
  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <AppDrawer open={open} onClose={handleClose}>
      <Styled.Form onSubmit={handleSubmit(onEditInvitedUserSubmit)}>
        <AppDrawerHeader title="Edit invitation" onClose={handleClose} />

        <AppDrawerContent>
          <Box display="flex" flexDirection="column" gap={3}>
            <RHFMonkTextField
              name="email"
              control={control}
              rules={{ ...requiredValidation, ...emailValidation }}
              label="Email address"
              placeholder="username@organization.com"
              disabled
              required
              fullWidth
            />

            <Controller
              name="workspaces"
              control={control}
              render={({ field }) => (
                <SelectWorkspaces
                  value={field.value}
                  onChange={field.onChange}
                  disabled={isSubmitting}
                />
              )}
            />

            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="titleMedium">Administrative permission</Typography>

              <Accordion>
                <AccordionSummary>
                  <Typography variant="titleSmall">Monks.Flow</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Controller
                    control={control}
                    name="isAdmin"
                    render={({ field }) => (
                      <CheckboxField
                        checked={field.value}
                        helperText="This user will automatically be granted all administrative permissions"
                        id="platform-administrator"
                        label="Platform administrator"
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        ref={field.ref}
                        value="platform-administrator"
                      />
                    )}
                  />

                  <Box display="flex" flexDirection="column" gap={2} marginLeft={4} marginTop={2}>
                    <RHFPermissionsField
                      checked={isAdminChecked}
                      control={control}
                      disabled={isAdminChecked}
                      name="permissions"
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </AppDrawerContent>

        <AppDrawerFooter>
          <Box ml="auto">
            <Styled.InviteUserButton
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isSubmitDisabled}
            >
              {isSubmitting ? <MFButtonLoader /> : `Save changes`}
            </Styled.InviteUserButton>
          </Box>
        </AppDrawerFooter>
      </Styled.Form>
    </AppDrawer>
  );
};

export default EditInvitationDrawer;
