import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  IUpdateOrganizationAuthMethodRequestBody,
  IUpdateOrganizationAuthMethodServiceParamsPath,
} from 'api/platformApi/platformApi.types';
import { updateOrganizationAuthMethodService } from './updateOrganizationAuthMethodService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseUpdateOrganizationAuthMethodParams
  extends IUpdateOrganizationAuthMethodServiceParamsPath {}

function useUpdateOrganizationAuthMethod(
  params: UseUpdateOrganizationAuthMethodParams,
  mutationOptions?: UseMutationOptions<unknown, unknown, IUpdateOrganizationAuthMethodRequestBody>,
) {
  return useMutation({
    ...mutationOptions,
    mutationFn: (body: IUpdateOrganizationAuthMethodRequestBody) => {
      return updateOrganizationAuthMethodService({
        params: {
          path: {
            organizationId: params.organizationId,
            methodId: params.methodId,
          },
        },
        body,
      });
    },
  });
}

export default useUpdateOrganizationAuthMethod;
