import styled from 'styled-components';

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.surface.level1};
  border-radius: ${({ theme }) => theme.spacing(2)};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.02);
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const AppsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const AppCard = styled.div`
  display: flex;
  align-items: center;
  width: ${({ theme }) => theme.spacing(25)};
  padding: ${({ theme }) => theme.spacing(1.25)};
  gap: ${({ theme }) => theme.spacing(1.25)};
  border: 1px solid ${({ theme }) => theme.palette.surface.dividerMain};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
`;

export const AppLogo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
`;
