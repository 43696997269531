import { useQueryClient } from '@tanstack/react-query';
import useDeleteOrganizationAuthMethod from 'api/platformApi/services/deleteOrganizationAuthMethodService/useDeleteOrganizationAuthMethod';
import { getOrganizationAuthMethodsQueryKey } from 'api/platformApi/services/organizationAuthMethodsService/useOrganizationAuthMethods';
import ConfirmDeleteDialog from 'components/ui/ConfirmDeleteDialog/ConfirmDeleteDialog';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import React from 'react';
import { RemoveAuthMethodDialogProps } from './RemoveAuthMethodDialog.types';

const RemoveAuthMethodDialog: React.FC<RemoveAuthMethodDialogProps> = ({
  organizationId,
  methodId,
  open,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();

  const deleteAuthMethodMutation = useDeleteOrganizationAuthMethod(
    {
      organizationId,
      methodId,
    },
    {
      onError: () => {
        snackbar.error('An error occurred and the method could not be removed. Please try again.');
      },
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getOrganizationAuthMethodsQueryKey(organizationId),
        });
        onClose();
        snackbar.success('The authentication method was removed successfully!');
      },
    },
  );

  const handleConfirmRemove = (): void => {
    deleteAuthMethodMutation.mutate();
  };

  const handleCancelRemove = (): void => {
    onClose();
  };

  return (
    <ConfirmDeleteDialog
      open={open}
      onClose={handleCancelRemove}
      onConfirm={handleConfirmRemove}
      title="Remove authentication method"
      content="If you remove the authentication method from this organization, it will be removed from the login user options. Would you like to proceed?"
    />
  );
};

export default RemoveAuthMethodDialog;
