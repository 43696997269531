import { IAgreementType } from 'api/platformApi/platformApi.types';
import useUsersMeAgreement from 'api/platformApi/services/usersMeAgreementsService/useUsersMeAgreements';
import useAuthContext from 'contexts/auth/useAuthContext';
import AgreementsContext from './AgreementsContext';
import { AgreementsProviderProps } from './AgreementsProvider.types';

const AgreementsProvider: React.FC<AgreementsProviderProps> = (props) => {
  const { isUserAuthenticated } = useAuthContext();
  const agreementsQuery = useUsersMeAgreement({
    enabled: isUserAuthenticated,
  });
  const eulaStatusResponse = agreementsQuery.data?.data.find(
    // Disable the unnecessary condition rule to future-proof the code
    // since more agreement types might be added later.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (agreement) => agreement.type === IAgreementType.EULA,
  );

  const providerValue = eulaStatusResponse;

  return (
    <AgreementsContext.Provider value={providerValue}>{props.children}</AgreementsContext.Provider>
  );
};

export default AgreementsProvider;
