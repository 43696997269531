export const PERMISSIONS = {
  PlatformInstanceManage: 'platform:instance:manage',
  PlatformManageUsers: 'platform:manage-users',
  PlatformManageWorkspaces: 'platform:manage-workspaces',
  PlatformModifyAll: 'platform:modify-all',
  PlatformViewAll: 'platform:view-all',
} as const;

export type Permission = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];
export type PermissionRule = Permission | PermissionRule[];

export function validatePermissions(
  ruleset: PermissionRule[],
  permissions: string[] = [],
): boolean {
  const hasPermission = (permission: Permission): boolean => permissions.includes(permission);

  const evaluateRule = (rule: PermissionRule): boolean => {
    if (typeof rule === 'string') {
      const permission = rule;
      return hasPermission(permission);
    }

    if (Array.isArray(rule)) {
      /* OR ruleset */
      // Treat as OR condition across arrays
      if (rule.some(Array.isArray)) {
        return rule.some(evaluateRule);
      }

      /* AND ruleset */
      // Treat as AND condition within array
      return rule.every(evaluateRule);
    }

    return false;
  };

  return evaluateRule(ruleset);
}
