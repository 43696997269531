import InfoIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import React, { FC, useState } from 'react';
import * as Styled from './FlowDescriptionPopover.styles';

const FlowDescriptionPopover: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'flow-description-popover' : undefined;

  return (
    <div>
      <Styled.IconButton
        aria-describedby={id}
        onMouseEnter={handleOpenPopover}
        onMouseLeave={handleClosePopover}
      >
        <InfoIcon />
      </Styled.IconButton>
      <Styled.Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        sx={{
          pointerEvents: 'none',
        }}
        anchorOrigin={{
          vertical: -12,
          horizontal: 'right',
        }}
        onClose={handleClosePopover}
        disableRestoreFocus
      >
        <Styled.Title>
          Welcome to workspace <Styled.AccentText>projects</Styled.AccentText>
        </Styled.Title>
        <Typography variant="bodyMedium" color="text.secondary">
          This is your workspace&apos;s project library. Explore public projects, access essential
          assets, and work seamlessly with your team. Ready to take the lead? Create your own
          project and collaborate with members by invitation only.
        </Typography>
      </Styled.Popover>
    </div>
  );
};

export default FlowDescriptionPopover;
