import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  IAgreementAcknowledgementsServiceParamsPath,
  IAgreementAcknowledgementsServiceResponse,
  IPlatformApiGenericError,
} from 'api/platformApi/platformApi.types';
import { AxiosError } from 'axios';
import { agreementAcknowledgementsService } from './agreementAcknowledgementsService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseAgreementAcknowledgmentsParams extends IAgreementAcknowledgementsServiceParamsPath {}

const useAgreementsAcknowledgements = (
  params: UseAgreementAcknowledgmentsParams,
  mutationOptions?: UseMutationOptions<
    IAgreementAcknowledgementsServiceResponse,
    AxiosError<IPlatformApiGenericError>
  >,
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: ['agreementAcknowledgements'],
    mutationFn: () => {
      return agreementAcknowledgementsService({
        params: {
          path: {
            agreementId: params.agreementId,
          },
        },
      });
    },
  });
};

export default useAgreementsAcknowledgements;
