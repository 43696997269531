import styled from 'styled-components';

export const HEADER_HEIGHT = 56;

export const Header = styled.header`
  align-items: center;
  background-color: #212121;
  color: white;
  display: flex;
  height: ${HEADER_HEIGHT}px;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.appBar};
`;

export const FlowName = styled.h2`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.typography.fontWeightExtraBold};
`;

export const LeftSection = styled('section')`
  margin-right: auto;
` as React.FC<React.HTMLAttributes<HTMLDivElement>>;

export const RightSection = styled('section')`
  margin-left: auto;
` as React.FC<React.HTMLAttributes<HTMLDivElement>>;

export const CenterSection = styled('section')`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
` as React.FC<React.HTMLAttributes<HTMLDivElement>>;
