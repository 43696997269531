import { format, intervalToDuration, parseISO } from 'date-fns';

export const formatRelativeDate = (date: string) => {
  const dateToFormat = parseISO(date);
  const currentDateTime = new Date();

  const duration = intervalToDuration({
    start: dateToFormat,
    end: currentDateTime,
  });

  // 48 hours or later
  if (
    duration.years ??
    duration.months ??
    duration.weeks ??
    (duration.days && duration.days >= 2)
  ) {
    return format(dateToFormat, 'MM/dd/yyyy');
  }

  // 24 - 48 hours
  if (duration.days && duration.days >= 1) {
    return `Yesterday`;
  }

  // 1 - 24 hours
  if (duration.hours && duration.hours >= 1) {
    return `${duration.hours} hour${duration.hours > 1 ? 's' : ''} ago`;
  }

  // 1 - 59 minutes
  if (duration.minutes && duration.minutes >= 1) {
    return `${duration.minutes} minute${duration.minutes > 1 ? 's' : ''} ago`;
  }

  // 1 - 59 seconds
  if (duration.seconds && duration.seconds >= 0) {
    return `Just now`;
  }

  return `-`;
};
