export const requiredValidation = {
  required: {
    value: true,
    message: 'The field is required',
  },
};

export const emailValidation = {
  pattern: {
    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: 'Invalid email address',
  },
};

export const numberValidation = {
  pattern: {
    value: /^(0|[1-9]\d*)(\.\d+)?$/,
    message: 'Only numerical values are allowed',
  },
};

export const integerValidation = {
  pattern: {
    value: /^[-]{0,1}([0-9]\d*)$/,
    message: 'Only integer values are allowed',
  },
};

export const URLValidation = {
  pattern: {
    value: new RegExp(
      '^(https?:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d%_.~+=-]*)?$', // fragment locator
      'i',
    ),
    message: 'Only valid URLs values are allowed',
  },
};

export const getMinLengthValidation = (minLength: number) => {
  return {
    minLength: {
      value: minLength,
      message: `Minimum length is ${minLength} characters`,
    },
  };
};

export const getMaxLengthValidation = (maxLength: number) => {
  return {
    maxLength: {
      value: maxLength,
      message: `Maximum length is ${maxLength} characters`,
    },
  };
};
