import { useQuery } from '@tanstack/react-query';
import { IUsersServiceParamsQuery } from 'api/platformApi/platformApi.types';
import { getUsers } from './usersService';

export const USERS_QUERY_KEY_PREFIX = 'users';

export const getUsersQueryKey = (params?: UseUsersParams) => {
  if (!params) return [USERS_QUERY_KEY_PREFIX];
  return [
    USERS_QUERY_KEY_PREFIX,
    {
      search: params.search,
      sortProperty: params.sortProperty,
      direction: params.direction,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
    },
  ];
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseUsersParams extends IUsersServiceParamsQuery {}

interface PartialUseQueryOptions {
  enabled?: boolean;
}

function useUsers(params?: UseUsersParams, queryOptions?: PartialUseQueryOptions) {
  return useQuery({
    ...queryOptions,
    queryKey: getUsersQueryKey(params),
    queryFn: () =>
      getUsers({
        params: {
          query: {
            search: params?.search,
            sortProperty: params?.sortProperty,
            direction: params?.direction,
            pageNumber: params?.pageNumber ?? 1,
            pageSize: params?.pageSize ?? 50,
          },
        },
      }),
  });
}

export default useUsers;
