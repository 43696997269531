import { Link } from '@mui/material';
import { FC } from 'react';
import { routes } from 'utils/routes';
import { LegalInformationLinkProps } from './LegalInformationLink.types';

const LegalInformationLink: FC<LegalInformationLinkProps> = (props) => (
  <Link
    href={routes.legalInformation()}
    target="_blank"
    rel="noopener noreferrer"
    color="text.primary"
    underline="none"
    {...props}
  >
    Legal information
  </Link>
);

export default LegalInformationLink;
