import { TableRow as MuiTableRow } from '@mui/material';
import styled from 'styled-components';

export const TableRow = styled(MuiTableRow)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.hover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.palette.primary.selected};
  }
`;
