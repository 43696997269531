import { Tooltip } from '@mui/material';
import { FC } from 'react';
import * as Styled from './ProjectMemberAvatars.styles';
import { ProjectMemberAvatarsProps } from './ProjectMemberAvatars.types';

const MAX_AVATARS_TO_SHOW = 3;

const ProjectMemberAvatars: FC<ProjectMemberAvatarsProps> = ({ members }) => {
  const displayed = members.slice(0, MAX_AVATARS_TO_SHOW);
  const hiddenCount = members.length - displayed.length;

  return (
    <Styled.Container>
      {displayed.map((member) => (
        <Tooltip key={member.id} title={member.name}>
          <Styled.Avatar>{member.name[0]}</Styled.Avatar>
        </Tooltip>
      ))}
      {hiddenCount > 0 && (
        <Tooltip title={`${hiddenCount} more member${hiddenCount === 1 ? '' : 's'} not shown`}>
          <Styled.Avatar style={{ fontSize: hiddenCount > 9 ? 10 : 12 }}>
            +{hiddenCount}
          </Styled.Avatar>
        </Tooltip>
      )}
    </Styled.Container>
  );
};

export default ProjectMemberAvatars;
