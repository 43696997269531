import { Typography } from '@mui/material';
import styled from 'styled-components';

export const NameContent = styled.div`
  display: flex;
  align-items: center;
`;

export const OrganizationLogo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

export const Name = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(1.5)};
`;
