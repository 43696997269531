import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WindowIcon from '@mui/icons-material/Window';
import { ToggleButtonGroup } from '@mui/material';
import { FC } from 'react';
import { ProjectsViewMode } from '../ProjectsView/ProjectsView.types';
import * as Styled from './ProjectsViewToggle.styles';
import { ProjectsViewToggleProps } from './ProjectsViewToggle.types';

const ProjectsViewToggle: FC<ProjectsViewToggleProps> = ({ viewMode, onViewModeChange }) => {
  return (
    <ToggleButtonGroup
      size="small"
      aria-label="Projects panel view modes"
      exclusive
      onChange={(_, mode: ProjectsViewMode | null) => {
        if (mode === null) return;
        onViewModeChange(mode);
      }}
      value={viewMode}
    >
      <Styled.ProjectsViewToggleButton value="list">
        <WindowIcon />
      </Styled.ProjectsViewToggleButton>

      <Styled.ProjectsViewToggleButton value="table">
        <FormatListBulletedIcon />
      </Styled.ProjectsViewToggleButton>
    </ToggleButtonGroup>
  );
};

export default ProjectsViewToggle;
