import { Button, Avatar as MuiAvatar } from '@mui/material';
import AppDrawerFooter from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter';
import styled from 'styled-components';

export const Avatar = styled(MuiAvatar)`
  width: 32px;
  height: 32px;
  font-size: 14px;
  font-weight: 800;
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.dark};
  line-height: 32x;
`;

export const Members = styled.div`
  margin-top: 32px;
`;

export const MemberSelectListContainer = styled.div`
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.palette.surface.dividerMain};
  overflow: hidden;
  border-radius: 8px;
`;

export const CustomAppDrawerFooter = styled(AppDrawerFooter)`
  justify-content: end;
`;

export const SaveChangesButton = styled(Button)`
  min-height: 42px;
  min-width: 155px;
`;
