import platformApi from 'api/platformApi/platformApi';
import { IOrganizationAuthMethodsServiceResponse } from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig } from 'axios';

export const getOrganizationAuthMethods = (
  organizationId: string,
  axiosConfig?: AxiosRequestConfig,
) => {
  return platformApi.get<IOrganizationAuthMethodsServiceResponse>(
    `/organizations/${organizationId}/sso-methods`,
    axiosConfig,
  );
};
