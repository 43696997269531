import { Menu } from '@mui/material';
import { useState } from 'react';
import SideNavItem from '../SideNavItem/SideNavItem';
import { SideNavItemWithSubmenuProps } from './SideNavItemWithSubmenu.types';

const SideNavItemWithSubmenu = ({ label, icon, renderMenuItems }: SideNavItemWithSubmenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <SideNavItem label={label} icon={icon} active={open} onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ marginTop: '1px' }}
      >
        {renderMenuItems(handleClose)}
      </Menu>
    </>
  );
};

export default SideNavItemWithSubmenu;
