import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { TableRowActionsMenuProps } from './TableRowActionsMenu.types';

const TableRowActionsMenu: FC<TableRowActionsMenuProps> = ({ actions }) => {
  const [optionsButtonElement, setActionsButtonElement] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(optionsButtonElement);

  const handleActionsOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setActionsButtonElement(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsButtonElement(null);
  };

  return (
    <>
      <IconButton onClick={handleActionsOpen} size="small">
        <MoreHorizIcon />
      </IconButton>

      <Menu
        open={isMenuOpen}
        onClose={handleActionsClose}
        anchorEl={optionsButtonElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            onClick={() => {
              action.onClick();
              handleActionsClose();
            }}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TableRowActionsMenu;
