import useUpdateWorkspaceProjectBookmarks from 'api/platformApi/services/updateWorkspaceProjectBookmarksService/useUpdateWorkspaceProjectBookmarks';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { generateSkeletonArray } from 'utils/skeleton';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import { BOOKMARK_HORIZONTAL_GAP, BOOKMARK_WIDTH, MIN_VISIBLE_COUNT } from './Bookmarks.consts';
import * as Styled from './Bookmarks.styles';
import { BookmarksProps, BookmarkWithId } from './Bookmarks.types';
import AddBookmark from './components/AddBookmark/AddBookmark';
import BookmarkItem from './components/BookmarkItem/BookmarkItem';
import MoreButton from './components/MoreButton/MoreButton';
import { useRearrangeBookmarks } from './hooks/useRearrangeBookmarks';

const Bookmarks = ({ bookmarks, disabled, isLoading }: BookmarksProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [visibleCount, setVisibleCount] = useState<number>(MIN_VISIBLE_COUNT);
  const snackbar = useSnackbarContext();
  const params = useParams();
  const activeWorkspace = useActiveWorkspace();
  const workspaceId = activeWorkspace?.id ?? '';
  const projectId = params.projectId ?? '';

  const updateContainerWidth = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    const bookmarkTotalWidth = BOOKMARK_WIDTH + BOOKMARK_HORIZONTAL_GAP;
    const count = Math.floor(containerWidth / bookmarkTotalWidth);
    setVisibleCount(Math.max(count, MIN_VISIBLE_COUNT));
  }, [containerWidth]);

  useEffect(() => {
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  const bookmarksWithId = useMemo(() => {
    return bookmarks.map((bookmark, i) => ({ ...bookmark, id: i.toString() }));
  }, [bookmarks]);

  const updateBookmarksMutation = useUpdateWorkspaceProjectBookmarks(workspaceId, projectId);

  const { dropIndex, dropIndicatorPosition, onDragStart, onDragOver, onDrop } =
    useRearrangeBookmarks(bookmarksWithId, visibleCount, {
      onChange: (bookmarks) => {
        updateBookmarksMutation.mutate(
          {
            bookmarks,
          },
          {
            onSuccess: () => {
              snackbar.success('Bookmarks successfully rearranged');
            },
            onError: () => {
              snackbar.error('An error occurred while rearranging bookmarks');
            },
          },
        );
      },
    });

  const handleDeleteBookmark = (bookmarkId: string) => {
    const newBookmarks = bookmarksWithId.filter((bookmark) => bookmark.id !== bookmarkId);
    updateBookmarksMutation.mutate(
      {
        bookmarks: newBookmarks,
      },
      {
        onSuccess: () => {
          snackbar.success('Bookmark successfully deleted');
        },
        onError: () => {
          snackbar.error('An error occurred while deleting the bookmark');
        },
      },
    );
  };

  const { visibleBookmarks, hiddenBookmarks } = useMemo(() => {
    return {
      visibleBookmarks: bookmarksWithId.slice(0, visibleCount),
      hiddenBookmarks: bookmarksWithId.slice(visibleCount),
    };
  }, [bookmarksWithId, visibleCount]);
  const skeletonBookmarks = generateSkeletonArray<BookmarkWithId>(5);
  const isUpdatingOrLoading = updateBookmarksMutation.isPending || isLoading;
  const bookmarksToDisplay = isUpdatingOrLoading ? skeletonBookmarks : visibleBookmarks;

  return (
    <Styled.Container>
      <Styled.BookmarksContainer ref={containerRef} onDrop={onDrop} onDragOver={onDragOver}>
        {bookmarksToDisplay.length === 0 ? (
          <Styled.EmptyState variant="labelSmall" color="text.secondary">
            Add bookmarks to this project…
          </Styled.EmptyState>
        ) : (
          bookmarksToDisplay.map((bookmark, i) => {
            return (
              <React.Fragment key={bookmark.id}>
                {i === dropIndex && dropIndicatorPosition === 'before' && <Styled.DropIndicator />}
                <BookmarkItem
                  bookmark={bookmark}
                  onDeleteBookmark={handleDeleteBookmark}
                  onDragBookmark={onDragStart}
                  isLoading={isUpdatingOrLoading}
                />
                {i === dropIndex && dropIndicatorPosition === 'after' && <Styled.DropIndicator />}
              </React.Fragment>
            );
          })
        )}
      </Styled.BookmarksContainer>

      {hiddenBookmarks.length > 0 && (
        <MoreButton
          bookmarks={hiddenBookmarks}
          disabled={disabled}
          onDeleteBookmark={handleDeleteBookmark}
          onDragBookmark={onDragStart}
          onDragOver={(e) => {
            onDragOver(e, 'vertical');
          }}
          onDrop={onDrop}
          offsetIndex={visibleCount}
          dropIndex={dropIndex}
          dropIndicatorPosition={dropIndicatorPosition}
        />
      )}

      {!disabled && <AddBookmark currentBookmarks={bookmarks} />}
    </Styled.Container>
  );
};

export default Bookmarks;
