import { Alert as MuiAlert, FormControlLabel as MuiFormControlLabel } from '@mui/material';
import waveformBlurredBg from 'assets/img/waveform-blurred-bg.jpg';
import styled from 'styled-components';

export const Main = styled.main`
  background: ${({ theme }) => theme.palette.surface.level0} url(${waveformBlurredBg}) no-repeat
    center center / cover fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.surface.level1};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;
  box-shadow: ${({ theme }) => theme.monksShadows.level4};
  max-width: 720px;
  color: ${({ theme }) => theme.palette.text.primary};

  font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
  font-weight: ${({ theme }) => theme.typography.bodyMedium.fontWeight};
  line-height: ${({ theme }) => theme.typography.bodyMedium.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.bodyMedium.letterSpacing};

  li {
    list-style: none;
  }

  h1 {
    font-size: ${({ theme }) => theme.typography.titleMedium.fontSize};
    font-weight: ${({ theme }) => theme.typography.titleMedium.fontWeight};
    line-height: ${({ theme }) => theme.typography.titleMedium.lineHeight};
    letter-spacing: ${({ theme }) => theme.typography.titleMedium.letterSpacing};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  h2 {
    font-size: ${({ theme }) => theme.typography.titleSmall.fontSize};
    font-weight: 700;
    line-height: ${({ theme }) => theme.typography.titleSmall.lineHeight};
    letter-spacing: ${({ theme }) => theme.typography.titleSmall.letterSpacing};
  }

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacing(4.5, 3.75, 3.5, 3.75)};
`;

export const ScrollableContainer = styled.div`
  height: 56vh;
  max-height: 720px;
  margin: ${({ theme }) => theme.spacing(0, 3.75)};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
  overflow-y: auto;
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 3.75)};
  background: ${({ theme }) => theme.palette.surface.level2};
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(0.5, 0, 1.5)};
`;

export const Alert = styled(MuiAlert)`
  width: 100%;
  color: #5f2120;
  background-color: #fdeded;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(3.5, 3.75)};
`;
