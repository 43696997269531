import { useQuery } from '@tanstack/react-query';
import { getOrganizationAuthMethods } from './organizationAuthMethodsService';

export const ORGANIZATION_AUTH_METHODS_QUERY_KEY_PREFIX = 'organizationAuthMethods';

export const getOrganizationAuthMethodsQueryKey = (organizationId: string) => [
  ORGANIZATION_AUTH_METHODS_QUERY_KEY_PREFIX,
  { organizationId },
];

const useOrganizationAuthMethods = (
  organizationId: string,
  queryOptions?: { enabled: boolean },
) => {
  return useQuery({
    queryKey: getOrganizationAuthMethodsQueryKey(organizationId),
    queryFn: () => getOrganizationAuthMethods(organizationId).then((r) => r.data),
    ...queryOptions,
  });
};

export default useOrganizationAuthMethods;
