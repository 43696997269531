import platformApi from 'api/platformApi/platformApi';
import {
  IDeleteWorkspaceUserParamsPath,
  IDeleteWorkspaceUserResponse,
} from 'api/platformApi/platformApi.types';
import { AxiosResponse } from 'axios';

export const deleteWorkspaceUser = async ({
  params: {
    path: { userId, workspaceId },
  },
}: ServiceFnConfig<IDeleteWorkspaceUserParamsPath>) => {
  const response = await platformApi.delete<
    IDeleteWorkspaceUserResponse,
    AxiosResponse<IDeleteWorkspaceUserResponse>
  >(`/workspaces/${workspaceId}/users/${userId}`);

  return response.data;
};
