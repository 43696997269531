import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  IDeleteWorkspaceUserParamsPath,
  IDeleteWorkspaceUserResponse,
  IWorkspaceUser,
} from 'api/platformApi/platformApi.types';
import { WorkspaceMetricsServiceKeys } from '../workspaceMetricsService/hooks';
import { getWorkspaceUsersQueryKey } from '../workspaceUsersService/useWorkspaceUsers';
import { deleteWorkspaceUser } from './deleteWorkspaceUserService';

function useDeleteWorkspaceUser(
  mutationOptions?: UseMutationOptions<
    IDeleteWorkspaceUserResponse,
    undefined,
    IDeleteWorkspaceUserParamsPath
  >,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...mutationOptions,
    mutationFn(path) {
      return deleteWorkspaceUser({ params: { path } });
    },
    async onMutate(variables) {
      const queryKey = getWorkspaceUsersQueryKey({ workspaceId: variables.workspaceId });

      await queryClient.cancelQueries({
        queryKey,
      });

      const previousUsers = queryClient.getQueryData<IWorkspaceUser[]>(queryKey);

      queryClient.setQueryData<IWorkspaceUser[]>(queryKey, (currentUsers = []) =>
        currentUsers.filter((user) => user.id !== variables.userId),
      );

      return { previousUsers };
    },
    onError(_error, variables, context) {
      queryClient.setQueryData(
        getWorkspaceUsersQueryKey({ workspaceId: variables.workspaceId }),
        context?.previousUsers,
      );
    },
    onSettled(_data, _error, variables) {
      void queryClient.invalidateQueries({
        queryKey: getWorkspaceUsersQueryKey({ workspaceId: variables.workspaceId }),
      });

      void queryClient.invalidateQueries({
        queryKey: WorkspaceMetricsServiceKeys.detail(variables),
      });
    },
  });
}

export default useDeleteWorkspaceUser;
