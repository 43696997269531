import { IWorkspaceProject } from 'api/platformApi/platformApi.types';
import { ITableHeadCell } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';

export const headCells = [
  { id: 'name', label: 'Project title', sortable: true },
  {
    id: 'owner',
    label: 'Owner',
    /**
     * Back-end currently does not support sorting by owner name.
     * @see https://api.dev.monksflow.ai/docs#/Workspaces/ProjectController_getAllProjects
     **/
    sortable: false,
  },
  { id: 'createdAt', label: 'Created', sortable: true },
  { id: 'visibility', label: 'Visible to' },
] satisfies ITableHeadCell<keyof IWorkspaceProject>[];
