import { ReactElement } from 'react';
import { ImageFileChooserError } from './ImageFileChooser.utils';

export enum ImageFileChooserErrorCode {
  FileAspectRatioOff = 'FILE_ASPECT_RATIO_OFF',
  FileDimensionsOff = 'FILE_DIMENSIONS_OFF',
  FileTooBig = 'FILE_TOO_BIG',
  FileTypeNotAccepted = 'FILE_TYPE_NOT_ACCEPTED',
}

export interface ImageFileMetadata {
  name: string;
  dimensions: { width: number; height: number };
  size: number;
  type: string;
}

export interface ImageFileRequirements {
  aspectRatio?: number;
  maxDimension?: number;
  minDimension?: number;
  /* Max size should be provided in bytes to match the web API from file inputs. */
  maxSize?: number;
  types?: string[];
}

export interface ImageFileChooserProps extends ImageFileRequirements {
  children: ReactElement;

  onChoose?: (file: File) => void;
  onError?: (errors: ImageFileChooserError[]) => void;
  onValidating?: () => void;
}
