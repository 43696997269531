import platformApi from 'api/platformApi/platformApi';
import {
  IAgreementAcknowledgementsServiceParamsPath,
  IAgreementAcknowledgementsServiceResponse,
} from 'api/platformApi/platformApi.types';

export const agreementAcknowledgementsService = async ({
  params: {
    path: { agreementId },
  },
}: ServiceFnConfig<IAgreementAcknowledgementsServiceParamsPath>) => {
  const response = await platformApi.post<IAgreementAcknowledgementsServiceResponse>(
    `/agreements/${agreementId}/acknowledgements`,
  );

  return response.data;
};
