import { useWorkspaceProject } from 'api/platformApi/services/workspaceProjectsService/hooks';
import { useWorkspace } from 'api/platformApi/services/workspacesService/hooks';

type WorkspaceAccessLevelParams = Parameters<typeof useWorkspace>[0];

/**
 * Returns the workspace access level for the current user.
 * Returns 'read' if the workspace is not found or yet not loaded.
 */
export const useWorkspaceAccessLevel = (params: WorkspaceAccessLevelParams) => {
  const { data: workspace } = useWorkspace(params);
  return workspace?.accessLevel ?? 'read';
};

type ProjectAccessLevelParams = Parameters<typeof useWorkspaceProject>[0];

/**
 * Returns the project access level for the current user.
 * Returns 'read' if the project is not found or yet not loaded.
 */
export const useProjectAccessLevel = (params: ProjectAccessLevelParams) => {
  const { data: project } = useWorkspaceProject(params);
  return project?.accessLevel ?? 'read';
};
