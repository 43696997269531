import { ITableOrderDirection } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import { IProjectFilesOrderBy, IProjectFileWithCreatedByString } from './ProjectFiles.types';

function projectFilesDescendingComparator(
  a: IProjectFileWithCreatedByString,
  b: IProjectFileWithCreatedByString,
  orderBy: IProjectFilesOrderBy,
) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getProjectFilesComparator(
  order: ITableOrderDirection,
  orderBy: IProjectFilesOrderBy,
): (a: IProjectFileWithCreatedByString, b: IProjectFileWithCreatedByString) => number {
  return order === 'desc'
    ? (a, b) => projectFilesDescendingComparator(a, b, orderBy)
    : (a, b) => -projectFilesDescendingComparator(a, b, orderBy);
}
