import platformApi from 'api/platformApi/platformApi';
import { IInvitationsServiceResponse } from 'api/platformApi/platformApi.types';

export const getInvitations = async () => {
  const networkResponse = await platformApi.get<IInvitationsServiceResponse>('/invitations');
  const apiResponse = networkResponse.data;
  const apiData = apiResponse.data;

  return apiData;
};
