import { skipToken, useQuery } from '@tanstack/react-query';
import { IWorkspaceMetricsServiceParamsPath } from '../../platformApi.types';
import { getWorkspaceMetrics } from './services';

export const WorkspaceMetricsServiceKeys = {
  root: ['workspaceMetrics'] as const,
  details() {
    return [...this.root, 'detail'] as const;
  },
  detail(params: Partial<IWorkspaceMetricsServiceParamsPath>) {
    return [...this.details(), params] as const;
  },
};

export type UseWorkspaceMetricsParams = Partial<IWorkspaceMetricsServiceParamsPath>;

export const useWorkspaceMetrics = (params: UseWorkspaceMetricsParams) => {
  const { workspaceId } = params;

  return useQuery({
    queryKey: WorkspaceMetricsServiceKeys.detail({ workspaceId }),
    queryFn: workspaceId
      ? () => getWorkspaceMetrics({ params: { path: { workspaceId } } })
      : skipToken,
  });
};
