import platformApi from 'api/platformApi/platformApi';
import { IResendInvitationResponse } from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const resendInvitation = (invitationId: string, axiosConfig?: AxiosRequestConfig) => {
  return platformApi.post<IResendInvitationResponse, AxiosResponse<IResendInvitationResponse>>(
    `/invitations/${invitationId}/actions/resend`,
    undefined,
    axiosConfig,
  );
};
