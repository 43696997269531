import platformApi from 'api/platformApi/platformApi';
import {
  IUpdateInvitationRequestBody,
  IUpdateInvitationResponse,
  IUpdateInvitationServiceParamsPath,
} from 'api/platformApi/platformApi.types';

export const updateInvitation = async ({
  params: {
    path: { invitationId },
  },
  body,
}: ServiceFnConfig<
  IUpdateInvitationServiceParamsPath,
  undefined,
  IUpdateInvitationRequestBody
>) => {
  const response = await platformApi.put<IUpdateInvitationResponse>(
    `/invitations/${invitationId}`,
    body,
  );

  return response.data;
};
