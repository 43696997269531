import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  ICreateInvitationRequestBody,
  ICreateInvitationResponse,
} from 'api/platformApi/platformApi.types';
import { createInvitation, CreateInvitationOperationError } from './createInvitationService';

function useCreateInvitation(
  mutationOptions?: UseMutationOptions<
    ICreateInvitationResponse,
    CreateInvitationOperationError,
    ICreateInvitationRequestBody
  >,
) {
  return useMutation({
    ...mutationOptions,
    mutationKey: ['createInvitation'],
    mutationFn: createInvitation,
  });
}

export default useCreateInvitation;
