import { TextField } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(4, 0)};
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.pageBar};
  backdrop-filter: blur(16px);
`;

export const ProjectCountDisplay = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const SearchInput = styled(TextField)`
  width: 240px;

  & .MuiInput-root {
    background-color: ${({ theme }) => theme.palette.surface.level1};
    border-radius: 16px;
    margin-top: 0px;

    &:hover {
      background: ${({ theme }) => theme.palette.surface.level1};
    }
  }
`;
