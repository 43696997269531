import { useQuery } from '@tanstack/react-query';
import { getApps } from './appsService';

export const APPS_QUERY_KEY_PREFIX = 'apps';

export const getAppsQueryKey = () => [APPS_QUERY_KEY_PREFIX];

function useApps() {
  return useQuery({
    queryKey: getAppsQueryKey(),
    queryFn: getApps,
  });
}

export default useApps;
