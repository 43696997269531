import { ITableHeadCell } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import { IUserWithWorkspacesString } from './AccountsList.types';

export const headCells = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'email', label: 'Email address', sortable: false },
  { id: 'workspacesString', label: 'Workspaces', sortable: false },
] satisfies ITableHeadCell<keyof IUserWithWorkspacesString>[];

export const HEAD_CELLS_SX = {
  width: `${100 / headCells.length}%`,
};

export const MIN_SEARCH_LENGTH = 3;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_SORT_DIRECTION = 'asc';
export const DEFAULT_SORT_PROPERTY = 'name';
