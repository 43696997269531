import { useQuery } from '@tanstack/react-query';
import { getUsersMeAgreements } from './usersMeAgreementsService';

export const USERS_ME_AGREEMENTS_QUERY_KEY_PREFIX = 'usersMeAgreements';

export const getUsersMeAgreementQueryKey = () => [USERS_ME_AGREEMENTS_QUERY_KEY_PREFIX];

export interface PartialUseQueryOptions {
  enabled?: boolean;
}

const useUsersMeAgreement = (partialQueryOptions: PartialUseQueryOptions) => {
  return useQuery({
    queryKey: getUsersMeAgreementQueryKey(),
    queryFn: () => getUsersMeAgreements(),
    ...partialQueryOptions,
  });
};

export default useUsersMeAgreement;
