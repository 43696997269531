import useAgreementsContext from 'contexts/agreements/useAgreementsContext';
import useAuthContext from 'contexts/auth/useAuthContext';
import { FC, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import { PermissionRule, PERMISSIONS, validatePermissions } from 'utils/permissions';
import { useGlobalPreferences } from 'utils/preferences/useGlobalPreferences';
import { RouteGuardState } from './RouteGuard.consts';

type RouteGuardProps = PropsWithChildren<{
  fallback: (state: RouteGuardState) => React.ReactNode;
}>;

export const InstanceAdminGuard: FC<RouteGuardProps> = ({ children, fallback }) => {
  const { isUserAuthenticated, user } = useAuthContext();

  if (!isUserAuthenticated) {
    throw new Error('`InstanceAdminGuard` should be used in an authenticated context');
  }

  const isInstanceAdmin = validatePermissions(
    [PERMISSIONS.PlatformInstanceManage],
    user.permissions,
  );

  if (!isInstanceAdmin) {
    return fallback(RouteGuardState.ForbiddenRole);
  }

  return children;
};

export const AuthenticatedGuard: FC<RouteGuardProps> = ({ children, fallback }) => {
  const { isUserAuthenticated } = useAuthContext();

  if (!isUserAuthenticated) {
    return fallback(RouteGuardState.NotAuthenticated);
  }

  return children;
};

export const AgreementsGuard: FC<RouteGuardProps> = ({ children, fallback }) => {
  const eulaStatus = useAgreementsContext();

  if (eulaStatus?.accepted === false) {
    return fallback(RouteGuardState.EulaNotAccepted);
  }

  return children;
};

export const UnauthenticatedGuard: FC<RouteGuardProps> = ({ children, fallback }) => {
  const { isUserAuthenticated } = useAuthContext();

  if (isUserAuthenticated) {
    return fallback(RouteGuardState.Authenticated);
  }

  return children;
};

export const WorkspaceRequiredGuard: FC<RouteGuardProps> = ({ children, fallback }) => {
  const { workspaceSlug } = useParams();
  const { isUserAuthenticated, user } = useAuthContext();

  if (!isUserAuthenticated) {
    throw new Error('`WorkspaceRequiredGuard` should be used in an authenticated context');
  }

  const userHasWorkspaces = user.workspaces.length > 0;

  if (!userHasWorkspaces) {
    return fallback(RouteGuardState.NoWorkspaceAssigned);
  }

  const activeWorkspace = workspaceSlug ?? user.workspaces[0].slug;
  const userContainsWorkspace = user.workspaces.some(
    (workspace) => workspace.slug === activeWorkspace,
  );

  if (!userContainsWorkspace) {
    return fallback(RouteGuardState.NoWorkspaceFound);
  }

  return children;
};

export const OrganizationSlugGuard: FC<RouteGuardProps> = ({ children, fallback }) => {
  const { organizationSlug } = useParams();
  const { preferences } = useGlobalPreferences();

  if (!organizationSlug) {
    if (preferences.organizationSlug) {
      return fallback(RouteGuardState.OrganizationSlugInStorage);
    }
    return fallback(RouteGuardState.NoOrganizationSlug);
  }

  return children;
};

export const SameAuthOrganizationSlugGuard: FC<RouteGuardProps> = ({ children, fallback }) => {
  const { isUserAuthenticated } = useAuthContext();
  const { organizationSlug } = useParams();
  const { preferences } = useGlobalPreferences();

  if (!isUserAuthenticated) {
    throw new Error('`SameAuthOrganizationSlugGuard` should be used in an authenticated context');
  }

  if (!organizationSlug) {
    throw new Error('`SameAuthOrganizationSlugGuard` should be used in an organization context');
  }

  if (preferences.organizationSlug && preferences.organizationSlug !== organizationSlug) {
    return fallback(RouteGuardState.DifferentOrganizationSlug);
  }

  return children;
};

type PermissionsGuardProps = RouteGuardProps & {
  ruleset: PermissionRule[];
};

/**
 * Guard that checks if the user has the required permissions to access the route
 * or render the component.
 *
 * The `ruleset` prop is an array of that accepts permutations of permissions.
 * Permissions in the same array are treated as an AND condition. And on different
 * arrays are treated as an OR condition.
 *
 * @example
 * ```jsx
 * const OR_RULESET = [['manage-users'], ['manage-workspaces']];
 * // render fallback
 * <PermissionsGuard rule={OR_RULESET} fallback={() => <div>Forbidden</div>}>
 *   <Component />
 * </PermissionsGuard>
 *
 * const AND_RULESET = ['manage-users', 'manage-workspaces'];
 * // render null
 * <PermissionsGuard rule={AND_RULESET} fallback={() => null}>
 *   <Component />
 * </PermissionsGuard>
 * ```
 */
export const PermissionsGuard: FC<PermissionsGuardProps> = ({ children, fallback, ruleset }) => {
  const { user, isUserAuthenticated } = useAuthContext();

  if (!isUserAuthenticated) {
    throw new Error('`AdminGuard` should be used in an authenticated context');
  }

  if (!validatePermissions(ruleset, user.permissions)) {
    return fallback(RouteGuardState.ForbiddenPermissions);
  }

  return children;
};
