import { TextField } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { RHFMonkTextFieldProps } from './RHFMonkTextField.types';

const RHFMonkTextField = <TFieldValues extends FieldValues>(
  props: RHFMonkTextFieldProps<TFieldValues>,
) => {
  const { name, control, rules, defaultValue, ...muiTextFieldProps } = props;

  return (
    <Controller<TFieldValues>
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { ref, ...fieldProps } = field;
        return (
          <TextField
            {...muiTextFieldProps}
            {...fieldProps}
            inputRef={ref}
            error={fieldState.invalid}
            helperText={fieldState.error ? fieldState.error.message : muiTextFieldProps.helperText}
          />
        );
      }}
    />
  );
};

export default RHFMonkTextField;
