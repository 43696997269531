import { Chip } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const VisibilityInfo = styled(Chip)`
  display: flex;
  padding-left: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;
