/**
 * Calculates the drop index based on the  drag index, element size, drop position, and an optional offset index.
 *
 * @param {number} dragIndex - The index of the element being dragged.
 * @param {number} elementSize - The size of the element being dropped into, such as its height or width.
 * @param {number} dropPosition - The relative position of the drop event, typically a mouse or touch position.
 * @param {number} [offsetIndex=0] - An optional offset index. This is used when the index does not start at 0,
 *                                   such as when you drop an element in a menu where the index starts at the count
 *                                   of visible items. (e.g. More button in a bookmarks menu)
 * @returns {number} - The calculated drop index.
 */
export const calculateDropIndex = (
  dragIndex: number,
  elementSize: number,
  dropPosition: number,
  offsetIndex = 0,
) => {
  /**
   * The `adjustToEdgeFactor` is used to fine-tune the calculation of the drop index. It shifts the calculated index
   * to better reflect whether the drop should be considered closer to the next or previous index based on the relative
   * position within the drop element. By default, this factor is set to 0.5, meaning the drop index will change when the drop
   * position crosses the midpoint of the drop element size. This helps in making the drop action feel more natural and
   * intuitive for the user.
   */
  const adjustToEdgeFactor = 0.5;
  let dropIndex = Math.floor(dropPosition / elementSize - adjustToEdgeFactor) + offsetIndex;
  if (dragIndex > dropIndex) {
    dropIndex = Math.floor(dropPosition / elementSize + adjustToEdgeFactor) + offsetIndex;
  }

  return dropIndex;
};
