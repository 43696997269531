import { Button } from '@mui/material';
import styled, { css } from 'styled-components';

export const TabButton = styled(Button)`
  ${({ theme, variant }) =>
    variant === 'text' &&
    css`
      color: ${theme.palette.text.secondary};
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const DescriptionToolsContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(1, 1fr);

  ${({ theme }) => {
    return css`
      @media (min-width: ${theme.breakpoints.values.lg}px) {
        grid-template-columns: repeat(2, 1fr);
      }
    `;
  }}
`;

export const Card = styled.div`
  align-self: start;
  padding: 24px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.surface.level1};
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.02);
`;

export const CardTitle = styled.h2<{ $isLoading?: boolean }>`
  margin: 0 auto 16px;
  color: ${({ $isLoading, theme }) =>
    $isLoading ? theme.palette.text.disabled : theme.palette.text.primary};
  /* utility ♋/title/medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.1px;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
  /* utility ♋/body/medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const CreatedBy = styled.p`
  margin: 16px auto 0;
  color: ${({ theme }) => theme.palette.text.secondary};
  /* utility ♋/body/small */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PinnedToolsCard = styled(Card)`
  container-type: inline-size;
`;

export const ToolsContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);

  @container (min-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @container (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 32px;
  background-color: ${({ theme }) => theme.palette.surface.dividerMain};
`;
