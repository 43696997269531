import { IApp } from 'api/platformApi/platformApi.types';

type IntentType = 'openProject' | 'openWorkspace';

interface IntentParams {
  openProject: { workspaceSlug: string; projectId: string };
  openWorkspace: { workspaceSlug: string };
}

function createIntentUrl<T extends IntentType>(
  app: IApp,
  intentType: T,
  params: IntentParams[T],
): string | null {
  const template = app.manifest?.intents?.[intentType];
  if (!template) return null;

  let resolvedUrl = template;

  if ('workspaceSlug' in params) {
    resolvedUrl = resolvedUrl.replace(':workspace-slug', params.workspaceSlug);
  }

  if ('projectId' in params) {
    resolvedUrl = resolvedUrl.replace(':project-id', params.projectId);
  }

  return `${app.hostedUrl}${resolvedUrl}`;
}

export default createIntentUrl;
