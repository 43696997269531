import {
  Alert,
  Button,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  Typography,
  TypographyProps,
} from '@mui/material';
import AppDrawerFooter from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const UserCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.palette.surface.level2};
  padding: 12px;
  border-radius: 16px;
`;

export const Avatar = styled.div`
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-radius: 50%;
  font-size: 14px;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.common.black};
`;

export const UserDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 12px;
`;

export const PermissionsRevokedAlert = styled(Alert)`
  background-color: #fff4e5;
  color: #663c00;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin: ${({ theme }) => theme.spacing(1, 0, 0, 0)};
`;

export const Checkbox = styled(MuiCheckbox)`
  padding: ${({ theme }) => theme.spacing(0.5)};
`;

export const WorkspacesContainer = styled.div`
  margin-top: 32px;
`;

export const WorkspaceLogo = styled.img`
  border-radius: 8px;
  margin-right: 12px;
`;

export const DefaultWorkspaceLogo = styled.div<{ $width: number; $height: number }>`
  height: ${({ $width }) => $width}px;
  width: ${({ $height }) => $height}px;
  background: #d9d9d9;
  border-radius: 8px;
  margin-right: 12px;
`;

export const NoOptionsText = styled(Typography)<TypographyProps>`
  text-align: center;
  padding: 32px 0px;
`;

export const SelectedWorkspacesContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.surface.dividerMain};
  border-radius: 0px 0px 8px 8px;

  & > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.surface.dividerMain};
  }
`;

export const SelectedWorkspaceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;

export const SelectedWorkspaceDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomAppDrawerFooter = styled(AppDrawerFooter)`
  justify-content: end;
`;

export const SaveChangesButton = styled(Button)`
  min-height: 42px;
  min-width: 155px;
`;
