import Menu from '@mui/material/Menu/Menu';
import useAuthContext from 'contexts/auth/useAuthContext';
import React from 'react';
import { useGlobalPreferences } from 'utils/preferences/useGlobalPreferences';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import * as Styled from './WorkspaceSelector.styles';

const WorkspaceSelector = () => {
  const { save } = useGlobalPreferences();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { user } = useAuthContext();
  const activeWorkspace = useActiveWorkspace();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSwitchWorkspace = (newWorkspaceSlug: string) => {
    handleCloseMenu();
    save({ workspaceSlug: newWorkspaceSlug }, false);
    window.location.assign(`/${newWorkspaceSlug}`);
  };

  const defaultMediaMonksLogoUrl = '/workspace-icons/media-monks-general.png';

  if (!user || user.workspaces.length === 0) {
    return null;
  }

  const workspaces = user.workspaces.filter(
    (workspace) => workspace.slug !== activeWorkspace?.slug,
  );

  if (workspaces.length === 0) {
    return (
      <Styled.Button id="workspace-button" variant="text" size="small" disableElevation>
        <Styled.WorkspaceImage src={activeWorkspace?.logoUrl ?? defaultMediaMonksLogoUrl} />
      </Styled.Button>
    );
  }

  return (
    <>
      <Styled.Button
        id="workspace-button"
        aria-controls={open ? 'workspace-menu-selector' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        size="small"
        disableElevation
        onClick={handleClick}
      >
        <Styled.WorkspaceImage src={activeWorkspace?.logoUrl ?? defaultMediaMonksLogoUrl} />
      </Styled.Button>

      <Menu
        id="workspace-menu-selector"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'workspace-button',
        }}
      >
        {workspaces.map((workspace) => {
          return (
            <Styled.MenuItem
              key={workspace.id}
              onClick={() => {
                handleSwitchWorkspace(workspace.slug);
              }}
            >
              <Styled.WorkspaceImage src={workspace.logoUrl ?? defaultMediaMonksLogoUrl} />

              <Styled.WorkspaceName>{workspace.name}</Styled.WorkspaceName>
            </Styled.MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default WorkspaceSelector;
