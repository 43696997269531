import { TableCell, Typography } from '@mui/material';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import styled from 'styled-components';

export const CustomMFTableHeadCell = styled(MFTableHeadCell)`
  width: 33%;
`;

export const NameContent = styled.div`
  display: flex;
  align-items: center;
`;

export const WorkspaceLogo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 25%;
`;

export const Name = styled(Typography)`
  margin-left: 12px;
`;

export const DescriptionTableCell = styled(TableCell)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
`;

export const DescriptionTypography = styled(Typography)``;
