import { Box } from '@mui/material';
import { Grid2Props } from '@mui/material/Unstable_Grid2';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { PropsWithChildren } from 'react';

export const GridContainer = ({ children, ...props }: PropsWithChildren<Grid2Props>) => {
  return (
    <Grid container {...props}>
      {children}
    </Grid>
  );
};

export const GridItem = ({ children, ...props }: PropsWithChildren<Grid2Props>) => {
  return (
    <Grid {...props}>
      <Box borderRadius={4} height={1} overflow="hidden">
        {children}
      </Box>
    </Grid>
  );
};
