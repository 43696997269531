import { lighten } from '@mui/material';
import MuiButtonBase from '@mui/material/ButtonBase';
import monksFlowLogoBg from 'assets/img/monks.flow-logo-bg.svg';
import styled from 'styled-components';

export const Container = styled(MuiButtonBase)`
  width: 100%;
  height: 160px;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  background: #fff url(${monksFlowLogoBg}) no-repeat left -40px bottom -40px / 302px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.02);
  overflow: hidden;
  transition:
    background-color 300ms ease-out,
    background-size 300ms ease-out,
    box-shadow 300ms ease-out;

  && .MuiTouchRipple-child {
    background-color: ${({ theme }) => lighten(theme.palette.primary.main, 0.85)};
  }

  &:hover {
    background-color: ${({ theme }) => lighten(theme.palette.primary.main, 0.96)};
    background-size: 324px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
  }
`;

export const ProjectName = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  /* utility ♋/title/large */
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
`;

export const ProjectOwner = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.text.secondary};
  /* utility ♋/body/small */
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
