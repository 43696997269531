import { isAxiosError } from 'axios';

export const getViewProjectError = (error: unknown) => {
  if (isAxiosError(error) && error.response?.status === 404) {
    return {
      title: 'Project not available',
      description: 'The project you are trying to access may be restricted or may not exist.',
    };
  }

  return {
    title: 'Project failed to load',
    description:
      'An error occurred and the project could not be loaded. Please try refreshing the page to access the project.',
  };
};
