import platformApi from 'api/platformApi/platformApi';
import {
  IUpdateWorkspaceProjectBookmarksServiceBody,
  IUpdateWorkspaceProjectBookmarksResponse,
} from 'api/platformApi/platformApi.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const updateWorkspaceProjectBookmarks = (
  workspaceId: string,
  projectId: string,
  bookmarksData: IUpdateWorkspaceProjectBookmarksServiceBody,
  axiosConfig?: AxiosRequestConfig,
) => {
  return platformApi.put<
    IUpdateWorkspaceProjectBookmarksResponse,
    AxiosResponse<IUpdateWorkspaceProjectBookmarksResponse>,
    IUpdateWorkspaceProjectBookmarksServiceBody
  >(`/workspaces/${workspaceId}/projects/${projectId}/bookmarks`, bookmarksData, axiosConfig);
};
