import { useMutation } from '@tanstack/react-query';
import { uploadImageService } from './uploadImageService';

function useUploadImage() {
  return useMutation({
    mutationFn(image: File) {
      return uploadImageService({ body: { file: image } });
    },
  });
}

export default useUploadImage;
