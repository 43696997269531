import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useDeleteWorkspaceUser from 'api/platformApi/services/deleteWorkspaceUserService/useDeleteWorkspaceUser';
import useWorkspaceUsers from 'api/platformApi/services/workspaceUsersService/useWorkspaceUsers';
import { useWorkspace } from 'api/platformApi/services/workspacesService/hooks';

interface RemoveWorkspaceUserConfirmationDialogProps {
  workspaceId?: string;
  userId?: string;
  onCancel: () => void;
  onRemove: (workspaceName: string, userEmail: string) => void;
  open: boolean;
}

const RemoveWorkspaceUserConfirmationDialog = ({
  workspaceId,
  userId,
  onCancel,
  onRemove,
  open,
}: RemoveWorkspaceUserConfirmationDialogProps) => {
  const { data: workspace } = useWorkspace({ workspaceId });
  const { data: workspaceUsers = [] } = useWorkspaceUsers({ workspaceId });

  const workspaceName = workspace?.name ?? 'Workspace';
  const workspaceUser = workspaceUsers.find((user) => user.id === userId);
  const userEmail = workspaceUser?.email ?? 'user@workspace.com';

  const deleteWorkspaceUserMutation = useDeleteWorkspaceUser();

  const removeUser = () => {
    if (!workspaceId || !userId) return;

    deleteWorkspaceUserMutation.mutate({
      workspaceId,
      userId,
    });
    onRemove(workspaceName, userEmail);
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="remove-workspace-user-dialog-title"
      aria-describedby="remove-workspace-user-dialog-description"
    >
      <DialogTitle id="remove-workspace-user-dialog-title">
        Remove user from {workspaceName}
      </DialogTitle>

      <DialogContent id="remove-workspace-user-dialog-description">
        If you remove {userEmail} from this workspace, they will no longer have access to its
        content. Would you like to proceed?
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} type="button" variant="text" color="black">
          Cancel
        </Button>

        <Button onClick={removeUser} variant="contained">
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveWorkspaceUserConfirmationDialog;
