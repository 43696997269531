import { IconButton as MuiIconButton, Popover as MuiPopover } from '@mui/material';
import styled from 'styled-components';

export const IconButton = styled(MuiIconButton)`
  background-color: ${({ theme }) => theme.palette.common.white};
}
  `;

export const Popover = styled(MuiPopover)`
  margin-left: 8px;

  & .MuiPopover-paper {
    max-width: 400px;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
  }
`;

export const Title = styled.h2`
  margin: 0 0 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: -0.1px;
`;

export const AccentText = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;
