import Loader from 'components/Loader/Loader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { authenticateUser, organizationLogin, redirectToLogout, refreshToken } from 'utils/auth';
import { useGlobalPreferences } from 'utils/preferences/useGlobalPreferences';
import AuthContext from './AuthContext';
import { AugmentedAuthenticatedUserResponseDto, IAuthContext } from './AuthContext.types';
import { AuthProviderProps } from './AuthProvider.types';

const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { read, save } = useGlobalPreferences();
  const [user, setUser] = useState<Maybe<AugmentedAuthenticatedUserResponseDto>>();
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);

  const checkLoginStatus = useCallback(async () => {
    const user = (await authenticateUser()) as AugmentedAuthenticatedUserResponseDto | undefined;

    if (!user) {
      const enabledRefreshToken = read('enableRefreshToken');
      const refreshAuthStatus = enabledRefreshToken && (await refreshToken());

      if (refreshAuthStatus) {
        void checkLoginStatus();
        return;
      }

      save({ enableRefreshToken: false });
      setUser(undefined);
      setIsAuthLoaded(true);

      return;
    }

    save({ enableRefreshToken: true });
    setUser(user);
    setIsAuthLoaded(true);
  }, [read, save]);

  const providerValue = useMemo(() => {
    const controls = {
      login: organizationLogin,
      logout() {
        save({ enableRefreshToken: false });
        redirectToLogout();
      },
    };

    // unauthenticated context
    if (user === undefined) {
      const unauthedValue: IAuthContext = {
        ...controls,
        state: 'unauthenticated',
        isUserAuthenticated: false,
        user: undefined,
        checkLoginStatus,
      };

      return unauthedValue;
    }

    const authedValue: IAuthContext = {
      ...controls,
      state: 'authenticated',
      isUserAuthenticated: true,
      user,
      checkLoginStatus,
    };

    return authedValue;
  }, [checkLoginStatus, user, save]);

  useEffect(() => {
    void checkLoginStatus();
  }, [checkLoginStatus]);

  return (
    <AuthContext.Provider value={providerValue}>
      {isAuthLoaded ? props.children : <Loader />}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
