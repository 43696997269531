import HomeIcon from '@mui/icons-material/HomeRounded';
import AppHeader from 'components/AppHeader/AppHeader';
import SideNav from 'components/SideNav/SideNav';
import { NavItem } from 'components/SideNav/SideNav.types';
import useAuthContext from 'contexts/auth/useAuthContext';
import useResetScrollPosition from 'hooks/useResetScrollPosition';
import { Outlet, useParams } from 'react-router-dom';
import { routes } from 'utils/routes';
import { getUserMenuActions } from 'utils/userMenuActions';
import * as Styled from './LegalInformationLayout.styles';

const LegalInformationLayout = () => {
  const { user, logout } = useAuthContext();
  const { workspaceSlug } = useParams();
  const activeWorkspaceSlug = workspaceSlug ?? '';
  const userActions = getUserMenuActions(user);
  const scrollableContainerRef = useResetScrollPosition();

  const navItems: NavItem[] = [
    {
      label: 'Home',
      icon: <HomeIcon />,
      route: routes.dashboard(activeWorkspaceSlug),
    },
  ];

  return (
    <main>
      <AppHeader flow="monks.flow" user={user} onSignOutClick={logout} userActions={userActions} />

      <Styled.Container>
        <SideNav navItems={navItems} />

        <Styled.ContentLayout ref={scrollableContainerRef}>
          <Styled.Content>
            <Outlet />
          </Styled.Content>
        </Styled.ContentLayout>
      </Styled.Container>
    </main>
  );
};

export default LegalInformationLayout;
