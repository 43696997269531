import { ToggleButton, toggleButtonClasses } from '@mui/material';
import styled from 'styled-components';

export const ProjectsViewToggleButton = styled(ToggleButton)`
  background-clip: padding-box;
  border: 1px solid ${(props) => props.theme.palette.surface.dividerMain};
  border-radius: ${(props) => props.theme.shape.borderRadius * 2}px;
  color: ${(props) => props.theme.palette.action.disabled};
  padding: ${(props) => props.theme.spacing(0.5)};

  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &:hover {
    background-color: ${(props) => props.theme.palette.common.blackHover};
  }

  &.${toggleButtonClasses.selected} {
    background-color: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};

    &:hover {
      background-color: ${(props) => props.theme.palette.common.black};
    }
  }
`;
