import { Box, Pagination, Table, Typography } from '@mui/material';
import useUsers from 'api/platformApi/services/usersService/useUsers';
import ManageUserDrawer from 'components/ManageUserDrawer/ManageUserDrawer';
import StopEventPropagation from 'components/StopEventPropagation';
import ListSkeleton from 'components/ui/ListSkeleton/ListSkeleton';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import { ITableOrderDirection } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import MFTableHeadCellSortable from 'components/ui/MFTableHeadCellSortable/MFTableHeadCellSortable';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import PageFeedback from 'components/ui/PageFeedback/PageFeedback';
import { PaginationContainer } from 'components/ui/PaginationContainer/PaginationContainer.styles';
import useAuthContext from 'contexts/auth/useAuthContext';
import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { generateStringFromArray } from 'utils/arraryString';
import { PERMISSIONS, validatePermissions } from 'utils/permissions';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_PROPERTY,
  HEAD_CELLS_SX,
  headCells,
  MIN_SEARCH_LENGTH,
} from './AccountsList.consts';
import * as Styled from './AccountsList.styles';
import { AccountsListProps, IUsersOrderBy, IUserWithWorkspacesString } from './AccountsList.types';
import UserOptions from './components/UserOptions/UserOptions';

const AccountsList: FC<AccountsListProps> = ({ searchValue }) => {
  const [searchParams] = useSearchParams();

  const [pageNumber, setPageNumber] = useState<number>(
    Number(searchParams.get('page') ?? DEFAULT_PAGE_NUMBER),
  );
  const [sortProperty, setSortProperty] = useState<IUsersOrderBy>(
    (searchParams.get('sort') ?? DEFAULT_SORT_PROPERTY) as IUsersOrderBy,
  );
  const [sortDirection, setSortDirection] = useState<ITableOrderDirection>(
    (searchParams.get('direction') ?? DEFAULT_SORT_DIRECTION) as ITableOrderDirection,
  );

  // Sync local state to URL without causing a re-render
  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('page', pageNumber.toString());
    url.searchParams.set('sort', sortProperty);
    url.searchParams.set('direction', sortDirection);
    window.history.pushState(null, '', url.toString());
  }, [pageNumber, sortProperty, sortDirection]);

  // Reset page number when search value changes
  useEffect(() => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
  }, [searchValue]);

  const { user } = useAuthContext();
  const canManageUsers = validatePermissions([PERMISSIONS.PlatformManageUsers], user?.permissions);
  const [selectedUser, setSelectedUser] = useState<IUserWithWorkspacesString>();
  const validSearchTerm = searchValue.length >= MIN_SEARCH_LENGTH ? searchValue : '';
  const { data, isError, isLoading } = useUsers({
    search: validSearchTerm,
    sortProperty,
    direction: sortDirection === 'asc' ? 'ASC' : 'DESC',
    pageNumber,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const { data: users = [], pagination } = data ?? {};
  const usersWithWorkspaces: IUserWithWorkspacesString[] = users.map((user) => {
    const workspacesString = generateStringFromArray(user.workspaces, 1, (ws) => ws.name);
    return {
      ...user,
      workspacesString,
    };
  });

  const handleSort = (newOrderBy: IUsersOrderBy) => {
    const newDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    setSortProperty(newOrderBy);
    setSortDirection(newDirection);
    setPageNumber(DEFAULT_PAGE_NUMBER);
  };

  const handlePageChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPageNumber(newPage);
  };

  if (isLoading) {
    return <ListSkeleton headCells={headCells} rows={8} />;
  }

  if (isError) {
    return (
      <PageFeedback
        title="Users failed to load"
        description="An error occurred and the user list could not be loaded. Please try refreshing the page to access."
      />
    );
  }

  if (users.length === 0) {
    if (validSearchTerm) {
      return (
        <PageFeedback
          title="No search results"
          description={`No results for "${validSearchTerm}". Please check your search query and try again.`}
        />
      );
    }

    return (
      <PageFeedback
        title="No users found"
        description="Invite users to join the organization. They will appear here once they accept the invitation and set up their accounts."
      />
    );
  }

  return (
    <>
      <MFTableContainer>
        <Table>
          <MFTableHead>
            <MFTableHeadRow>
              {headCells.map((headCell) => {
                if (headCell.sortable) {
                  return (
                    <MFTableHeadCellSortable
                      key={headCell.id}
                      // Currently, there is only one sortable headCell, so the condition is unnecessary.
                      // Disable eslint to ensure that this code will function correctly when additional sortable headCells are added.
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      active={headCell.id === sortProperty}
                      direction={sortDirection}
                      onSort={() => {
                        handleSort(headCell.id);
                      }}
                      sx={HEAD_CELLS_SX}
                    >
                      {headCell.label}
                    </MFTableHeadCellSortable>
                  );
                } else {
                  return (
                    <MFTableHeadCell key={headCell.id} sx={HEAD_CELLS_SX}>
                      {headCell.label}
                    </MFTableHeadCell>
                  );
                }
              })}
            </MFTableHeadRow>
          </MFTableHead>

          <MFTableBody>
            {usersWithWorkspaces.map((user) => (
              <MFTableBodyRow
                key={user.id}
                onClick={() => {
                  if (canManageUsers) {
                    setSelectedUser(user);
                  }
                }}
                sx={{ cursor: canManageUsers ? 'pointer' : 'default' }}
              >
                <Styled.UserCell>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <Styled.Avatar>{user.name.charAt(0).toUpperCase()}</Styled.Avatar>
                      <Styled.Name variant="bodyMedium">{user.name}</Styled.Name>
                    </Box>

                    {user.role === 'admin' && (
                      <Styled.Chip label="Admin" size="small" color="info" />
                    )}
                  </Box>
                </Styled.UserCell>
                <Styled.UserCell>
                  <Typography variant="bodyMedium">{user.email}</Typography>
                </Styled.UserCell>
                <Styled.UserCell>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="bodyMedium">{user.workspacesString}</Typography>

                    <StopEventPropagation eventTypes={['onClick']}>
                      <UserOptions
                        onClickEdit={() => {
                          setSelectedUser(user);
                        }}
                      />
                    </StopEventPropagation>
                  </Box>
                </Styled.UserCell>
              </MFTableBodyRow>
            ))}
          </MFTableBody>
        </Table>
      </MFTableContainer>

      <PaginationContainer>
        <Pagination
          count={pagination?.pagesCount}
          page={pagination?.pageNumber}
          onChange={handlePageChange}
        />
      </PaginationContainer>

      <ManageUserDrawer
        open={Boolean(selectedUser)}
        onClose={() => {
          setSelectedUser(undefined);
        }}
        user={selectedUser}
      />
    </>
  );
};

export default AccountsList;
