export const PROJECT_VISIBILITY_OPTIONS: {
  value: 'workspace' | 'private';
  label: string;
  description: string;
}[] = [
  {
    value: 'workspace',
    label: 'Workspace',
    description: 'Accessible to all members of this workspace',
  },
  {
    value: 'private',
    label: 'Invite-only',
    description: 'Accessible to invited members only',
  },
];
