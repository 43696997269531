import platformApi from 'api/platformApi/platformApi';
import { IDeleteOrganizationAuthMethodServiceParamsPath } from 'api/platformApi/platformApi.types';

export const deleteOrganizationAuthMethodService = async ({
  params: {
    path: { organizationId, methodId },
  },
}: ServiceFnConfig<IDeleteOrganizationAuthMethodServiceParamsPath>) => {
  await platformApi.delete(`/organizations/${organizationId}/sso-methods/${methodId}`);
};
