import { Skeleton, Table, TableBody, TableCell } from '@mui/material';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import { FC } from 'react';
import * as Styled from './ListSkeleton.styles';
import { ListSkeletonProps } from './ListSkeleton.types';

const ListSkeleton: FC<ListSkeletonProps> = (props) => {
  return (
    <>
      <MFTableContainer>
        <Table>
          <MFTableHead>
            <MFTableHeadRow>
              {props.headCells.map((headCell) => (
                <Styled.ListSkeletonMFTableHeadCell
                  variant="head"
                  key={headCell.id}
                  $cellCount={props.headCells.length}
                >
                  {headCell.label}
                </Styled.ListSkeletonMFTableHeadCell>
              ))}
            </MFTableHeadRow>
          </MFTableHead>

          <TableBody>
            {[...Array(props.rows).keys()].map((rowKey) => (
              <MFTableBodyRow key={rowKey}>
                {props.headCells.map((headCell) => (
                  <TableCell key={headCell.id}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
              </MFTableBodyRow>
            ))}
          </TableBody>
        </Table>
      </MFTableContainer>
    </>
  );
};

export default ListSkeleton;
