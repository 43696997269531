import { RefreshOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { GridContainer, GridItem } from 'components/ui/Grid/Grid';
import { FC } from 'react';
import SolutionItem from '../SolutionItem/SolutionItem';
import { SolutionsGridProps } from './SolutionsGrid.types';

const SolutionsGrid: FC<SolutionsGridProps> = ({
  solutions,
  isError,
  isLoading,
  onSolutionClick,
  onRetry,
}) => {
  if (isError) {
    return (
      <Box display="flex" flexDirection="column" gap={1} alignItems="center" p={2}>
        <Typography variant="titleSmall">Solutions couldn’t be loaded at this time.</Typography>
        <Button variant="text" size="small" onClick={onRetry} startIcon={<RefreshOutlined />}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <GridContainer spacing={{ xs: 2, sm: 3 }}>
      {solutions.map((solution) => (
        <GridItem key={solution.id} xs={12} sm={6} lg={3}>
          <SolutionItem
            name={solution.name}
            description={solution.description}
            onClick={() => {
              onSolutionClick(solution);
            }}
            isLoading={isLoading}
          />
        </GridItem>
      ))}
    </GridContainer>
  );
};

export default SolutionsGrid;
