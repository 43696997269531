import styled from 'styled-components';

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.surface.level1};
  border-radius: ${({ theme }) => theme.spacing(2)};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.02);
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const NoSolutionsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(4, 0)};
`;
