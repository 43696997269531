import platformApi from 'api/platformApi/platformApi';
import 'api/platformApi/platformApi.types';
import { AxiosResponse } from 'axios';
import {
  ICreateWorkspaceServiceRequestBody,
  ICreateWorkspaceServiceResponse,
  IUpdateWorkspaceServiceParamsPath,
  IUpdateWorkspaceServiceRequestBody,
  IUpdateWorkspaceServiceResponse,
  IWorkspaceServiceParamsPath,
  IWorkspaceServiceResponse,
  IWorkspacesServiceResponse,
} from '../../platformApi.types';
import { CreateWorkspaceOperationError, UpdateWorkspaceOperationError } from './errors';

export const WorkspacesServiceEndpoints = {
  root: 'workspaces' as const,
  resources() {
    return `/${this.root}` as const;
  },
  resource({ workspaceId }: { workspaceId: string }) {
    return `${this.resources()}/${workspaceId}` as const;
  },
};

export const getWorkspaces = async () => {
  const response = await platformApi.get<IWorkspacesServiceResponse>(
    WorkspacesServiceEndpoints.resources(),
  );
  return response.data;
};

export const getWorkspace = async ({
  params: {
    path: { workspaceId },
  },
}: ServiceFnConfig<IWorkspaceServiceParamsPath>) => {
  const axiosResponse = await platformApi.get<IWorkspaceServiceResponse>(
    WorkspacesServiceEndpoints.resource({ workspaceId }),
  );
  const apiResponse = axiosResponse.data;
  const apiData = apiResponse;

  return apiData;
};

export const createWorkspace = async ({
  body,
}: ServiceFnConfig<undefined, undefined, ICreateWorkspaceServiceRequestBody>) => {
  try {
    const response = await platformApi.post<
      ICreateWorkspaceServiceResponse,
      AxiosResponse<ICreateWorkspaceServiceResponse>,
      ICreateWorkspaceServiceRequestBody
    >(WorkspacesServiceEndpoints.resources(), body);

    return response.data;
  } catch (error) {
    const apiError = CreateWorkspaceOperationError.unwrapAPIError(error);
    if (apiError) throw new CreateWorkspaceOperationError(apiError);
    throw error;
  }
};

export const updateWorkspace = async ({
  params: {
    path: { workspaceId },
  },
  body,
}: ServiceFnConfig<
  IUpdateWorkspaceServiceParamsPath,
  undefined,
  IUpdateWorkspaceServiceRequestBody
>) => {
  try {
    const response = await platformApi.put<
      IUpdateWorkspaceServiceResponse,
      AxiosResponse<IUpdateWorkspaceServiceResponse>,
      IUpdateWorkspaceServiceRequestBody
    >(WorkspacesServiceEndpoints.resource({ workspaceId }), body);

    return response.data;
  } catch (error) {
    const apiError = UpdateWorkspaceOperationError.unwrapAPIError(error);
    if (apiError) throw new UpdateWorkspaceOperationError(apiError);
    throw error;
  }
};
