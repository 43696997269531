import { useQuery } from '@tanstack/react-query';
import { getOrganizations } from './organizationsService';

export const ORGANIZATIONS_QUERY_KEY_PREFIX = 'organizations';

export const getOrganizationsQueryKey = () => [ORGANIZATIONS_QUERY_KEY_PREFIX];

function useOrganizations() {
  return useQuery({
    queryKey: getOrganizationsQueryKey(),
    queryFn: getOrganizations,
  });
}

export default useOrganizations;
