import { Box, Skeleton } from '@mui/material';
import VisibilityAvatar from 'components/VisibilityAvatar/VisibilityAvatar';
import * as Styled from './ProjectCard.styles';
import { ProjectCardProps } from './ProjectCard.types';

const ProjectCard = ({ name, visibility, owner, isLoading }: ProjectCardProps) => {
  return (
    <Styled.Container>
      {isLoading ? (
        <>
          <Skeleton variant="rounded" width="100%" height={28} />
          <Skeleton variant="rounded" width="60%" height={16} />
        </>
      ) : (
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="end">
          <Box>
            <Styled.ProjectName>{name}</Styled.ProjectName>
            <Styled.ProjectOwner>Created by {owner}</Styled.ProjectOwner>
          </Box>
          <VisibilityAvatar visibility={visibility} />
        </Box>
      )}
    </Styled.Container>
  );
};

export default ProjectCard;
