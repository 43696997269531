import { ChangeEvent, FC, cloneElement, useRef } from 'react';
import * as Styled from './ImageFileChooser.styles';
import { ImageFileChooserProps } from './ImageFileChooser.types';
import { validateFile } from './ImageFileChooser.utils';

const ImageFileChooser: FC<ImageFileChooserProps> = ({
  children,

  onChoose,
  onError,
  onValidating,

  ...requirements
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const openFileExplorer = () => inputRef.current?.click();
  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const file = event.target.files[0];

    onValidating?.();

    const errors = await validateFile(file, requirements);

    if (errors.length > 0) {
      onError?.(errors);
    } else {
      onChoose?.(file);
    }
  };

  return (
    <>
      <Styled.VisuallyHiddenInput
        id="logo"
        ref={inputRef}
        name="logo"
        type="file"
        accept={requirements.types ? requirements.types.join(',') : undefined}
        onChange={handleChangeFile}
      />

      {cloneElement(children, { onClick: openFileExplorer })}
    </>
  );
};

export default ImageFileChooser;
