import { AddOutlined } from '@mui/icons-material';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { Button, IconButton, Table, TableCell, Tooltip, Typography } from '@mui/material';
import { IUser } from 'api/platformApi/platformApi.types';
import { useWorkspaceMetrics } from 'api/platformApi/services/workspaceMetricsService/hooks';
import useWorkspaceUsers from 'api/platformApi/services/workspaceUsersService/useWorkspaceUsers';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import { ITableOrderDirection } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import MFTableHeadCellSortable from 'components/ui/MFTableHeadCellSortable/MFTableHeadCellSortable';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC, useReducer, useState } from 'react';
import { formatRelativeDate } from 'utils/dates';
import AddWorkspaceUsersDrawer from '../AddWorkspaceUsersDrawer/AddWorkspaceUsersDrawer';
import RemoveWorkspaceUserConfirmationDialog from '../RemoveWorkspaceUserConfirmationDialog';
import { headCells } from './WorkspaceMembers.consts';
import * as Styled from './WorkspaceMembers.styles';
import { MembersTableSortableCells, WorkspaceMembersProps } from './WorkspaceMembers.types';
import { getComparator } from './WorkspaceMembers.utils';

const WorkspaceMembers: FC<WorkspaceMembersProps> = ({ workspaceId }) => {
  const snackbar = useSnackbarContext();
  const [orderBy, setOrderBy] = useState<MembersTableSortableCells['id']>('name');
  const [orderDirection, setOrderDirection] = useState<ITableOrderDirection>('asc');
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [isAddUsersDrawerOpen, setIsAddUsersDrawerOpen] = useState(false);

  const [userIdToRemove, setUserIdToRemove] = useState<IUser['id']>();
  const [isRemoveConfirmationOpen, toggleRemoveConfirmation] = useReducer((state) => !state, false);

  const { data: users = [] } = useWorkspaceUsers({ workspaceId });
  const { data: metrics } = useWorkspaceMetrics({ workspaceId });
  const sortedUsers = users.toSorted(getComparator(orderDirection, orderBy));

  const handleUserSelection = (user: IUser) => {
    setSelectedUser(user);
  };

  return (
    <>
      <Styled.CustomMFTableContainer>
        <Styled.TitleHeader>
          <Typography component="h2" variant="titleMedium">
            Members ({metrics?.totalMembers ?? 0})
          </Typography>

          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              setIsAddUsersDrawerOpen(true);
            }}
            startIcon={<AddOutlined />}
          >
            Add users
          </Button>

          <AddWorkspaceUsersDrawer
            workspaceId={workspaceId}
            open={isAddUsersDrawerOpen}
            onClose={() => {
              setIsAddUsersDrawerOpen(false);
            }}
          />
        </Styled.TitleHeader>
        <Table>
          <MFTableHead>
            <MFTableHeadRow>
              {headCells.map((headCell) =>
                headCell.sortable ? (
                  <MFTableHeadCellSortable
                    key={headCell.id}
                    onSort={() => {
                      setOrderBy(headCell.id);
                      setOrderDirection((orderDirection) =>
                        orderDirection === 'desc' ? 'asc' : 'desc',
                      );
                    }}
                    active={headCell.id === orderBy}
                    direction={orderDirection}
                  >
                    {headCell.label}
                  </MFTableHeadCellSortable>
                ) : (
                  <MFTableHeadCell key={headCell.id}>{headCell.label}</MFTableHeadCell>
                ),
              )}
            </MFTableHeadRow>
          </MFTableHead>

          <MFTableBody>
            {sortedUsers.map((user) => (
              <MFTableBodyRow
                onClick={() => {
                  handleUserSelection(user);
                }}
                selected={user.id === selectedUser?.id}
                key={user.id}
              >
                <TableCell>
                  <Styled.NameContent>
                    <Styled.Avatar>{user.name.charAt(0).toUpperCase()}</Styled.Avatar>
                    <Styled.Name variant="bodyMedium">{user.name}</Styled.Name>
                  </Styled.NameContent>
                </TableCell>

                <TableCell>
                  <Typography variant="bodyMedium">{user.email}</Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="bodyMedium" color="text.secondary">
                    {formatRelativeDate(user.addedToWorkspaceAt)}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Tooltip title="Remove">
                    <IconButton
                      color="error"
                      onClick={() => {
                        setUserIdToRemove(user.id);
                        toggleRemoveConfirmation();
                      }}
                    >
                      <DoNotDisturbOnOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </MFTableBodyRow>
            ))}
          </MFTableBody>
        </Table>
      </Styled.CustomMFTableContainer>

      <RemoveWorkspaceUserConfirmationDialog
        workspaceId={workspaceId}
        userId={userIdToRemove}
        onCancel={() => {
          toggleRemoveConfirmation();
        }}
        onRemove={(workspaceName, userEmail) => {
          toggleRemoveConfirmation();
          snackbar.success(`${userEmail} removed from ${workspaceName}`);
        }}
        open={isRemoveConfirmationOpen}
      />
    </>
  );
};

export default WorkspaceMembers;
