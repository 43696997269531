import { Table, TableSortLabel } from '@mui/material';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableContainer from 'components/ui/MFTableContainer/MFTableContainer';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import {
  ITableHeadCell,
  ITableOrderDirection,
} from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import { PropsWithChildren } from 'react';
import * as Styled from '../../ProjectFiles.styles';
import { IProjectFilesOrderBy } from '../../ProjectFiles.types';

const headCells = [
  { id: 'name', label: 'Name' },
  { id: 'type', label: 'Type' },
  { id: 'createdByString', label: 'Created by' },
  { id: 'updatedAt', label: 'Last modified' },
] satisfies ITableHeadCell<IProjectFilesOrderBy>[];

export const ProjectFilesTable = ({
  children,
  onSort,
  orderedBy,
  orderedDirection,
}: PropsWithChildren<{
  onSort: (orderBy: IProjectFilesOrderBy) => void;
  orderedBy: IProjectFilesOrderBy;
  orderedDirection: ITableOrderDirection;
}>) => {
  return (
    <MFTableContainer>
      <Table>
        <MFTableHead>
          <MFTableHeadRow>
            {headCells.map((headCell) => (
              <Styled.CustomMFTableHeadCell variant="head" key={headCell.id}>
                <TableSortLabel
                  onClick={() => {
                    onSort(headCell.id);
                  }}
                  active={headCell.id === orderedBy}
                  direction={orderedDirection}
                  hideSortIcon={false}
                >
                  {headCell.label}
                </TableSortLabel>
              </Styled.CustomMFTableHeadCell>
            ))}
          </MFTableHeadRow>
        </MFTableHead>

        <MFTableBody>{children}</MFTableBody>
      </Table>
    </MFTableContainer>
  );
};

export { ProjectFilesTableRow } from './ProjectFilesTableRow';
export { ProjectFilesTableRowSkeleton } from './ProjectFilesTableRowSkeleton';
