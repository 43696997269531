import { useInfiniteQuery } from '@tanstack/react-query';
import {
  IWorkspaceProjectFilesParamsPath,
  IWorkspaceProjectFilesParamsQuery,
} from '../../platformApi.types';
import { getWorkspaceProjectFiles } from './workspaceProjectFilesService';

interface UseWorkspaceProjectFilesParams
  extends IWorkspaceProjectFilesParamsPath,
    Pick<IWorkspaceProjectFilesParamsQuery, 'app' | 'limit'> {}

export const WORKSPACE_PROJECT_FILES_QUERY_KEY_PREFIX = 'workspaceProjectFiles';

export const getWorkspaceProjectsQueryKey = (params: UseWorkspaceProjectFilesParams) => [
  WORKSPACE_PROJECT_FILES_QUERY_KEY_PREFIX,
  params.workspaceId,
  params.projectId,
  { app: params.app },
];

const useWorkspaceProjectFiles = (params: UseWorkspaceProjectFilesParams) => {
  return useInfiniteQuery({
    queryKey: getWorkspaceProjectsQueryKey(params),
    queryFn: ({ pageParam }) =>
      getWorkspaceProjectFiles({
        params: {
          path: {
            workspaceId: params.workspaceId,
            projectId: params.projectId,
          },
          query: {
            app: params.app,
            limit: params.limit ?? 15,
            nextCursor: pageParam.length > 0 ? pageParam : undefined,
          },
        },
      }),
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
};

export default useWorkspaceProjectFiles;
