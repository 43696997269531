import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IUpdateWorkspaceProjectUsersServiceBody } from 'api/platformApi/platformApi.types';
import { getWorkspaceProjectUsersQueryKey } from '../workspaceProjectUsersService/useWorkspaceProjectUsers';
import { updateWorkspaceProjectUsers } from './updateWorkspaceProjectUsersService';

function useUpdateWorkspaceProjectUsers(workspaceId: string, projectId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUpdateWorkspaceProjectUsersServiceBody) => {
      return updateWorkspaceProjectUsers(workspaceId, projectId, data.userIds);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getWorkspaceProjectUsersQueryKey(workspaceId, projectId),
      });
    },
  });
}

export default useUpdateWorkspaceProjectUsers;
