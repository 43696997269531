import { skipToken, useQuery } from '@tanstack/react-query';
import { IWorkspaceServiceParamsPath } from 'api/platformApi/platformApi.types';
import { getWorkspaceSolutions } from './workspaceSolutionsService';

export type UseWorkspaceSolutionsParams = Partial<IWorkspaceServiceParamsPath>;

export const getWorkspaceSolutionsQueryKey = (params: UseWorkspaceSolutionsParams) => [
  'workspaces',
  params.workspaceId,
  'solutions',
];

const useWorkspaceSolutions = (params: UseWorkspaceSolutionsParams) => {
  const { workspaceId } = params;

  return useQuery({
    queryKey: getWorkspaceSolutionsQueryKey(params),
    queryFn: workspaceId
      ? () => getWorkspaceSolutions({ params: { path: { workspaceId } } })
      : skipToken,
  });
};

export default useWorkspaceSolutions;
