import { DoNotDisturbOnOutlined } from '@mui/icons-material';
import { IconButton, MenuItem, Typography, Box, TextField } from '@mui/material';
import useApps from 'api/platformApi/services/appsService/useApps';
import SelectMultiple from 'components/ui/SelectMultiple/SelectMultiple';
import { FC } from 'react';
import * as Styled from './SelectApplications.styles';
import { SelectApplicationsProps } from './SelectApplications.types';

const SelectApplications: FC<SelectApplicationsProps> = ({ label, value, onChange, disabled }) => {
  const appsQuery = useApps();
  const appsOptions = appsQuery.data?.data ?? [];
  const orderedOptions = appsOptions;

  return (
    <SelectMultiple
      label={label}
      options={orderedOptions}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select applications"
          placeholder="Search..."
          variant="filled"
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          <Styled.AppLogo src={option.logo} alt={option.name} width="24" height="24" />
          {option.name}
        </MenuItem>
      )}
      renderSelectedOption={(selectedApp, onRemoveApp) => (
        <Styled.SelectedApplicationRow key={selectedApp.id}>
          <Box display="flex" alignItems="center">
            <Styled.AppLogo src={selectedApp.logo} alt={selectedApp.name} width="40" height="40" />

            <Typography component="div" variant="bodyMedium">
              {selectedApp.name}
            </Typography>
          </Box>

          {onRemoveApp && (
            <IconButton
              onClick={() => {
                onRemoveApp(selectedApp);
              }}
              color="error"
            >
              <DoNotDisturbOnOutlined />
            </IconButton>
          )}
        </Styled.SelectedApplicationRow>
      )}
      onRemoveSelectedOption={(app) => {
        onChange(value.filter((selectedApp) => selectedApp.id !== app.id));
      }}
      disabled={disabled}
    />
  );
};

export default SelectApplications;
