import { useQuery } from '@tanstack/react-query';
import { getAuthOrganizationMethods } from './authOrganizationMethodsService';

function useAuthOrganizationMethods(organizationSlug: string) {
  return useQuery({
    queryKey: ['authOrganizationMethods', { organizationSlug }],
    queryFn: () => getAuthOrganizationMethods(organizationSlug).then((r) => r.data),
  });
}

export default useAuthOrganizationMethods;
