import { FC, useCallback, useEffect } from 'react';
import * as Styled from './AppDrawer.styles';
import { AppDrawerProps } from './AppDrawer.types';

const AppDrawer: FC<AppDrawerProps> = (props) => {
  const { open, onClose, leftSidebar, children } = props;

  const handleEscapePress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (!open) return;

    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [handleEscapePress, open]);

  return (
    <Styled.Drawer anchor="right" open={open} onClose={onClose} $leftSidebar={leftSidebar}>
      {children}
    </Styled.Drawer>
  );
};

export default AppDrawer;
