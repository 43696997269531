import styled from 'styled-components';
import { breakpoint } from 'utils/styled';

export const DashboardBannerBackgroundImage = styled.div`
  background-image: linear-gradient(to right, #000 0 40%, transparent 80%),
    url('/dashboard/updated-banner.png');
  background-size: cover;
  background-position-x:
    left,
    -40vw;
  background-repeat: no-repeat;

  @media (min-width: ${breakpoint('sm')}px) {
    background-position-x: left, 8vw;
  }

  @media (min-width: ${breakpoint('md')}px) {
    background-position-x: left, 12vw;
  }

  @media (min-width: ${breakpoint('lg')}px) {
    background-position-x: left, 8vw;
  }

  @media (min-width: ${breakpoint('xl')}px) {
    background-position-x: left, right;
    background-size: contain;
  }

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

DashboardBannerBackgroundImage.displayName = 'DashboardBannerBackgroundImage';
