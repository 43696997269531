import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const WorkspaceInfoBackground = styled.div`
  backdrop-filter: blur(80px);
  background-color: ${({ theme }) => theme.palette.primary.hover};
  background-repeat: no-repeat;
  box-shadow: ${({ theme }) => theme.monksShadows.level1};

  &,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &::after {
    content: '';

    background-image: url('/monks-flow-logo.svg');
    background-repeat: no-repeat;
  }
`;

export const WorkspaceInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2.5)};
  height: 100%;
  padding: ${({ theme }) => theme.spacing(5, 6)};
  position: relative;
  width: 100%;

  ${WorkspaceInfoBackground}::after {
    transform-origin: bottom left;
    transition-duration: 800ms;
    transition-property: transform;
    transition-timing-function: ease-in-out;

    transform: scale(1);
  }

  &:hover ${WorkspaceInfoBackground}::after {
    transform: scale(1.1);
  }
`;

export const WorkspaceInfoDescription = styled(Typography)`
  --line-height: 24px;
  --line-clamp: 4;

  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 16px;
  font-weight: 500;
  height: calc(var(--line-height) * var(--line-clamp));
  line-height: var(--line-height);

  /* clamp lines and show ellipsis on overflow */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--line-clamp);
  overflow: hidden;
`;
