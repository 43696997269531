import { Button, Typography } from '@mui/material';
import useAuthOrganizationMethods from 'api/platformApi/services/authOrganizationMethodsService/useAuthOrganizationMethods';
import monksFlowLogoUrl from 'assets/img/monks.flow-logo-black.svg';
import Loader from 'components/Loader/Loader';
import useAuthContext from 'contexts/auth/useAuthContext';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGlobalPreferences } from 'utils/preferences/useGlobalPreferences';
import * as Styled from './OrganizationLoginPage.styles';
import { LoginPageLocationState } from './OrganizationLoginPage.types';

const OrganizationLoginPage = () => {
  const { login } = useAuthContext();
  const { organizationSlug = '' } = useParams();
  const [loggingIn, setLoggingIn] = useState(false);
  const location = useLocation();
  const redirectUri = (location.state as LoginPageLocationState | null)?.redirectUri;

  const { save } = useGlobalPreferences();
  const authOrganizationMethodsQuery = useAuthOrganizationMethods(organizationSlug);
  const authOrganizationMethods = authOrganizationMethodsQuery.data?.data ?? [];

  useEffect(() => {
    // the backend logout endpoint redirect the user to the homepage, but we want it to redirect to the /:organizationSlug/login
    // so we store the last user.organizationSlug in order to redirect the user to the correct organization login page
    save({ organizationSlug });
  }, [organizationSlug, save]);

  if (authOrganizationMethodsQuery.isLoading || loggingIn) {
    return <Loader />;
  }

  if (authOrganizationMethodsQuery.isError || authOrganizationMethods.length === 0) {
    return (
      <ErrorPage
        title="Something went wrong!"
        description="If you're experiencing difficulties logging in, try reloading the page or contacting your organization's administrator"
      />
    );
  }

  const handleLogin = (methodId: string) => {
    setLoggingIn(true);
    login(methodId, redirectUri);
  };

  return (
    <Styled.Main>
      <Styled.LoginContainer>
        <Styled.LoginHeader>
          <Styled.LoginHeaderImg src={monksFlowLogoUrl} alt="monks.flow" title="monks.flow" />
        </Styled.LoginHeader>
        <Styled.LoginBody>
          <Typography variant="titleLargeMd" color="text-primary">
            Sign In to your Account
          </Typography>
          <Styled.LoginMethods>
            {authOrganizationMethods.map((method) => (
              <Button
                key={method.id}
                variant="contained"
                color="black"
                onClick={() => {
                  handleLogin(method.id);
                }}
                sx={{ boxShadow: 2 }}
              >
                Sign In with {method.name}
              </Button>
            ))}
          </Styled.LoginMethods>
        </Styled.LoginBody>
      </Styled.LoginContainer>
    </Styled.Main>
  );
};

export default OrganizationLoginPage;
