import { TableCell, Typography, capitalize } from '@mui/material';
import { IProjectFile } from 'api/platformApi/platformApi.types';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import { forwardRef } from 'react';
import { formatRelativeDate } from 'utils/dates';
import * as Styled from '../../ProjectFiles.styles';

interface ProjectFilesTableRowProps {
  projectFile: IProjectFile;
  onSelect: (projectFile: IProjectFile) => void;
  onOpen: (projectFile: IProjectFile) => void;
  selected: boolean;
}

export const ProjectFilesTableRow = forwardRef<HTMLTableRowElement, ProjectFilesTableRowProps>(
  ({ projectFile, onOpen, onSelect, selected }, ref) => {
    return (
      <MFTableBodyRow
        key={projectFile.id}
        onClick={() => {
          onSelect(projectFile);
        }}
        onDoubleClick={() => {
          onOpen(projectFile);
        }}
        ref={ref}
        selected={selected}
      >
        <TableCell>
          <Styled.FileNameContent>
            <Styled.AppLogo src={projectFile.app.logo ?? ''} alt={projectFile.app.name} />
            <Styled.FileName variant="bodyMedium" color="text.primary">
              {projectFile.name}
            </Styled.FileName>
          </Styled.FileNameContent>
        </TableCell>

        <TableCell>
          <Typography variant="bodyMedium" color="text.secondary">
            {capitalize(projectFile.type)}
          </Typography>
        </TableCell>

        <TableCell>
          <Styled.CreatedByContent>
            <Styled.Avatar>{projectFile.createdBy.name.charAt(0).toUpperCase()}</Styled.Avatar>
            <Styled.UserName variant="bodyMedium" color="text.primary">
              {projectFile.createdBy.name}
            </Styled.UserName>
          </Styled.CreatedByContent>
        </TableCell>

        <TableCell>
          <Typography variant="bodyMedium" color="text.primary">
            {formatRelativeDate(projectFile.updatedAt)} by {projectFile.updatedBy.name}
          </Typography>
        </TableCell>
      </MFTableBodyRow>
    );
  },
);

ProjectFilesTableRow.displayName = 'ProjectFilesTableRow';
