import PageFeedback from 'components/ui/PageFeedback/PageFeedback';
import { FC } from 'react';
import { routes } from 'utils/routes';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import ProjectCard from '../ProjectCard/ProjectCard';
import * as Styled from './ProjectsList.styles';
import { ProjectsListProps } from './ProjectsList.types';
import ProjectsListLoader from './ProjectsListLoader/ProjectsListLoader';

const ProjectsList: FC<ProjectsListProps> = ({
  projects,
  isLoading,
  isError,
  isLoadingMore,
  lastProjectRef,
}) => {
  const activeWorkspace = useActiveWorkspace();
  const activeWorkspaceSlug = activeWorkspace?.slug ?? '';

  if (isLoading) {
    return (
      <Styled.ProjectsContainer>
        <ProjectsListLoader projects={projects.length ? projects.length : 16} />
      </Styled.ProjectsContainer>
    );
  }

  if (isError) {
    return (
      <PageFeedback
        title="Projects failed to load"
        description="An error occurred and your projects could not be loaded. Please try refreshing the page to access your projects."
      />
    );
  }

  if (projects.length === 0) {
    return (
      <PageFeedback
        title="No projects found"
        description="Projects can be used to organize work for a campaign, work request, or other groups of work that requires combining many files."
      />
    );
  }

  return (
    <Styled.ProjectsContainer>
      {projects.map((project) => (
        <Styled.Link
          key={project.id}
          to={routes.viewProject(activeWorkspaceSlug, project.id)}
          ref={lastProjectRef}
        >
          <ProjectCard
            name={project.name}
            owner={project.owner.name}
            visibility={project.visibility}
            isLoading={isLoading}
          />
        </Styled.Link>
      ))}

      {isLoadingMore && <ProjectsListLoader projects={6} />}
    </Styled.ProjectsContainer>
  );
};

export default ProjectsList;
