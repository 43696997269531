import useAuthContext from 'contexts/auth/useAuthContext';
import { usePreferences } from './usePreferences';

export interface GlobalPreferences {
  enableRefreshToken?: boolean;
  organizationSlug?: string;
  /**
   * Refers to the last workspace that the user has deliberately switched to,
   * either when logging in or through the workspace selector.
   *
   * It differs from the concept of an active workspace in a way that users can
   * still access another workspace URL without changing the chosen workspace.
   * It's possible then that a workspace is active but not chosen.
   *
   * Should be used to redirect the user to a workspace when it's not clear
   * which one to show.
   */
  workspaceSlug?: string;
}

const MEDIA_MONKS_ORGANIZATION_SLUG = 'mm';

export const defaultPreferences: GlobalPreferences = {
  organizationSlug: MEDIA_MONKS_ORGANIZATION_SLUG,
};

export function useGlobalPreferences(initialPreferences: GlobalPreferences = defaultPreferences) {
  return usePreferences('global', '__app__', initialPreferences);
}

useGlobalPreferences.getStorage = (initialPreferences: GlobalPreferences = defaultPreferences) =>
  usePreferences.getStorage('global', '__app__', initialPreferences);

/**
 * Returns the preferred workspace slug if it's available in the user's
 * workspaces. Otherwise, returns undefined.
 *
 * Necessary to prevent redirecting to a workspace that the user doesn't have
 * access to, or a malformed slug.
 */
export const usePreferredWorkspace = () => {
  const { user } = useAuthContext();
  const { read } = useGlobalPreferences();
  const preferredWorkspaceSlug = read('workspaceSlug');

  if (!user?.workspaces.find((workspace) => workspace.slug === preferredWorkspaceSlug)) {
    return undefined;
  }

  return preferredWorkspaceSlug;
};
