import {
  DefaultError,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import {
  IInvitation,
  IInvitationStatus,
  IRevokeInvitationParamsPath,
  IRevokeInvitationResponse,
  IRevokeOrganizationInvitationServiceParamsPath,
} from 'api/platformApi/platformApi.types';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { getInvitationsQueryKey } from '../invitationsService/useInvitations';
import {
  revokeInvitationService,
  revokeOrganizationInvitationService,
} from './revokeInvitationService';

type UseRevokeInvitationParams = IRevokeInvitationParamsPath;

/**
 * Revokes an invitation tied to the current organization the user is current
 * logged in.
 */
export const useRevokeInvitation = (
  mutationOptions?: UseMutationOptions<
    IRevokeInvitationResponse,
    DefaultError,
    UseRevokeInvitationParams
  >,
) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();

  return useMutation({
    ...mutationOptions,
    mutationFn(variables) {
      return revokeInvitationService({
        params: {
          path: {
            invitationId: variables.invitationId,
          },
        },
      });
    },
    async onMutate(variables) {
      const queryKey = getInvitationsQueryKey();

      await queryClient.cancelQueries({
        queryKey,
      });

      const previousInvitations = queryClient.getQueryData<IInvitation[]>(queryKey);

      queryClient.setQueryData<IInvitation[]>(queryKey, (currentInvitations = []) =>
        Array.from(currentInvitations).map((invitation) => {
          if (invitation.id === variables.invitationId) {
            return {
              ...invitation,
              status: IInvitationStatus.REVOKED,
            };
          }

          return invitation;
        }),
      );

      snackbar.success('This invitation is now no longer valid!');

      return { previousInvitations };
    },
    onError(_error, _variables, context) {
      queryClient.setQueryData(getInvitationsQueryKey(), context?.previousInvitations);
      snackbar.error('Something wrong happened revoking the inivitation, please try again.');
    },
    onSettled() {
      void queryClient.invalidateQueries({
        queryKey: getInvitationsQueryKey(),
      });
    },
  });
};

type UseRevokeInvitationOrganizationParams = IRevokeOrganizationInvitationServiceParamsPath;

/**
 * Revokes an invitation of a specific organization that can be specific as
 * parameter. The user must be an instance admin to perform this action.
 */
export const useRevokeOrganizationInvitation = (
  params: Pick<UseRevokeInvitationOrganizationParams, 'organizationId'>,
  mutationOptions?: UseMutationOptions<
    unknown,
    DefaultError,
    Pick<UseRevokeInvitationOrganizationParams, 'invitationId'>
  >,
) => {
  return useMutation({
    ...mutationOptions,
    mutationFn(variables) {
      return revokeOrganizationInvitationService({
        params: {
          path: {
            organizationId: params.organizationId,
            invitationId: variables.invitationId,
          },
        },
      });
    },
  });
};
