import { Avatar as MuiAvatar } from '@mui/material';
import styled from 'styled-components';

export const Avatar = styled(MuiAvatar)`
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 800;
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.dark};
  line-height: 24px;
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const Container = styled.div<{ $disabled?: boolean }>`
  & > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.surface.dividerMain};
  }

  ${(props) => {
    if (props.$disabled) {
      return `
        opacity: 0.5;
        pointer-events: none;
      `;
    }
  }}
`;

export const MemberAvatar = styled(MuiAvatar)`
  width: 32px;
  height: 32px;
  font-size: 14px;
  font-weight: 800;
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.dark};
  line-height: 32x;
`;

export const MemberRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;

export const MemberDetails = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;
