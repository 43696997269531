import { Link as MuiLink, Typography } from '@mui/material';
import styled from 'styled-components';

export const MenuLabel = styled(Typography)`
  display: block;
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const Link = styled(MuiLink)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  text-decoration: none;
`;

export const ToolIcon = styled.img`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
`;
