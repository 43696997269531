import styled from 'styled-components';

export const Container = styled.div`
  background: radial-gradient(44.49% 44.49% at 50% 50%, #ebf4f6 0%, #c4d8dd 100%);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const transition = 'opacity 250ms ease-out';

export const AnimatedLogo = styled.img<{ $show: boolean }>`
  width: 360px;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: ${transition};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -180px;
  margin-top: -102px;
`;

export const Children = styled.div<{ $show: boolean }>`
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: ${transition};
  pointer-events: ${(props) => (props.$show ? 'auto' : 'none')};
`;
