export enum RouteGuardState {
  Authenticated,
  ForbiddenRole,
  ForbiddenPermissions,
  NotAuthenticated,
  EulaNotAccepted,
  DifferentOrganizationSlug,
  OrganizationSlugInStorage,
  NoOrganizationSlug,
  NoWorkspaceAssigned,
  NoWorkspaceFound,
  NoProjects,
}
