import { Chip as MuiChip } from '@mui/material';
import { IInvitationStatus } from 'api/platformApi/platformApi.types';
import styled from 'styled-components';
import { StatusChipProps } from './StatusChip.types';

export const Chip = styled(MuiChip)<StatusChipProps>`
  ${({ theme, status }) => {
    switch (status) {
      case IInvitationStatus.PENDING:
        return `
          background-color: ${theme.palette.action.selected};
          color: ${theme.palette.text.primary};
        `;
      case IInvitationStatus.ACCEPTED:
        return `
          background-color: ${theme.palette.success.selected};
          color: ${theme.palette.success.main};
        `;
      case IInvitationStatus.REVOKED:
        return `
          background-color: ${theme.palette.error.selected};
          color: ${theme.palette.error.main};
        `;
      case IInvitationStatus.EXPIRED:
        return `
          background-color: ${theme.palette.warning.selected};
          color: ${theme.palette.warning.main};
        `;
      default:
        return '';
    }
  }}
`;
