import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import { FieldValues } from 'react-hook-form';
import { PROJECT_VISIBILITY_OPTIONS } from './ProjectVisibilitySelect.consts';
import * as Styled from './ProjectVisibilitySelect.styles';
import { ProjectVisibilitySelectProps } from './ProjectVisibilitySelect.types';

const ProjectVisibilitySelect = <T extends FieldValues>({
  name,
  control,
  disabled,
}: ProjectVisibilitySelectProps<T>) => {
  return (
    <RHFMonkTextField
      name={name}
      control={control}
      label="Project visibility"
      disabled={disabled}
      required
      fullWidth
      select
      sx={{ marginTop: '24px' }}
    >
      {PROJECT_VISIBILITY_OPTIONS.map((option) => (
        <Styled.SelectOption key={option.value} value={option.value}>
          <Styled.SelectOptionLabel>{option.label}</Styled.SelectOptionLabel>
          <Styled.SelectOptionDescription>{option.description}</Styled.SelectOptionDescription>
        </Styled.SelectOption>
      ))}
    </RHFMonkTextField>
  );
};

export default ProjectVisibilitySelect;
